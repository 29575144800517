import type {FC} from 'react'
import {useMemo} from 'react'
import {Area, Brush, CartesianGrid, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import type {Payload} from 'recharts/types/component/DefaultLegendContent'
import GraphData from '../../lookup/graph'

interface GraphPoint {
  los: number
  patients: number
}

interface GraphProps {
  actual: number | undefined
  prediction: number
}

const Graph: FC<GraphProps> = (props: GraphProps) => {
  const intervalLength = 1

  const data = useMemo<GraphPoint[]>(() => {
    return GraphData
      .reduce((acc, cur) => {
        const intervalPart = Math.floor(cur.los / intervalLength)
        if (!acc[intervalPart]) {
          acc[intervalPart] = {
            los: intervalPart * intervalLength,
            patients: cur.patients,
          }
        }
        else {
          acc[intervalPart].patients += cur.patients
        }
        return acc
      }, [] as GraphPoint[])
  }, [intervalLength])

  if (!data.length)
    return null

  const legend: Payload[] = [
    {
      id: 'patients',
      type: 'circle',
      value: 'Patients',
      color: 'var(--accent)',
    },
    {
      id: 'actual',
      type: 'circle',
      value: `Actual: ${props.actual === -1 ? 'N/A' : props.actual} days`,
      color: 'green',
    },
    {
      id: 'predicted',
      type: 'circle',
      value: `Predicted: ${props.prediction === -1 ? 'N/A' : props.prediction} days`,
      color: 'blue',
    },
  ]

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient
            id="gradient" x1="0" y1="0"
            x2="0" y2="1"
          >
            <stop offset="5%" stopColor="var(--accent)" stopOpacity={0.8} />
            <stop offset="95%" stopColor="var(--accent)" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis dataKey="los" />
        <YAxis dataKey="patients" />
        <CartesianGrid stroke="#797979" strokeDasharray="5 5" vertical={false} />
        <Tooltip labelFormatter={(value: number) => `${value.toFixed(2)} - ${(value + intervalLength).toFixed(2)} days`} />
        <Area
          id="area"
          type="monotone"
          dataKey="patients"
          stroke="var(--accent)"
          fill="url(#gradient)"
        />
        {!!props.actual && (
        <ReferenceLine
          x={data.find(el => el?.los === Math.floor((props.actual || 0) / intervalLength) * intervalLength)?.los}
          stroke="green"
        />
        )}
        <ReferenceLine
          x={data.find(el => el?.los === Math.floor(props.prediction / intervalLength) * intervalLength)?.los}
          stroke="blue"
        />
        <Legend verticalAlign='top' payload={legend} />
        <Brush
          dataKey="los"
          height={30}
          stroke="var(--accent)"
          endIndex={Math.round(12 / intervalLength)}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default Graph
