import type {FC} from 'react'
import Grid from '@mui/material/Grid'
import {Paper, Typography} from '@mui/material'
import {useAppSelector} from '../../redux/hooks'
import type {PatientInfo} from '../../redux/patientsSlice'
import ICDLut from '../../lookup/icd'
import NamePlate from './NamePlate'

const PatientList: FC = () => {
  const patients = useAppSelector(state => state.patients)

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sx={{pt: 2, pl: 2}}>
          <Typography variant="h6" component="h5">Patients</Typography>
        </Grid>
        {patients.map((patient: PatientInfo) => {
          return (
            <Grid item xs={12} key={patient.index}>
              <hr style={{marginBlockStart: 0, marginBlockEnd: 0}} />
              <NamePlate
                index={patient.index}
                name={patient.name}
                gender={patient.properties.gender}
                age={patient.properties.anchor_age}
                diagnosis={ICDLut[patient.properties.icd_code]}
              />
            </Grid>
          )
        })}
      </Grid>
    </Paper>
  )
}

export default PatientList
