import type {PayloadAction} from '@reduxjs/toolkit'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import type {ResultType} from '../components/result/Result'
import type {PatientInfo} from './patientsSlice'

const initialState: ResultType[] = [
  {
    index: 0,
    actual: 10.12,
    prediction: 6.70,
  },
  {
    index: 1,
    actual: 4.03,
    prediction: 2.26,
  },
  {
    index: 2,
    actual: 0.68,
    prediction: 0.92,
  },
  {
    index: 3,
    actual: -1,
    prediction: -1,
  },
]

export const refreshPrediction = createAsyncThunk(
  'results/refreshPrediction',
  async (patient: PatientInfo) => {
    const response = await axios.post('https://api.los-prediction.singular-it-test.de/predict/los', patient.properties)
    return {
      index: patient.index,
      prediction: response.data.toFixed(2),
    }
  },
)

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    updateResult: (state, action: PayloadAction<ResultType>) => {
      state[action.payload.index] = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshPrediction.fulfilled, (state, action) => {
      state[action.payload.index].prediction = parseFloat(action.payload.prediction)
    })
  },
})

export const {updateResult} = resultsSlice.actions

export default resultsSlice.reducer
