import {Box, Grid, Slider, Typography} from '@mui/material'
import type {FC} from 'react'
import {useEffect, useState} from 'react'

interface SliderFieldProps {
  onChangeCommitted: (value: number) => void
  min: number
  max: number
  step?: number
  value: number
  label: string
  disabled?: boolean
}

const SliderField: FC<SliderFieldProps> = (props: SliderFieldProps) => {
  const [value, setValue] = useState<number>(props.value)
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <Grid item xs={6}>
      <Box sx={{px: 2}}>
        <Typography
          variant="button"
          component="p"
          sx={{mt: 2}}
        >{props.label}:
        </Typography>
        <Slider
          onChangeCommitted={() => props.onChangeCommitted(value)}
          onChange={e => setValue(parseFloat((e.target as HTMLInputElement).value))}
          name={props.label}
          aria-label={props.label}
          value={value}
          getAriaValueText={(value: number) => `${props.label}: ${value}`}
          valueLabelDisplay="auto"
          step={props.step || 1}
          min={props.min}
          max={props.max}
          sx={{mb: 1}}
          disabled={props.disabled}
        />
      </Box>
    </Grid>
  )
}

export default SliderField
