const ethnicityLut: string[] = [
  'WHITE',
  'BLACK/AFRICAN AMERICAN',
  'HISPANIC/LATINO',
  'OTHER',
  'ASIAN',
  'UNKNOWN',
  'AMERICAN INDIAN/ALASKA NATIVE',
  'UNABLE TO OBTAIN',
]

export default ethnicityLut
