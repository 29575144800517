import {Box, Grid, Paper, Typography} from '@mui/material'
import type {FC} from 'react'
import {useEffect} from 'react'
import {useCurrentPatient, useCurrentResult} from '../../redux/currentSlice'
import {useAppDispatch} from '../../redux/hooks'
import {refreshPrediction} from '../../redux/resultsSlice'
import Graph from './Graph'

interface ResultType {
  index: number
  actual: number
  prediction: number
}

const Result: FC = () => {
  const result = useCurrentResult()
  const patient = useCurrentPatient()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (result.index !== 3) return
    dispatch(refreshPrediction(patient))
  }, [result.index, dispatch])

  return (
    <Paper sx={{p: 2, height: '100%'}}>
      <Grid
        container
        spacing={4}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h6" component="h5">Result</Typography>
            <Typography variant="subtitle2" component="h5" sx={{textAlign: 'right'}}>Length of stay prediction</Typography>
            <Grid item xs={12}>
              <Typography variant="h6" component="h5" sx={{textAlign: 'right', pr: 2, mt: -2}}>{result.prediction === -1 ? 'N/A' : result.prediction} days</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Graph actual={result.actual} prediction={result.prediction} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Result
export type {ResultType}
