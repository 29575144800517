import type {FC} from 'react'
import {Divider, Grid, Hidden, Typography} from '@mui/material'
import PatientList from './selector/PatientList'
import Main from './main/Main'
import Result from './result/Result'

const Page: FC = () => {
  return (
    <>
      <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}}>LENGTH OF STAY PREDICTION</Typography>
      <Divider variant="fullWidth" sx={{height: '.5rem', backgroundColor: '#fb923c', mb: 2}} />
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="stretch"
        spacing={2}
        sx={{pb: 2}}
      >
        <Hidden lgDown>
          <Grid item lg={2}>
            <PatientList />
          </Grid>
        </Hidden>
        <Grid item xs={6}>
          <Main />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Result />
        </Grid>
      </Grid>
    </>
  )
}

export default Page
