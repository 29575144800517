import type {SelectChangeEvent} from '@mui/material'
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material'
import type {FC} from 'react'
import ICDLut from '../../../lookup/icd'
import admissionLocLut from '../../../lookup/AdmissionLoc'
import {useCurrentPatient} from '../../../redux/currentSlice'
import SliderField from './SliderField'
import {useUpdateCurrent} from './Control'

const Medical: FC = () => {
  const update = useUpdateCurrent()
  const props = useCurrentPatient().properties
  return (
    <>
      <Grid item xs={6}>
        <Box sx={{px: 4}}>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel
              id="control-medi-select-icd"
            >DIAGNOSIS
            </InputLabel>
            <Select
              label="Diagnosis"
              labelId="control-medi-select-icd"
              value={props.icd_code}
              onChange={(e: SelectChangeEvent) => update('icd_code', e.target.value)}
            >
              {(Object.entries(ICDLut)).map(([code, value]) => {
                return (
                  <MenuItem
                    key={code}
                    value={code}
                  >{value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{px: 4}}>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel
              id="control-medi-select-admloc"
            >ADMISSION LOCATION
            </InputLabel>
            <Select
              label="Admission Location"
              labelId="control-medi-select-admloc"
              value={props.admission_location}
              onChange={(e: SelectChangeEvent) => update('admission_location', e.target.value)}
            >
              {admissionLocLut.map((admloc: string) => {
                return (
                  <MenuItem
                    key={admloc}
                    value={admloc}
                  >{admloc}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <SliderField
        onChangeCommitted={(value: number) => update('diag_count', value)}
        min={0}
        max={5}
        value={props.diag_count}
        label="Diagnosis Count"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('med_count', value)}
        min={0}
        max={30}
        value={props.med_count}
        label="Medication Count"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('ed_los', value)}
        min={0}
        max={0.5}
        step={0.01}
        value={props.ed_los}
        label="Days at Emergency department"
      />
      <SliderField
        onChangeCommitted={(value: number) => {
          const override = update('previous_stays', value)
          if (value === 0)
            update('prev_stay_avg_dur', value, override)
        }}
        min={0}
        max={5}
        value={props.previous_stays}
        label="Previous Stays"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('prev_stay_avg_dur', value)}
        disabled={props.previous_stays === 0}
        min={0}
        max={10}
        step={0.1}
        value={props.previous_stays === 0 ? 0 : props.prev_stay_avg_dur}
        label="Average Stay Duration"
      />
    </>
  )
}

export default Medical
