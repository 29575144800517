const data = [
  {
    los: 1,
    patients: 516,
  },
  {
    los: 2,
    patients: 364,
  },
  {
    los: 3,
    patients: 292,
  },
  {
    los: 4,
    patients: 231,
  },
  {
    los: 5,
    patients: 168,
  },
  {
    los: 6,
    patients: 126,
  },
  {
    los: 7,
    patients: 90,
  },
  {
    los: 8,
    patients: 59,
  },
  {
    los: 9,
    patients: 47,
  },
  {
    los: 10,
    patients: 26,
  },
  {
    los: 11,
    patients: 16,
  },
  {
    los: 12,
    patients: 26,
  },
  {
    los: 13,
    patients: 14,
  },
  {
    los: 14,
    patients: 18,
  },
  {
    los: 15,
    patients: 11,
  },
  {
    los: 16,
    patients: 8,
  },
  {
    los: 17,
    patients: 15,
  },
  {
    los: 18,
    patients: 11,
  },
  {
    los: 19,
    patients: 3,
  },
  {
    los: 20,
    patients: 7,
  },
  {
    los: 21,
    patients: 7,
  },
  {
    los: 22,
    patients: 6,
  },
  {
    los: 23,
    patients: 2,
  },
  {
    los: 24,
    patients: 2,
  },
  {
    los: 25,
    patients: 3,
  },
  {
    los: 26,
    patients: 5,
  },
  {
    los: 27,
    patients: 3,
  },
  {
    los: 28,
    patients: 1,
  },
  {
    los: 29,
    patients: 1,
  },
  {
    los: 30,
    patients: 1,
  },
  {
    los: 31,
    patients: 1,
  },
  {
    los: 32,
    patients: 1,
  },
  {
    los: 33,
    patients: 1,
  },
  {
    los: 35,
    patients: 2,
  },
  {
    los: 36,
    patients: 1,
  },
  {
    los: 37,
    patients: 2,
  },
  {
    los: 40,
    patients: 2,
  },
  {
    los: 42,
    patients: 1,
  },
  {
    los: 0.79,
    patients: 640,
  },
  {
    los: 1.02,
    patients: 473,
  },
  {
    los: 2.22,
    patients: 111,
  },
  {
    los: 1.75,
    patients: 443,
  },
  {
    los: 4.54,
    patients: 123,
  },
  {
    los: 0.46,
    patients: 519,
  },
  {
    los: 0.41,
    patients: 513,
  },
  {
    los: 0.53,
    patients: 601,
  },
  {
    los: 2.93,
    patients: 331,
  },
  {
    los: 0.75,
    patients: 693,
  },
  {
    los: 3.93,
    patients: 254,
  },
  {
    los: 4.71,
    patients: 170,
  },
  {
    los: 0.92,
    patients: 535,
  },
  {
    los: 3.76,
    patients: 253,
  },
  {
    los: 15.91,
    patients: 11,
  },
  {
    los: 1.99,
    patients: 372,
  },
  {
    los: 5.81,
    patients: 115,
  },
  {
    los: 1.79,
    patients: 430,
  },
  {
    los: 7.9,
    patients: 95,
  },
  {
    los: 1.07,
    patients: 373,
  },
  {
    los: 2.95,
    patients: 321,
  },
  {
    los: 3.46,
    patients: 129,
  },
  {
    los: 0.49,
    patients: 553,
  },
  {
    los: 6.79,
    patients: 91,
  },
  {
    los: 3.02,
    patients: 265,
  },
  {
    los: 12.87,
    patients: 15,
  },
  {
    los: 4.69,
    patients: 169,
  },
  {
    los: 4.59,
    patients: 137,
  },
  {
    los: 1.67,
    patients: 397,
  },
  {
    los: 3.73,
    patients: 253,
  },
  {
    los: 0.5,
    patients: 548,
  },
  {
    los: 0.98,
    patients: 530,
  },
  {
    los: 1.78,
    patients: 482,
  },
  {
    los: 3.78,
    patients: 306,
  },
  {
    los: 0.54,
    patients: 553,
  },
  {
    los: 2.05,
    patients: 304,
  },
  {
    los: 0.67,
    patients: 562,
  },
  {
    los: 1.91,
    patients: 440,
  },
  {
    los: 0.88,
    patients: 624,
  },
  {
    los: 2.76,
    patients: 315,
  },
  {
    los: 12.36,
    patients: 8,
  },
  {
    los: 2.59,
    patients: 224,
  },
  {
    los: 1.71,
    patients: 416,
  },
  {
    los: 1.82,
    patients: 495,
  },
  {
    los: 0.76,
    patients: 638,
  },
  {
    los: 3.31,
    patients: 73,
  },
  {
    los: 12.91,
    patients: 20,
  },
  {
    los: 0.23,
    patients: 556,
  },
  {
    los: 2.06,
    patients: 247,
  },
  {
    los: 1.9,
    patients: 476,
  },
  {
    los: 2.68,
    patients: 318,
  },
  {
    los: 8.33,
    patients: 29,
  },
  {
    los: 0.08,
    patients: 299,
  },
  {
    los: 9.82,
    patients: 66,
  },
  {
    los: 17.09,
    patients: 3,
  },
  {
    los: 2.53,
    patients: 206,
  },
  {
    los: 4.15,
    patients: 104,
  },
  {
    los: 0.8,
    patients: 663,
  },
  {
    los: 9.56,
    patients: 27,
  },
  {
    los: 5.45,
    patients: 61,
  },
  {
    los: 4.68,
    patients: 144,
  },
  {
    los: 0.99,
    patients: 420,
  },
  {
    los: 1.77,
    patients: 484,
  },
  {
    los: 0.45,
    patients: 534,
  },
  {
    los: 0.15,
    patients: 458,
  },
  {
    los: 3.13,
    patients: 141,
  },
  {
    los: 1.53,
    patients: 231,
  },
  {
    los: 0.32,
    patients: 442,
  },
  {
    los: 4.85,
    patients: 178,
  },
  {
    los: 1.66,
    patients: 366,
  },
  {
    los: 2.8,
    patients: 377,
  },
  {
    los: 0.9,
    patients: 654,
  },
  {
    los: 4.95,
    patients: 162,
  },
  {
    los: 4.01,
    patients: 206,
  },
  {
    los: 9.15,
    patients: 25,
  },
  {
    los: 0.94,
    patients: 560,
  },
  {
    los: 2.99,
    patients: 278,
  },
  {
    los: 8.1,
    patients: 46,
  },
  {
    los: 0.48,
    patients: 533,
  },
  {
    los: 2.17,
    patients: 154,
  },
  {
    los: 3.51,
    patients: 143,
  },
  {
    los: 1.2,
    patients: 189,
  },
  {
    los: 0.38,
    patients: 535,
  },
  {
    los: 4.87,
    patients: 184,
  },
  {
    los: 0.36,
    patients: 444,
  },
  {
    los: 7.87,
    patients: 66,
  },
  {
    los: 2.81,
    patients: 348,
  },
  {
    los: 10.95,
    patients: 31,
  },
  {
    los: 2.4,
    patients: 129,
  },
  {
    los: 4.12,
    patients: 113,
  },
  {
    los: 1.09,
    patients: 296,
  },
  {
    los: 3.62,
    patients: 189,
  },
  {
    los: 1.64,
    patients: 378,
  },
  {
    los: 4.45,
    patients: 97,
  },
  {
    los: 2.84,
    patients: 365,
  },
  {
    los: 5.17,
    patients: 54,
  },
  {
    los: 1.46,
    patients: 230,
  },
  {
    los: 14.13,
    patients: 8,
  },
  {
    los: 7.75,
    patients: 78,
  },
  {
    los: 6.71,
    patients: 85,
  },
  {
    los: 8.58,
    patients: 36,
  },
  {
    los: 3.75,
    patients: 287,
  },
  {
    los: 1.76,
    patients: 457,
  },
  {
    los: 21.43,
    patients: 4,
  },
  {
    los: 6.75,
    patients: 114,
  },
  {
    los: 3.3,
    patients: 84,
  },
  {
    los: 0.26,
    patients: 516,
  },
  {
    los: 0.4,
    patients: 448,
  },
  {
    los: 2.1,
    patients: 233,
  },
  {
    los: 2.98,
    patients: 301,
  },
  {
    los: 3.94,
    patients: 253,
  },
  {
    los: 6.81,
    patients: 119,
  },
  {
    los: 4.96,
    patients: 154,
  },
  {
    los: 11.09,
    patients: 14,
  },
  {
    los: 2.79,
    patients: 346,
  },
  {
    los: 6.43,
    patients: 31,
  },
  {
    los: 6.02,
    patients: 91,
  },
  {
    los: 6.4,
    patients: 48,
  },
  {
    los: 8.12,
    patients: 34,
  },
  {
    los: 2.71,
    patients: 318,
  },
  {
    los: 6.91,
    patients: 99,
  },
  {
    los: 6.65,
    patients: 99,
  },
  {
    los: 4.92,
    patients: 194,
  },
  {
    los: 6.61,
    patients: 76,
  },
  {
    los: 2.64,
    patients: 266,
  },
  {
    los: 0.06,
    patients: 249,
  },
  {
    los: 1.29,
    patients: 151,
  },
  {
    los: 0.21,
    patients: 517,
  },
  {
    los: 1.18,
    patients: 219,
  },
  {
    los: 0.47,
    patients: 541,
  },
  {
    los: 0.97,
    patients: 531,
  },
  {
    los: 2.77,
    patients: 332,
  },
  {
    los: 0.13,
    patients: 378,
  },
  {
    los: 0.43,
    patients: 502,
  },
  {
    los: 3.8,
    patients: 298,
  },
  {
    los: 1.83,
    patients: 453,
  },
  {
    los: 2.01,
    patients: 355,
  },
  {
    los: 0.83,
    patients: 607,
  },
  {
    los: 1.33,
    patients: 164,
  },
  {
    los: 6.88,
    patients: 112,
  },
  {
    los: 1.73,
    patients: 476,
  },
  {
    los: 0.34,
    patients: 441,
  },
  {
    los: 6.04,
    patients: 90,
  },
  {
    los: 0.19,
    patients: 475,
  },
  {
    los: 4.5,
    patients: 112,
  },
  {
    los: 5.77,
    patients: 131,
  },
  {
    los: 3.85,
    patients: 296,
  },
  {
    los: 0.11,
    patients: 382,
  },
  {
    los: 0.69,
    patients: 645,
  },
  {
    los: 0.33,
    patients: 506,
  },
  {
    los: 0.65,
    patients: 635,
  },
  {
    los: 0.37,
    patients: 480,
  },
  {
    los: 0.35,
    patients: 516,
  },
  {
    los: 1.35,
    patients: 178,
  },
  {
    los: 0.42,
    patients: 534,
  },
  {
    los: 0.07,
    patients: 302,
  },
  {
    los: 5.25,
    patients: 53,
  },
  {
    los: 0.73,
    patients: 686,
  },
  {
    los: 0.52,
    patients: 562,
  },
  {
    los: 1.68,
    patients: 423,
  },
  {
    los: 10.2,
    patients: 19,
  },
  {
    los: 26.97,
    patients: 4,
  },
  {
    los: 6.73,
    patients: 95,
  },
  {
    los: 24.72,
    patients: 3,
  },
  {
    los: 0.22,
    patients: 524,
  },
  {
    los: 1.03,
    patients: 435,
  },
  {
    los: 1.58,
    patients: 300,
  },
  {
    los: 2.61,
    patients: 229,
  },
  {
    los: 1.93,
    patients: 453,
  },
  {
    los: 11.73,
    patients: 28,
  },
  {
    los: 2.97,
    patients: 294,
  },
  {
    los: 2.08,
    patients: 273,
  },
  {
    los: 0.24,
    patients: 502,
  },
  {
    los: 1.16,
    patients: 233,
  },
  {
    los: 2.52,
    patients: 206,
  },
  {
    los: 0.3,
    patients: 528,
  },
  {
    los: 14.49,
    patients: 11,
  },
  {
    los: 7.04,
    patients: 81,
  },
  {
    los: 0.72,
    patients: 627,
  },
  {
    los: 4.52,
    patients: 94,
  },
  {
    los: 5.68,
    patients: 127,
  },
  {
    los: 1.34,
    patients: 140,
  },
  {
    los: 1.5,
    patients: 225,
  },
  {
    los: 3.82,
    patients: 274,
  },
  {
    los: 4.18,
    patients: 86,
  },
  {
    los: 2.42,
    patients: 157,
  },
  {
    los: 0.84,
    patients: 610,
  },
  {
    los: 1.81,
    patients: 462,
  },
  {
    los: 1.88,
    patients: 485,
  },
  {
    los: 0.04,
    patients: 198,
  },
  {
    los: 0.81,
    patients: 645,
  },
  {
    los: 1.74,
    patients: 458,
  },
  {
    los: 2.67,
    patients: 346,
  },
  {
    los: 5.21,
    patients: 52,
  },
  {
    los: 2.9,
    patients: 372,
  },
  {
    los: 31.88,
    patients: 2,
  },
  {
    los: 3.95,
    patients: 242,
  },
  {
    los: 18.69,
    patients: 8,
  },
  {
    los: 1.98,
    patients: 428,
  },
  {
    los: 0.56,
    patients: 554,
  },
  {
    los: 1.11,
    patients: 303,
  },
  {
    los: 0.7,
    patients: 671,
  },
  {
    los: 1.95,
    patients: 426,
  },
  {
    los: 7.37,
    patients: 29,
  },
  {
    los: 6.08,
    patients: 66,
  },
  {
    los: 41.63,
    patients: 1,
  },
  {
    los: 0.59,
    patients: 607,
  },
  {
    los: 15.01,
    patients: 16,
  },
  {
    los: 9.61,
    patients: 27,
  },
  {
    los: 10.66,
    patients: 33,
  },
  {
    los: 2.96,
    patients: 306,
  },
  {
    los: 18.92,
    patients: 9,
  },
  {
    los: 7.98,
    patients: 71,
  },
  {
    los: 0.87,
    patients: 601,
  },
  {
    los: 8.39,
    patients: 17,
  },
  {
    los: 9.78,
    patients: 50,
  },
  {
    los: 0.1,
    patients: 358,
  },
  {
    los: 0.85,
    patients: 654,
  },
  {
    los: 2.75,
    patients: 382,
  },
  {
    los: 4.65,
    patients: 177,
  },
  {
    los: 2.89,
    patients: 387,
  },
  {
    los: 3.68,
    patients: 249,
  },
  {
    los: 36.27,
    patients: 1,
  },
  {
    los: 1.06,
    patients: 382,
  },
  {
    los: 0.68,
    patients: 707,
  },
  {
    los: 3.05,
    patients: 225,
  },
  {
    los: 2.74,
    patients: 310,
  },
  {
    los: 12.48,
    patients: 7,
  },
  {
    los: 7.41,
    patients: 38,
  },
  {
    los: 2.19,
    patients: 137,
  },
  {
    los: 2.92,
    patients: 277,
  },
  {
    los: 0.18,
    patients: 513,
  },
  {
    los: 3.77,
    patients: 296,
  },
  {
    los: 0.6,
    patients: 672,
  },
  {
    los: 0.58,
    patients: 612,
  },
  {
    los: 1.7,
    patients: 455,
  },
  {
    los: 9.63,
    patients: 31,
  },
  {
    los: 13.77,
    patients: 26,
  },
  {
    los: 0.66,
    patients: 597,
  },
  {
    los: 0.62,
    patients: 632,
  },
  {
    los: 5.64,
    patients: 106,
  },
  {
    los: 6.74,
    patients: 94,
  },
  {
    los: 1.72,
    patients: 477,
  },
  {
    los: 2.12,
    patients: 202,
  },
  {
    los: 1.37,
    patients: 148,
  },
  {
    los: 4.51,
    patients: 89,
  },
  {
    los: 1.44,
    patients: 184,
  },
  {
    los: 11.93,
    patients: 32,
  },
  {
    los: 2.63,
    patients: 256,
  },
  {
    los: 4.98,
    patients: 149,
  },
  {
    los: 0.39,
    patients: 491,
  },
  {
    los: 6.96,
    patients: 69,
  },
  {
    los: 0.77,
    patients: 664,
  },
  {
    los: 1.52,
    patients: 262,
  },
  {
    los: 5.88,
    patients: 130,
  },
  {
    los: 3.08,
    patients: 197,
  },
  {
    los: 3.03,
    patients: 267,
  },
  {
    los: 0.57,
    patients: 639,
  },
  {
    los: 2.58,
    patients: 253,
  },
  {
    los: 3.18,
    patients: 122,
  },
  {
    los: 2.86,
    patients: 366,
  },
  {
    los: 1.57,
    patients: 307,
  },
  {
    los: 3.74,
    patients: 272,
  },
  {
    los: 14.08,
    patients: 7,
  },
  {
    los: 3.83,
    patients: 266,
  },
  {
    los: 1.31,
    patients: 119,
  },
  {
    los: 15.63,
    patients: 14,
  },
  {
    los: 15.46,
    patients: 5,
  },
  {
    los: 4.44,
    patients: 83,
  },
  {
    los: 1.14,
    patients: 241,
  },
  {
    los: 4.11,
    patients: 109,
  },
  {
    los: 12.81,
    patients: 17,
  },
  {
    los: 14.75,
    patients: 11,
  },
  {
    los: 13.88,
    patients: 13,
  },
  {
    los: 4.31,
    patients: 53,
  },
  {
    los: 1.36,
    patients: 172,
  },
  {
    los: 2.85,
    patients: 382,
  },
  {
    los: 5.6,
    patients: 107,
  },
  {
    los: 7.45,
    patients: 34,
  },
  {
    los: 2.83,
    patients: 357,
  },
  {
    los: 5.76,
    patients: 131,
  },
  {
    los: 10.68,
    patients: 32,
  },
  {
    los: 2.91,
    patients: 346,
  },
  {
    los: 0.96,
    patients: 512,
  },
  {
    los: 1.59,
    patients: 343,
  },
  {
    los: 24.75,
    patients: 4,
  },
  {
    los: 5.73,
    patients: 139,
  },
  {
    los: 3.7,
    patients: 257,
  },
  {
    los: 1.28,
    patients: 148,
  },
  {
    los: 3.72,
    patients: 231,
  },
  {
    los: 7.65,
    patients: 61,
  },
  {
    los: 3.97,
    patients: 207,
  },
  {
    los: 3.61,
    patients: 208,
  },
  {
    los: 4.75,
    patients: 195,
  },
  {
    los: 1.48,
    patients: 255,
  },
  {
    los: 3.86,
    patients: 253,
  },
  {
    los: 9.71,
    patients: 38,
  },
  {
    los: 10.7,
    patients: 43,
  },
  {
    los: 7.92,
    patients: 60,
  },
  {
    los: 4.72,
    patients: 185,
  },
  {
    los: 7.97,
    patients: 66,
  },
  {
    los: 1.47,
    patients: 208,
  },
  {
    los: 2.72,
    patients: 315,
  },
  {
    los: 1.23,
    patients: 155,
  },
  {
    los: 5.99,
    patients: 116,
  },
  {
    los: 16.77,
    patients: 12,
  },
  {
    los: 1.04,
    patients: 406,
  },
  {
    los: 9.55,
    patients: 35,
  },
  {
    los: 22.87,
    patients: 6,
  },
  {
    los: 0.12,
    patients: 396,
  },
  {
    los: 4.63,
    patients: 169,
  },
  {
    los: 7.64,
    patients: 52,
  },
  {
    los: 7.48,
    patients: 42,
  },
  {
    los: 2.27,
    patients: 93,
  },
  {
    los: 8.01,
    patients: 67,
  },
  {
    los: 0.51,
    patients: 571,
  },
  {
    los: 1.96,
    patients: 387,
  },
  {
    los: 1.86,
    patients: 438,
  },
  {
    los: 8.64,
    patients: 39,
  },
  {
    los: 2.04,
    patients: 294,
  },
  {
    los: 3.98,
    patients: 249,
  },
  {
    los: 7.02,
    patients: 78,
  },
  {
    los: 4.43,
    patients: 79,
  },
  {
    los: 2.2,
    patients: 141,
  },
  {
    los: 2.47,
    patients: 142,
  },
  {
    los: 20.8,
    patients: 6,
  },
  {
    los: 3.54,
    patients: 161,
  },
  {
    los: 0.89,
    patients: 589,
  },
  {
    los: 13.34,
    patients: 5,
  },
  {
    los: 5.27,
    patients: 48,
  },
  {
    los: 1.42,
    patients: 172,
  },
  {
    los: 2.25,
    patients: 109,
  },
  {
    los: 1.13,
    patients: 257,
  },
  {
    los: 0.44,
    patients: 504,
  },
  {
    los: 7.83,
    patients: 78,
  },
  {
    los: 4.77,
    patients: 175,
  },
  {
    los: 4.46,
    patients: 97,
  },
  {
    los: 8.08,
    patients: 39,
  },
  {
    los: 5.3,
    patients: 37,
  },
  {
    los: 3.89,
    patients: 235,
  },
  {
    los: 0.55,
    patients: 635,
  },
  {
    los: 2.18,
    patients: 146,
  },
  {
    los: 1.92,
    patients: 426,
  },
  {
    los: 5.34,
    patients: 42,
  },
  {
    los: 22.28,
    patients: 3,
  },
  {
    los: 1.97,
    patients: 372,
  },
  {
    los: 7.5,
    patients: 51,
  },
  {
    los: 0.17,
    patients: 461,
  },
  {
    los: 8.72,
    patients: 43,
  },
  {
    los: 0.82,
    patients: 700,
  },
  {
    los: 3.22,
    patients: 92,
  },
  {
    los: 15.81,
    patients: 12,
  },
  {
    los: 1.25,
    patients: 162,
  },
  {
    los: 0.86,
    patients: 632,
  },
  {
    los: 2.37,
    patients: 113,
  },
  {
    los: 4.67,
    patients: 149,
  },
  {
    los: 6.38,
    patients: 44,
  },
  {
    los: 11.81,
    patients: 30,
  },
  {
    los: 6.22,
    patients: 43,
  },
  {
    los: 5.8,
    patients: 134,
  },
  {
    los: 4.9,
    patients: 205,
  },
  {
    los: 0.2,
    patients: 564,
  },
  {
    los: 5.92,
    patients: 108,
  },
  {
    los: 1.69,
    patients: 414,
  },
  {
    los: 0.16,
    patients: 487,
  },
  {
    los: 0.28,
    patients: 516,
  },
  {
    los: 0.31,
    patients: 523,
  },
  {
    los: 2.38,
    patients: 129,
  },
  {
    los: 16.83,
    patients: 14,
  },
  {
    los: 2.51,
    patients: 175,
  },
  {
    los: 1.49,
    patients: 207,
  },
  {
    los: 3.09,
    patients: 183,
  },
  {
    los: 6.95,
    patients: 120,
  },
  {
    los: 6.66,
    patients: 84,
  },
  {
    los: 3.35,
    patients: 84,
  },
  {
    los: 2.13,
    patients: 198,
  },
  {
    los: 4.6,
    patients: 137,
  },
  {
    los: 8.74,
    patients: 56,
  },
  {
    los: 2.56,
    patients: 245,
  },
  {
    los: 5.85,
    patients: 150,
  },
  {
    los: 0.61,
    patients: 615,
  },
  {
    los: 3.17,
    patients: 136,
  },
  {
    los: 5.7,
    patients: 128,
  },
  {
    los: 1.54,
    patients: 260,
  },
  {
    los: 3.65,
    patients: 231,
  },
  {
    los: 2.62,
    patients: 289,
  },
  {
    los: 1.63,
    patients: 365,
  },
  {
    los: 13.83,
    patients: 19,
  },
  {
    los: 4.76,
    patients: 174,
  },
  {
    los: 1.84,
    patients: 467,
  },
  {
    los: 5.83,
    patients: 128,
  },
  {
    los: 4.83,
    patients: 197,
  },
  {
    los: 12.25,
    patients: 7,
  },
  {
    los: 9.89,
    patients: 44,
  },
  {
    los: 5.69,
    patients: 125,
  },
  {
    los: 22.63,
    patients: 6,
  },
  {
    los: 11.96,
    patients: 15,
  },
  {
    los: 13.63,
    patients: 10,
  },
  {
    los: 35.58,
    patients: 1,
  },
  {
    los: 0.09,
    patients: 304,
  },
  {
    los: 10.98,
    patients: 27,
  },
  {
    los: 7.99,
    patients: 61,
  },
  {
    los: 3.07,
    patients: 200,
  },
  {
    los: 6.19,
    patients: 45,
  },
  {
    los: 10.12,
    patients: 27,
  },
  {
    los: 8.73,
    patients: 61,
  },
  {
    los: 1.19,
    patients: 193,
  },
  {
    los: 25.97,
    patients: 2,
  },
  {
    los: 3.1,
    patients: 186,
  },
  {
    los: 10.86,
    patients: 21,
  },
  {
    los: 30.94,
    patients: 4,
  },
  {
    los: 2.49,
    patients: 180,
  },
  {
    los: 8.87,
    patients: 51,
  },
  {
    los: 8.83,
    patients: 59,
  },
  {
    los: 2.6,
    patients: 264,
  },
  {
    los: 2.7,
    patients: 325,
  },
  {
    los: 11.02,
    patients: 23,
  },
  {
    los: 7.16,
    patients: 44,
  },
  {
    los: 5.26,
    patients: 53,
  },
  {
    los: 3.48,
    patients: 161,
  },
  {
    los: 3.37,
    patients: 90,
  },
  {
    los: 0.27,
    patients: 537,
  },
  {
    los: 10.92,
    patients: 31,
  },
  {
    los: 5.61,
    patients: 111,
  },
  {
    los: 4.89,
    patients: 184,
  },
  {
    los: 8.48,
    patients: 29,
  },
  {
    los: 32.04,
    patients: 3,
  },
  {
    los: 2.23,
    patients: 126,
  },
  {
    los: 7.67,
    patients: 55,
  },
  {
    los: 29.27,
    patients: 2,
  },
  {
    los: 30.15,
    patients: 1,
  },
  {
    los: 13.45,
    patients: 8,
  },
  {
    los: 2.78,
    patients: 377,
  },
  {
    los: 0.91,
    patients: 583,
  },
  {
    los: 0.71,
    patients: 633,
  },
  {
    los: 4.93,
    patients: 170,
  },
  {
    los: 14.73,
    patients: 19,
  },
  {
    los: 4.42,
    patients: 81,
  },
  {
    los: 3.56,
    patients: 197,
  },
  {
    los: 1.89,
    patients: 464,
  },
  {
    los: 9.86,
    patients: 39,
  },
  {
    los: 1.87,
    patients: 495,
  },
  {
    los: 3.66,
    patients: 253,
  },
  {
    los: 4.8,
    patients: 203,
  },
  {
    los: 2.45,
    patients: 157,
  },
  {
    los: 2.14,
    patients: 173,
  },
  {
    los: 1.85,
    patients: 469,
  },
  {
    los: 4.78,
    patients: 175,
  },
  {
    los: 7.77,
    patients: 81,
  },
  {
    los: 9.43,
    patients: 20,
  },
  {
    los: 5.75,
    patients: 171,
  },
  {
    los: 5.12,
    patients: 88,
  },
  {
    los: 3.2,
    patients: 106,
  },
  {
    los: 7.68,
    patients: 57,
  },
  {
    los: 7.84,
    patients: 76,
  },
  {
    los: 2.73,
    patients: 360,
  },
  {
    los: 9.98,
    patients: 34,
  },
  {
    los: 34.08,
    patients: 1,
  },
  {
    los: 1.56,
    patients: 278,
  },
  {
    los: 16.62,
    patients: 7,
  },
  {
    los: 0.05,
    patients: 257,
  },
  {
    los: 4.07,
    patients: 173,
  },
  {
    los: 1.01,
    patients: 430,
  },
  {
    los: 0.63,
    patients: 617,
  },
  {
    los: 6.8,
    patients: 112,
  },
  {
    los: 4.61,
    patients: 146,
  },
  {
    los: 3.58,
    patients: 195,
  },
  {
    los: 5.93,
    patients: 135,
  },
  {
    los: 6.93,
    patients: 119,
  },
  {
    los: 5.03,
    patients: 126,
  },
  {
    los: 7.33,
    patients: 22,
  },
  {
    los: 4.57,
    patients: 119,
  },
  {
    los: 16.96,
    patients: 12,
  },
  {
    los: 6.84,
    patients: 118,
  },
  {
    los: 1.55,
    patients: 319,
  },
  {
    los: 9.21,
    patients: 17,
  },
  {
    los: 1.08,
    patients: 317,
  },
  {
    los: 4.47,
    patients: 100,
  },
  {
    los: 4.99,
    patients: 143,
  },
  {
    los: 6.72,
    patients: 92,
  },
  {
    los: 4.24,
    patients: 69,
  },
  {
    los: 3.59,
    patients: 202,
  },
  {
    los: 5.91,
    patients: 141,
  },
  {
    los: 1.21,
    patients: 205,
  },
  {
    los: 2.09,
    patients: 261,
  },
  {
    los: 1.65,
    patients: 385,
  },
  {
    los: 8.79,
    patients: 55,
  },
  {
    los: 8.85,
    patients: 55,
  },
  {
    los: 3.36,
    patients: 97,
  },
  {
    los: 2.21,
    patients: 120,
  },
  {
    los: 0.78,
    patients: 709,
  },
  {
    los: 4.7,
    patients: 209,
  },
  {
    los: 2.66,
    patients: 294,
  },
  {
    los: 4.06,
    patients: 149,
  },
  {
    los: 6.83,
    patients: 86,
  },
  {
    los: 3.88,
    patients: 301,
  },
  {
    los: 1.15,
    patients: 232,
  },
  {
    los: 4.19,
    patients: 86,
  },
  {
    los: 6.09,
    patients: 64,
  },
  {
    los: 6.05,
    patients: 104,
  },
  {
    los: 5.53,
    patients: 85,
  },
  {
    los: 19.84,
    patients: 11,
  },
  {
    los: 4.82,
    patients: 165,
  },
  {
    los: 2.87,
    patients: 365,
  },
  {
    los: 4.22,
    patients: 78,
  },
  {
    los: 4.94,
    patients: 159,
  },
  {
    los: 10.77,
    patients: 25,
  },
  {
    los: 12.73,
    patients: 17,
  },
  {
    los: 10.87,
    patients: 33,
  },
  {
    los: 8.68,
    patients: 51,
  },
  {
    los: 6.67,
    patients: 82,
  },
  {
    los: 8.7,
    patients: 58,
  },
  {
    los: 5.86,
    patients: 131,
  },
  {
    los: 6.94,
    patients: 96,
  },
  {
    los: 38.93,
    patients: 1,
  },
  {
    los: 5.82,
    patients: 143,
  },
  {
    los: 2.65,
    patients: 284,
  },
  {
    los: 3.21,
    patients: 90,
  },
  {
    los: 20.11,
    patients: 3,
  },
  {
    los: 6.25,
    patients: 33,
  },
  {
    los: 48.06,
    patients: 1,
  },
  {
    los: 11.68,
    patients: 33,
  },
  {
    los: 5.67,
    patients: 101,
  },
  {
    los: 3.92,
    patients: 271,
  },
  {
    los: 7.66,
    patients: 58,
  },
  {
    los: 5.05,
    patients: 113,
  },
  {
    los: 2.39,
    patients: 111,
  },
  {
    los: 0.14,
    patients: 388,
  },
  {
    los: 14.83,
    patients: 19,
  },
  {
    los: 5.32,
    patients: 28,
  },
  {
    los: 6.14,
    patients: 68,
  },
  {
    los: 2.02,
    patients: 343,
  },
  {
    los: 1.4,
    patients: 179,
  },
  {
    los: 2.44,
    patients: 140,
  },
  {
    los: 6.52,
    patients: 44,
  },
  {
    los: 3.57,
    patients: 201,
  },
  {
    los: 15.95,
    patients: 13,
  },
  {
    los: 3.49,
    patients: 142,
  },
  {
    los: 5.9,
    patients: 150,
  },
  {
    los: 0.64,
    patients: 597,
  },
  {
    los: 3.44,
    patients: 107,
  },
  {
    los: 2.43,
    patients: 151,
  },
  {
    los: 7.14,
    patients: 42,
  },
  {
    los: 1.1,
    patients: 303,
  },
  {
    los: 8.59,
    patients: 33,
  },
  {
    los: 2.69,
    patients: 295,
  },
  {
    los: 0.95,
    patients: 592,
  },
  {
    los: 1.41,
    patients: 192,
  },
  {
    los: 3.99,
    patients: 183,
  },
  {
    los: 7.86,
    patients: 68,
  },
  {
    los: 6.23,
    patients: 37,
  },
  {
    los: 11.71,
    patients: 24,
  },
  {
    los: 3.84,
    patients: 237,
  },
  {
    los: 2.82,
    patients: 353,
  },
  {
    los: 3.81,
    patients: 245,
  },
  {
    los: 0.93,
    patients: 596,
  },
  {
    los: 2.11,
    patients: 199,
  },
  {
    los: 9.65,
    patients: 40,
  },
  {
    los: 8.77,
    patients: 60,
  },
  {
    los: 11.91,
    patients: 39,
  },
  {
    los: 3.12,
    patients: 166,
  },
  {
    los: 9.51,
    patients: 21,
  },
  {
    los: 2.55,
    patients: 230,
  },
  {
    los: 1.22,
    patients: 180,
  },
  {
    los: 5.4,
    patients: 61,
  },
  {
    los: 2.57,
    patients: 200,
  },
  {
    los: 4.38,
    patients: 86,
  },
  {
    los: 3.64,
    patients: 210,
  },
  {
    los: 6.64,
    patients: 69,
  },
  {
    los: 2.15,
    patients: 178,
  },
  {
    los: 2.5,
    patients: 179,
  },
  {
    los: 4.48,
    patients: 85,
  },
  {
    los: 40.3,
    patients: 1,
  },
  {
    los: 6.89,
    patients: 130,
  },
  {
    los: 6.76,
    patients: 104,
  },
  {
    los: 0.25,
    patients: 570,
  },
  {
    los: 20.51,
    patients: 5,
  },
  {
    los: 2.33,
    patients: 110,
  },
  {
    los: 3.5,
    patients: 133,
  },
  {
    los: 5.08,
    patients: 114,
  },
  {
    los: 12.88,
    patients: 23,
  },
  {
    los: 1.8,
    patients: 493,
  },
  {
    los: 17.02,
    patients: 6,
  },
  {
    los: 2.26,
    patients: 101,
  },
  {
    los: 10.74,
    patients: 38,
  },
  {
    los: 7.73,
    patients: 81,
  },
  {
    los: 15.7,
    patients: 7,
  },
  {
    los: 18.14,
    patients: 6,
  },
  {
    los: 12.37,
    patients: 4,
  },
  {
    los: 12.5,
    patients: 14,
  },
  {
    los: 11.58,
    patients: 19,
  },
  {
    los: 8.9,
    patients: 57,
  },
  {
    los: 1.6,
    patients: 325,
  },
  {
    los: 15.56,
    patients: 10,
  },
  {
    los: 36.94,
    patients: 2,
  },
  {
    los: 18.54,
    patients: 4,
  },
  {
    los: 17.99,
    patients: 5,
  },
  {
    los: 36.95,
    patients: 1,
  },
  {
    los: 5.87,
    patients: 128,
  },
  {
    los: 6.37,
    patients: 28,
  },
  {
    los: 7.71,
    patients: 67,
  },
  {
    los: 8.45,
    patients: 23,
  },
  {
    los: 21.99,
    patients: 4,
  },
  {
    los: 16.82,
    patients: 12,
  },
  {
    los: 2.3,
    patients: 110,
  },
  {
    los: 3.19,
    patients: 110,
  },
  {
    los: 6.24,
    patients: 29,
  },
  {
    los: 6.99,
    patients: 89,
  },
  {
    los: 10.49,
    patients: 16,
  },
  {
    los: 10.03,
    patients: 40,
  },
  {
    los: 6.06,
    patients: 88,
  },
  {
    los: 8.21,
    patients: 16,
  },
  {
    los: 5.33,
    patients: 29,
  },
  {
    los: 4.16,
    patients: 92,
  },
  {
    los: 11.89,
    patients: 30,
  },
  {
    los: 13.92,
    patients: 13,
  },
  {
    los: 6.36,
    patients: 30,
  },
  {
    los: 7.55,
    patients: 44,
  },
  {
    los: 4.2,
    patients: 87,
  },
  {
    los: 7.62,
    patients: 65,
  },
  {
    los: 5.71,
    patients: 112,
  },
  {
    los: 1.51,
    patients: 230,
  },
  {
    los: 11.12,
    patients: 16,
  },
  {
    los: 3.04,
    patients: 268,
  },
  {
    los: 5.56,
    patients: 89,
  },
  {
    los: 5.37,
    patients: 41,
  },
  {
    los: 24.69,
    patients: 8,
  },
  {
    los: 8.02,
    patients: 52,
  },
  {
    los: 8.89,
    patients: 39,
  },
  {
    los: 36.31,
    patients: 1,
  },
  {
    los: 10.8,
    patients: 46,
  },
  {
    los: 7.24,
    patients: 33,
  },
  {
    los: 4.88,
    patients: 200,
  },
  {
    los: 31.53,
    patients: 1,
  },
  {
    los: 2.54,
    patients: 181,
  },
  {
    los: 5.1,
    patients: 107,
  },
  {
    los: 8.63,
    patients: 38,
  },
  {
    los: 11.97,
    patients: 24,
  },
  {
    los: 17.73,
    patients: 9,
  },
  {
    los: 2.35,
    patients: 105,
  },
  {
    los: 5.66,
    patients: 107,
  },
  {
    los: 1.94,
    patients: 408,
  },
  {
    los: 8.41,
    patients: 21,
  },
  {
    los: 1.3,
    patients: 159,
  },
  {
    los: 7.21,
    patients: 29,
  },
  {
    los: 11.34,
    patients: 6,
  },
  {
    los: 19.42,
    patients: 3,
  },
  {
    los: 9.94,
    patients: 40,
  },
  {
    los: 4.04,
    patients: 175,
  },
  {
    los: 9.68,
    patients: 35,
  },
  {
    los: 4.27,
    patients: 71,
  },
  {
    los: 2.28,
    patients: 122,
  },
  {
    los: 13.5,
    patients: 10,
  },
  {
    los: 16.42,
    patients: 9,
  },
  {
    los: 10.44,
    patients: 14,
  },
  {
    los: 3.4,
    patients: 107,
  },
  {
    los: 1.61,
    patients: 320,
  },
  {
    los: 17.88,
    patients: 8,
  },
  {
    los: 1.62,
    patients: 364,
  },
  {
    los: 4.17,
    patients: 91,
  },
  {
    los: 10.82,
    patients: 25,
  },
  {
    los: 1.43,
    patients: 229,
  },
  {
    los: 4.41,
    patients: 70,
  },
  {
    los: 0.29,
    patients: 544,
  },
  {
    los: 5.74,
    patients: 137,
  },
  {
    los: 7.8,
    patients: 71,
  },
  {
    los: 4.79,
    patients: 189,
  },
  {
    los: 10.72,
    patients: 33,
  },
  {
    los: 7.51,
    patients: 35,
  },
  {
    los: 4.84,
    patients: 200,
  },
  {
    los: 0.01,
    patients: 81,
  },
  {
    los: 13.48,
    patients: 13,
  },
  {
    los: 3.79,
    patients: 261,
  },
  {
    los: 9.91,
    patients: 41,
  },
  {
    los: 4.13,
    patients: 130,
  },
  {
    los: 4.33,
    patients: 52,
  },
  {
    los: 12.1,
    patients: 20,
  },
  {
    los: 6.15,
    patients: 62,
  },
  {
    los: 16.02,
    patients: 8,
  },
  {
    los: 2.88,
    patients: 396,
  },
  {
    los: 0.74,
    patients: 616,
  },
  {
    los: 15.38,
    patients: 5,
  },
  {
    los: 21.61,
    patients: 6,
  },
  {
    los: 3.34,
    patients: 74,
  },
  {
    los: 10.06,
    patients: 26,
  },
  {
    los: 8.13,
    patients: 30,
  },
  {
    los: 3.01,
    patients: 259,
  },
  {
    los: 25.17,
    patients: 3,
  },
  {
    los: 1.05,
    patients: 444,
  },
  {
    los: 10.6,
    patients: 34,
  },
  {
    los: 3.15,
    patients: 142,
  },
  {
    los: 13.84,
    patients: 12,
  },
  {
    los: 8.24,
    patients: 20,
  },
  {
    los: 11.06,
    patients: 23,
  },
  {
    los: 5.24,
    patients: 40,
  },
  {
    los: 5.78,
    patients: 165,
  },
  {
    los: 5.18,
    patients: 62,
  },
  {
    los: 4.58,
    patients: 144,
  },
  {
    los: 4.97,
    patients: 156,
  },
  {
    los: 3.06,
    patients: 186,
  },
  {
    los: 5.36,
    patients: 42,
  },
  {
    los: 8.71,
    patients: 54,
  },
  {
    los: 9.19,
    patients: 13,
  },
  {
    los: 15.76,
    patients: 12,
  },
  {
    los: 10.71,
    patients: 26,
  },
  {
    los: 7.81,
    patients: 72,
  },
  {
    los: 7.42,
    patients: 29,
  },
  {
    los: 30.7,
    patients: 6,
  },
  {
    los: 5.22,
    patients: 50,
  },
  {
    los: 7.03,
    patients: 85,
  },
  {
    los: 3.63,
    patients: 204,
  },
  {
    los: 10.45,
    patients: 16,
  },
  {
    los: 6.51,
    patients: 56,
  },
  {
    los: 17.27,
    patients: 2,
  },
  {
    los: 7.56,
    patients: 64,
  },
  {
    los: 4.55,
    patients: 130,
  },
  {
    los: 5.63,
    patients: 127,
  },
  {
    los: 1.45,
    patients: 235,
  },
  {
    los: 2.48,
    patients: 191,
  },
  {
    los: 8.05,
    patients: 46,
  },
  {
    los: 17.8,
    patients: 12,
  },
  {
    los: 4.02,
    patients: 182,
  },
  {
    los: 3.23,
    patients: 96,
  },
  {
    los: 9.41,
    patients: 17,
  },
  {
    los: 17.68,
    patients: 9,
  },
  {
    los: 10.91,
    patients: 29,
  },
  {
    los: 2.03,
    patients: 305,
  },
  {
    los: 13.75,
    patients: 22,
  },
  {
    los: 6.58,
    patients: 58,
  },
  {
    los: 9.57,
    patients: 22,
  },
  {
    los: 27.81,
    patients: 3,
  },
  {
    los: 3.87,
    patients: 256,
  },
  {
    los: 6.57,
    patients: 60,
  },
  {
    los: 22.76,
    patients: 5,
  },
  {
    los: 5.51,
    patients: 66,
  },
  {
    los: 5.84,
    patients: 145,
  },
  {
    los: 35.83,
    patients: 2,
  },
  {
    los: 9.64,
    patients: 35,
  },
  {
    los: 7.72,
    patients: 80,
  },
  {
    los: 3.41,
    patients: 101,
  },
  {
    los: 5.42,
    patients: 49,
  },
  {
    los: 5.29,
    patients: 46,
  },
  {
    los: 7.52,
    patients: 37,
  },
  {
    los: 38.86,
    patients: 1,
  },
  {
    los: 16.29,
    patients: 2,
  },
  {
    los: 4.26,
    patients: 57,
  },
  {
    los: 11.48,
    patients: 14,
  },
  {
    los: 1.24,
    patients: 171,
  },
  {
    los: 5.01,
    patients: 139,
  },
  {
    los: 7.85,
    patients: 93,
  },
  {
    los: 9.8,
    patients: 33,
  },
  {
    los: 10.01,
    patients: 33,
  },
  {
    los: 22.55,
    patients: 3,
  },
  {
    los: 3.11,
    patients: 147,
  },
  {
    los: 6.77,
    patients: 116,
  },
  {
    los: 17.96,
    patients: 8,
  },
  {
    los: 8.25,
    patients: 16,
  },
  {
    los: 23.12,
    patients: 3,
  },
  {
    los: 17.86,
    patients: 15,
  },
  {
    los: 6.7,
    patients: 98,
  },
  {
    los: 8.86,
    patients: 54,
  },
  {
    los: 21.12,
    patients: 3,
  },
  {
    los: 5.98,
    patients: 119,
  },
  {
    los: 7.79,
    patients: 73,
  },
  {
    los: 12.54,
    patients: 8,
  },
  {
    los: 22.3,
    patients: 2,
  },
  {
    los: 5.94,
    patients: 118,
  },
  {
    los: 2.07,
    patients: 279,
  },
  {
    los: 5.07,
    patients: 129,
  },
  {
    los: 7.82,
    patients: 69,
  },
  {
    los: 4.36,
    patients: 66,
  },
  {
    los: 7.49,
    patients: 37,
  },
  {
    los: 8.75,
    patients: 57,
  },
  {
    los: 4.64,
    patients: 155,
  },
  {
    los: 16.64,
    patients: 10,
  },
  {
    los: 11.01,
    patients: 17,
  },
  {
    los: 4.56,
    patients: 106,
  },
  {
    los: 25.53,
    patients: 2,
  },
  {
    los: 3.42,
    patients: 108,
  },
  {
    los: 8.65,
    patients: 45,
  },
  {
    los: 3.6,
    patients: 218,
  },
  {
    los: 5.54,
    patients: 77,
  },
  {
    los: 9.46,
    patients: 17,
  },
  {
    los: 6.32,
    patients: 30,
  },
  {
    los: 5.65,
    patients: 122,
  },
  {
    los: 23.71,
    patients: 5,
  },
  {
    los: 6.9,
    patients: 121,
  },
  {
    los: 12.17,
    patients: 9,
  },
  {
    los: 10.16,
    patients: 29,
  },
  {
    los: 4.03,
    patients: 176,
  },
  {
    los: 40.01,
    patients: 1,
  },
  {
    los: 11.76,
    patients: 28,
  },
  {
    los: 5.95,
    patients: 134,
  },
  {
    los: 4.86,
    patients: 179,
  },
  {
    los: 2.94,
    patients: 320,
  },
  {
    los: 10.23,
    patients: 6,
  },
  {
    los: 3.67,
    patients: 247,
  },
  {
    los: 3.55,
    patients: 158,
  },
  {
    los: 9.47,
    patients: 21,
  },
  {
    los: 13.91,
    patients: 18,
  },
  {
    los: 14.89,
    patients: 16,
  },
  {
    los: 12.9,
    patients: 27,
  },
  {
    los: 1.32,
    patients: 160,
  },
  {
    los: 3.52,
    patients: 158,
  },
  {
    los: 8.09,
    patients: 42,
  },
  {
    los: 1.27,
    patients: 149,
  },
  {
    los: 8.03,
    patients: 53,
  },
  {
    los: 25.96,
    patients: 3,
  },
  {
    los: 19.09,
    patients: 2,
  },
  {
    los: 6.35,
    patients: 40,
  },
  {
    los: 3.69,
    patients: 244,
  },
  {
    los: 14.1,
    patients: 12,
  },
  {
    los: 3.16,
    patients: 125,
  },
  {
    los: 14.65,
    patients: 17,
  },
  {
    los: 12.85,
    patients: 20,
  },
  {
    los: 1.12,
    patients: 277,
  },
  {
    los: 9.93,
    patients: 51,
  },
  {
    los: 8.49,
    patients: 29,
  },
  {
    los: 13.05,
    patients: 18,
  },
  {
    los: 2.34,
    patients: 115,
  },
  {
    los: 20.89,
    patients: 8,
  },
  {
    los: 6.68,
    patients: 93,
  },
  {
    los: 14.09,
    patients: 14,
  },
  {
    los: 11.84,
    patients: 29,
  },
  {
    los: 16.8,
    patients: 11,
  },
  {
    los: 9.18,
    patients: 19,
  },
  {
    los: 5.02,
    patients: 136,
  },
  {
    los: 12.8,
    patients: 19,
  },
  {
    los: 3.91,
    patients: 244,
  },
  {
    los: 10.17,
    patients: 12,
  },
  {
    los: 14.79,
    patients: 23,
  },
  {
    los: 11.51,
    patients: 17,
  },
  {
    los: 4.53,
    patients: 112,
  },
  {
    los: 18.78,
    patients: 3,
  },
  {
    los: 5.31,
    patients: 53,
  },
  {
    los: 12.12,
    patients: 12,
  },
  {
    los: 18.87,
    patients: 7,
  },
  {
    los: 14.93,
    patients: 15,
  },
  {
    los: 8.81,
    patients: 50,
  },
  {
    los: 16.44,
    patients: 4,
  },
  {
    los: 21.55,
    patients: 1,
  },
  {
    los: 3.24,
    patients: 84,
  },
  {
    los: 12.03,
    patients: 26,
  },
  {
    los: 10.69,
    patients: 29,
  },
  {
    los: 6.13,
    patients: 66,
  },
  {
    los: 11.95,
    patients: 23,
  },
  {
    los: 2.32,
    patients: 92,
  },
  {
    los: 8.19,
    patients: 22,
  },
  {
    los: 21.82,
    patients: 4,
  },
  {
    los: 15.19,
    patients: 5,
  },
  {
    los: 17.36,
    patients: 1,
  },
  {
    los: 6.87,
    patients: 123,
  },
  {
    los: 13.95,
    patients: 21,
  },
  {
    los: 19.49,
    patients: 7,
  },
  {
    los: 11.75,
    patients: 25,
  },
  {
    los: 15.69,
    patients: 13,
  },
  {
    los: 6.31,
    patients: 30,
  },
  {
    los: 24.04,
    patients: 2,
  },
  {
    los: 35.11,
    patients: 1,
  },
  {
    los: 6.03,
    patients: 95,
  },
  {
    los: 5.58,
    patients: 91,
  },
  {
    los: 5.48,
    patients: 67,
  },
  {
    los: 12.86,
    patients: 26,
  },
  {
    los: 12.78,
    patients: 27,
  },
  {
    los: 29.68,
    patients: 4,
  },
  {
    los: 10.84,
    patients: 41,
  },
  {
    los: 29.74,
    patients: 5,
  },
  {
    los: 19.11,
    patients: 7,
  },
  {
    los: 6.78,
    patients: 115,
  },
  {
    los: 6.07,
    patients: 84,
  },
  {
    los: 18.9,
    patients: 7,
  },
  {
    los: 3.9,
    patients: 248,
  },
  {
    los: 17.67,
    patients: 9,
  },
  {
    los: 11.03,
    patients: 25,
  },
  {
    los: 6.47,
    patients: 41,
  },
  {
    los: 13.86,
    patients: 27,
  },
  {
    los: 12.84,
    patients: 26,
  },
  {
    los: 5.2,
    patients: 59,
  },
  {
    los: 2.41,
    patients: 145,
  },
  {
    los: 8.53,
    patients: 43,
  },
  {
    los: 1.17,
    patients: 234,
  },
  {
    los: 14.74,
    patients: 13,
  },
  {
    los: 3.39,
    patients: 84,
  },
  {
    los: 5.39,
    patients: 42,
  },
  {
    los: 4.62,
    patients: 155,
  },
  {
    los: 7.53,
    patients: 40,
  },
  {
    los: 9.1,
    patients: 20,
  },
  {
    los: 11.42,
    patients: 6,
  },
  {
    los: 1.39,
    patients: 151,
  },
  {
    los: 1.26,
    patients: 151,
  },
  {
    los: 3.14,
    patients: 139,
  },
  {
    los: 13.01,
    patients: 19,
  },
  {
    los: 10.02,
    patients: 24,
  },
  {
    los: 31.89,
    patients: 1,
  },
  {
    los: 5.13,
    patients: 70,
  },
  {
    los: 16.98,
    patients: 16,
  },
  {
    los: 4.08,
    patients: 144,
  },
  {
    los: 7.3,
    patients: 31,
  },
  {
    los: 2.29,
    patients: 110,
  },
  {
    los: 21.09,
    patients: 4,
  },
  {
    los: 3.32,
    patients: 70,
  },
  {
    los: 3.38,
    patients: 85,
  },
  {
    los: 8.42,
    patients: 19,
  },
  {
    los: 13.97,
    patients: 20,
  },
  {
    los: 36.39,
    patients: 1,
  },
  {
    los: 5.89,
    patients: 139,
  },
  {
    los: 20.63,
    patients: 5,
  },
  {
    los: 9.11,
    patients: 34,
  },
  {
    los: 4.23,
    patients: 69,
  },
  {
    los: 6.92,
    patients: 106,
  },
  {
    los: 10.08,
    patients: 31,
  },
  {
    los: 21.81,
    patients: 9,
  },
  {
    los: 9.05,
    patients: 46,
  },
  {
    los: 10.97,
    patients: 26,
  },
  {
    los: 14.05,
    patients: 12,
  },
  {
    los: 11.54,
    patients: 16,
  },
  {
    los: 13.12,
    patients: 8,
  },
  {
    los: 39.95,
    patients: 1,
  },
  {
    los: 20.99,
    patients: 5,
  },
  {
    los: 14.18,
    patients: 5,
  },
  {
    los: 7.88,
    patients: 92,
  },
  {
    los: 18.94,
    patients: 9,
  },
  {
    los: 25.71,
    patients: 2,
  },
  {
    los: 6.82,
    patients: 102,
  },
  {
    los: 13.79,
    patients: 18,
  },
  {
    los: 7.09,
    patients: 61,
  },
  {
    los: 11.83,
    patients: 38,
  },
  {
    los: 4.49,
    patients: 90,
  },
  {
    los: 8.37,
    patients: 16,
  },
  {
    los: 13.7,
    patients: 14,
  },
  {
    los: 22.35,
    patients: 2,
  },
  {
    los: 12.46,
    patients: 8,
  },
  {
    los: 10.78,
    patients: 49,
  },
  {
    los: 5.62,
    patients: 111,
  },
  {
    los: 24.98,
    patients: 4,
  },
  {
    los: 3.27,
    patients: 88,
  },
  {
    los: 13.22,
    patients: 7,
  },
  {
    los: 6.39,
    patients: 47,
  },
  {
    los: 21.64,
    patients: 3,
  },
  {
    los: 4.4,
    patients: 83,
  },
  {
    los: 16.86,
    patients: 13,
  },
  {
    los: 11.52,
    patients: 14,
  },
  {
    los: 11.17,
    patients: 13,
  },
  {
    los: 5.5,
    patients: 75,
  },
  {
    los: 9.81,
    patients: 42,
  },
  {
    los: 2.16,
    patients: 155,
  },
  {
    los: 9.31,
    patients: 15,
  },
  {
    los: 7.63,
    patients: 61,
  },
  {
    los: 5.47,
    patients: 59,
  },
  {
    los: 9.26,
    patients: 13,
  },
  {
    los: 7.39,
    patients: 28,
  },
  {
    los: 20.85,
    patients: 8,
  },
  {
    los: 5.49,
    patients: 66,
  },
  {
    los: 7.7,
    patients: 84,
  },
  {
    los: 13.07,
    patients: 17,
  },
  {
    los: 8.78,
    patients: 71,
  },
  {
    los: 12.34,
    patients: 5,
  },
  {
    los: 5.79,
    patients: 139,
  },
  {
    los: 3.71,
    patients: 224,
  },
  {
    los: 4.1,
    patients: 134,
  },
  {
    los: 5.72,
    patients: 120,
  },
  {
    los: 20.01,
    patients: 15,
  },
  {
    los: 4.25,
    patients: 66,
  },
  {
    los: 3.43,
    patients: 89,
  },
  {
    los: 5.57,
    patients: 93,
  },
  {
    los: 9.58,
    patients: 28,
  },
  {
    los: 8.17,
    patients: 30,
  },
  {
    los: 8.97,
    patients: 60,
  },
  {
    los: 3.53,
    patients: 167,
  },
  {
    los: 18.52,
    patients: 7,
  },
  {
    los: 10.59,
    patients: 22,
  },
  {
    los: 14.88,
    patients: 25,
  },
  {
    los: 7.35,
    patients: 26,
  },
  {
    los: 25.55,
    patients: 4,
  },
  {
    los: 3.45,
    patients: 123,
  },
  {
    los: 17.75,
    patients: 13,
  },
  {
    los: 11.55,
    patients: 18,
  },
  {
    los: 9.49,
    patients: 20,
  },
  {
    los: 29.28,
    patients: 2,
  },
  {
    los: 3.26,
    patients: 78,
  },
  {
    los: 8.82,
    patients: 72,
  },
  {
    los: 7.31,
    patients: 30,
  },
  {
    los: 23.8,
    patients: 8,
  },
  {
    los: 4.74,
    patients: 180,
  },
  {
    los: 19.08,
    patients: 3,
  },
  {
    los: 26.41,
    patients: 2,
  },
  {
    los: 13.9,
    patients: 19,
  },
  {
    los: 6.48,
    patients: 51,
  },
  {
    los: 7.23,
    patients: 29,
  },
  {
    los: 21.47,
    patients: 4,
  },
  {
    los: 5.44,
    patients: 49,
  },
  {
    los: 4.35,
    patients: 58,
  },
  {
    los: 11.18,
    patients: 9,
  },
  {
    los: 2.46,
    patients: 149,
  },
  {
    los: 8.51,
    patients: 23,
  },
  {
    los: 11.36,
    patients: 6,
  },
  {
    los: 11.45,
    patients: 12,
  },
  {
    los: 8.55,
    patients: 35,
  },
  {
    los: 11.4,
    patients: 14,
  },
  {
    los: 4.05,
    patients: 169,
  },
  {
    los: 18.85,
    patients: 6,
  },
  {
    los: 4.32,
    patients: 56,
  },
  {
    los: 6.6,
    patients: 62,
  },
  {
    los: 17.01,
    patients: 5,
  },
  {
    los: 11.24,
    patients: 9,
  },
  {
    los: 10.31,
    patients: 11,
  },
  {
    los: 6.86,
    patients: 105,
  },
  {
    los: 8.32,
    patients: 22,
  },
  {
    los: 4.09,
    patients: 132,
  },
  {
    los: 6.33,
    patients: 27,
  },
  {
    los: 14.82,
    patients: 20,
  },
  {
    los: 6.27,
    patients: 31,
  },
  {
    los: 7.18,
    patients: 29,
  },
  {
    los: 4.21,
    patients: 84,
  },
  {
    los: 8.2,
    patients: 21,
  },
  {
    los: 13.56,
    patients: 13,
  },
  {
    los: 42.04,
    patients: 1,
  },
  {
    los: 4.91,
    patients: 175,
  },
  {
    los: 8.5,
    patients: 25,
  },
  {
    los: 5.97,
    patients: 112,
  },
  {
    los: 7.05,
    patients: 86,
  },
  {
    los: 18.58,
    patients: 4,
  },
  {
    los: 6.45,
    patients: 54,
  },
  {
    los: 11.85,
    patients: 33,
  },
  {
    los: 9.9,
    patients: 53,
  },
  {
    los: 6.97,
    patients: 107,
  },
  {
    los: 44.8,
    patients: 1,
  },
  {
    los: 28.64,
    patients: 2,
  },
  {
    los: 11.86,
    patients: 30,
  },
  {
    los: 28.07,
    patients: 1,
  },
  {
    los: 12.65,
    patients: 12,
  },
  {
    los: 8.28,
    patients: 15,
  },
  {
    los: 36.62,
    patients: 2,
  },
  {
    los: 7.58,
    patients: 60,
  },
  {
    los: 7.1,
    patients: 58,
  },
  {
    los: 15.49,
    patients: 3,
  },
  {
    los: 12.26,
    patients: 8,
  },
  {
    los: 42.65,
    patients: 2,
  },
  {
    los: 7.15,
    patients: 51,
  },
  {
    los: 27.06,
    patients: 4,
  },
  {
    los: 12.06,
    patients: 18,
  },
  {
    los: 4.81,
    patients: 189,
  },
  {
    los: 4.66,
    patients: 160,
  },
  {
    los: 6.01,
    patients: 108,
  },
  {
    los: 14.7,
    patients: 11,
  },
  {
    los: 18.23,
    patients: 6,
  },
  {
    los: 10.22,
    patients: 9,
  },
  {
    los: 17.82,
    patients: 12,
  },
  {
    los: 8.91,
    patients: 49,
  },
  {
    los: 14.4,
    patients: 4,
  },
  {
    los: 1.38,
    patients: 168,
  },
  {
    los: 0.02,
    patients: 116,
  },
  {
    los: 7.95,
    patients: 72,
  },
  {
    los: 8.95,
    patients: 47,
  },
  {
    los: 15.86,
    patients: 12,
  },
  {
    los: 6.2,
    patients: 39,
  },
  {
    los: 10.52,
    patients: 27,
  },
  {
    los: 7.6,
    patients: 51,
  },
  {
    los: 8.96,
    patients: 66,
  },
  {
    los: 8.06,
    patients: 47,
  },
  {
    los: 9.62,
    patients: 31,
  },
  {
    los: 9.39,
    patients: 16,
  },
  {
    los: 13.06,
    patients: 6,
  },
  {
    los: 5.04,
    patients: 131,
  },
  {
    los: 8.66,
    patients: 52,
  },
  {
    los: 17.92,
    patients: 6,
  },
  {
    los: 8.38,
    patients: 13,
  },
  {
    los: 4.73,
    patients: 155,
  },
  {
    los: 23.09,
    patients: 2,
  },
  {
    los: 13.4,
    patients: 15,
  },
  {
    los: 7.69,
    patients: 72,
  },
  {
    los: 12.83,
    patients: 26,
  },
  {
    los: 6.11,
    patients: 66,
  },
  {
    los: 15.04,
    patients: 10,
  },
  {
    los: 11.65,
    patients: 17,
  },
  {
    los: 19.8,
    patients: 9,
  },
  {
    los: 27.18,
    patients: 2,
  },
  {
    los: 25.75,
    patients: 8,
  },
  {
    los: 12.79,
    patients: 18,
  },
  {
    los: 9.66,
    patients: 38,
  },
  {
    los: 44.82,
    patients: 2,
  },
  {
    los: 26.02,
    patients: 3,
  },
  {
    los: 35.05,
    patients: 2,
  },
  {
    los: 5.41,
    patients: 44,
  },
  {
    los: 7.19,
    patients: 20,
  },
  {
    los: 5.52,
    patients: 70,
  },
  {
    los: 44.88,
    patients: 2,
  },
  {
    los: 27.02,
    patients: 1,
  },
  {
    los: 14.62,
    patients: 20,
  },
  {
    los: 10.05,
    patients: 30,
  },
  {
    los: 15.44,
    patients: 5,
  },
  {
    los: 6.69,
    patients: 73,
  },
  {
    los: 9.92,
    patients: 41,
  },
  {
    los: 6.55,
    patients: 74,
  },
  {
    los: 5.96,
    patients: 133,
  },
  {
    los: 10.64,
    patients: 28,
  },
  {
    los: 11.6,
    patients: 13,
  },
  {
    los: 13.67,
    patients: 15,
  },
  {
    los: 5.11,
    patients: 81,
  },
  {
    los: 12.09,
    patients: 12,
  },
  {
    los: 13.1,
    patients: 17,
  },
  {
    los: 9.32,
    patients: 13,
  },
  {
    los: 5.55,
    patients: 81,
  },
  {
    los: 11.87,
    patients: 28,
  },
  {
    los: 10.04,
    patients: 25,
  },
  {
    los: 14.01,
    patients: 18,
  },
  {
    los: 12.76,
    patients: 25,
  },
  {
    los: 7.57,
    patients: 56,
  },
  {
    los: 6.53,
    patients: 55,
  },
  {
    los: 7.76,
    patients: 75,
  },
  {
    los: 8.18,
    patients: 31,
  },
  {
    los: 35.33,
    patients: 2,
  },
  {
    los: 33.42,
    patients: 2,
  },
  {
    los: 8.22,
    patients: 25,
  },
  {
    los: 7.96,
    patients: 70,
  },
  {
    los: 7.44,
    patients: 34,
  },
  {
    los: 10.65,
    patients: 37,
  },
  {
    los: 2.36,
    patients: 92,
  },
  {
    los: 8.61,
    patients: 42,
  },
  {
    los: 6.5,
    patients: 58,
  },
  {
    los: 26.87,
    patients: 3,
  },
  {
    los: 5.14,
    patients: 67,
  },
  {
    los: 4.3,
    patients: 59,
  },
  {
    los: 12.29,
    patients: 9,
  },
  {
    los: 8.93,
    patients: 57,
  },
  {
    los: 10.73,
    patients: 32,
  },
  {
    los: 28.84,
    patients: 1,
  },
  {
    los: 30.05,
    patients: 1,
  },
  {
    los: 10.37,
    patients: 9,
  },
  {
    los: 3.28,
    patients: 70,
  },
  {
    los: 13.26,
    patients: 6,
  },
  {
    los: 14.53,
    patients: 9,
  },
  {
    los: 27.1,
    patients: 2,
  },
  {
    los: 19.61,
    patients: 4,
  },
  {
    los: 21.72,
    patients: 4,
  },
  {
    los: 4.39,
    patients: 66,
  },
  {
    los: 3.33,
    patients: 82,
  },
  {
    los: 12.58,
    patients: 9,
  },
  {
    los: 7.01,
    patients: 85,
  },
  {
    los: 6.12,
    patients: 83,
  },
  {
    los: 5.09,
    patients: 98,
  },
  {
    los: 13.52,
    patients: 8,
  },
  {
    los: 21.76,
    patients: 8,
  },
  {
    los: 12.56,
    patients: 10,
  },
  {
    los: 14.42,
    patients: 6,
  },
  {
    los: 19.45,
    patients: 2,
  },
  {
    los: 0.03,
    patients: 174,
  },
  {
    los: 13.66,
    patients: 18,
  },
  {
    los: 19.72,
    patients: 9,
  },
  {
    los: 10.41,
    patients: 11,
  },
  {
    los: 8.56,
    patients: 26,
  },
  {
    los: 20.66,
    patients: 3,
  },
  {
    los: 12.95,
    patients: 16,
  },
  {
    los: 17.69,
    patients: 10,
  },
  {
    los: 15.54,
    patients: 5,
  },
  {
    los: 11.3,
    patients: 10,
  },
  {
    los: 6.18,
    patients: 44,
  },
  {
    los: 10.75,
    patients: 24,
  },
  {
    los: 5.16,
    patients: 72,
  },
  {
    los: 20.1,
    patients: 4,
  },
  {
    los: 13.62,
    patients: 16,
  },
  {
    los: 5.06,
    patients: 93,
  },
  {
    los: 5.23,
    patients: 54,
  },
  {
    los: 2.24,
    patients: 110,
  },
  {
    los: 15.8,
    patients: 8,
  },
  {
    los: 12.62,
    patients: 21,
  },
  {
    los: 8.14,
    patients: 31,
  },
  {
    los: 9.02,
    patients: 34,
  },
  {
    los: 4.34,
    patients: 63,
  },
  {
    los: 7.11,
    patients: 37,
  },
  {
    los: 18.99,
    patients: 8,
  },
  {
    los: 8.84,
    patients: 64,
  },
  {
    los: 8.92,
    patients: 57,
  },
  {
    los: 8.26,
    patients: 13,
  },
  {
    los: 20.19,
    patients: 3,
  },
  {
    los: 9.24,
    patients: 18,
  },
  {
    los: 10.48,
    patients: 15,
  },
  {
    los: 4.37,
    patients: 56,
  },
  {
    los: 39.75,
    patients: 1,
  },
  {
    los: 14.87,
    patients: 13,
  },
  {
    los: 6.49,
    patients: 42,
  },
  {
    los: 35.65,
    patients: 1,
  },
  {
    los: 13.03,
    patients: 13,
  },
  {
    los: 21.54,
    patients: 5,
  },
  {
    los: 28.78,
    patients: 4,
  },
  {
    los: 11.46,
    patients: 13,
  },
  {
    los: 7.07,
    patients: 52,
  },
  {
    los: 12.22,
    patients: 4,
  },
  {
    los: 11.88,
    patients: 30,
  },
  {
    los: 8.99,
    patients: 39,
  },
  {
    los: 28.97,
    patients: 4,
  },
  {
    los: 18.09,
    patients: 6,
  },
  {
    los: 8.94,
    patients: 48,
  },
  {
    los: 13.89,
    patients: 15,
  },
  {
    los: 23.36,
    patients: 1,
  },
  {
    los: 5.15,
    patients: 79,
  },
  {
    los: 18.83,
    patients: 6,
  },
  {
    los: 7.12,
    patients: 61,
  },
  {
    los: 11.2,
    patients: 10,
  },
  {
    los: 21.14,
    patients: 4,
  },
  {
    los: 9.85,
    patients: 54,
  },
  {
    los: 28.7,
    patients: 3,
  },
  {
    los: 10.57,
    patients: 21,
  },
  {
    los: 10.83,
    patients: 26,
  },
  {
    los: 14.28,
    patients: 3,
  },
  {
    los: 8.36,
    patients: 26,
  },
  {
    los: 20.7,
    patients: 5,
  },
  {
    los: 25.51,
    patients: 2,
  },
  {
    los: 9.6,
    patients: 20,
  },
  {
    los: 5.59,
    patients: 110,
  },
  {
    los: 18.89,
    patients: 10,
  },
  {
    los: 10.88,
    patients: 39,
  },
  {
    los: 20.95,
    patients: 2,
  },
  {
    los: 24.36,
    patients: 1,
  },
  {
    los: 19.07,
    patients: 4,
  },
  {
    los: 7.94,
    patients: 72,
  },
  {
    los: 3.96,
    patients: 201,
  },
  {
    los: 6.85,
    patients: 116,
  },
  {
    los: 16.73,
    patients: 8,
  },
  {
    los: 8.16,
    patients: 23,
  },
  {
    los: 10.47,
    patients: 15,
  },
  {
    los: 9.27,
    patients: 15,
  },
  {
    los: 13.08,
    patients: 19,
  },
  {
    los: 3.47,
    patients: 130,
  },
  {
    los: 6.34,
    patients: 34,
  },
  {
    los: 42.44,
    patients: 1,
  },
  {
    los: 38.06,
    patients: 2,
  },
  {
    los: 7.91,
    patients: 68,
  },
  {
    los: 20.74,
    patients: 3,
  },
  {
    los: 9.88,
    patients: 39,
  },
  {
    los: 10.21,
    patients: 14,
  },
  {
    los: 14.03,
    patients: 19,
  },
  {
    los: 10.55,
    patients: 24,
  },
  {
    los: 26.44,
    patients: 2,
  },
  {
    los: 6.1,
    patients: 78,
  },
  {
    los: 25.62,
    patients: 1,
  },
  {
    los: 13.74,
    patients: 21,
  },
  {
    los: 9.01,
    patients: 48,
  },
  {
    los: 21.01,
    patients: 5,
  },
  {
    los: 5.35,
    patients: 47,
  },
  {
    los: 7.93,
    patients: 71,
  },
  {
    los: 8.44,
    patients: 22,
  },
  {
    los: 22.16,
    patients: 5,
  },
  {
    los: 11.98,
    patients: 25,
  },
  {
    los: 17.06,
    patients: 5,
  },
  {
    los: 9.38,
    patients: 12,
  },
  {
    los: 48.82,
    patients: 1,
  },
  {
    los: 7.38,
    patients: 27,
  },
  {
    los: 10.09,
    patients: 28,
  },
  {
    los: 19.58,
    patients: 9,
  },
  {
    los: 7.74,
    patients: 53,
  },
  {
    los: 7.27,
    patients: 22,
  },
  {
    los: 8.62,
    patients: 54,
  },
  {
    los: 22.68,
    patients: 6,
  },
  {
    los: 8.07,
    patients: 51,
  },
  {
    los: 18.67,
    patients: 8,
  },
  {
    los: 11.92,
    patients: 21,
  },
  {
    los: 18.77,
    patients: 9,
  },
  {
    los: 12.57,
    patients: 13,
  },
  {
    los: 6.62,
    patients: 74,
  },
  {
    los: 6.28,
    patients: 32,
  },
  {
    los: 19.59,
    patients: 5,
  },
  {
    los: 29.05,
    patients: 3,
  },
  {
    los: 11.04,
    patients: 20,
  },
  {
    los: 13.72,
    patients: 26,
  },
  {
    los: 12.05,
    patients: 17,
  },
  {
    los: 32.51,
    patients: 1,
  },
  {
    los: 15.16,
    patients: 5,
  },
  {
    los: 16.65,
    patients: 9,
  },
  {
    los: 6.98,
    patients: 87,
  },
  {
    los: 21.71,
    patients: 4,
  },
  {
    los: 3.29,
    patients: 85,
  },
  {
    los: 13.71,
    patients: 10,
  },
  {
    los: 35.09,
    patients: 4,
  },
  {
    los: 16.5,
    patients: 6,
  },
  {
    los: 21.39,
    patients: 1,
  },
  {
    los: 9.95,
    patients: 49,
  },
  {
    los: 19.89,
    patients: 9,
  },
  {
    los: 12.19,
    patients: 9,
  },
  {
    los: 27.23,
    patients: 3,
  },
  {
    los: 9.14,
    patients: 23,
  },
  {
    los: 12.55,
    patients: 10,
  },
  {
    los: 27.45,
    patients: 3,
  },
  {
    los: 9.79,
    patients: 46,
  },
  {
    los: 7.89,
    patients: 68,
  },
  {
    los: 20.18,
    patients: 4,
  },
  {
    los: 7.47,
    patients: 31,
  },
  {
    los: 32.12,
    patients: 2,
  },
  {
    los: 36.51,
    patients: 1,
  },
  {
    los: 6.54,
    patients: 63,
  },
  {
    los: 26.72,
    patients: 3,
  },
  {
    los: 12.11,
    patients: 16,
  },
  {
    los: 7.43,
    patients: 35,
  },
  {
    los: 18.97,
    patients: 5,
  },
  {
    los: 18.04,
    patients: 8,
  },
  {
    los: 8.35,
    patients: 22,
  },
  {
    los: 9.08,
    patients: 37,
  },
  {
    los: 17.72,
    patients: 7,
  },
  {
    los: 10.4,
    patients: 15,
  },
  {
    los: 10.43,
    patients: 18,
  },
  {
    los: 11.79,
    patients: 30,
  },
  {
    los: 15.18,
    patients: 9,
  },
  {
    los: 22.69,
    patients: 2,
  },
  {
    los: 17.6,
    patients: 3,
  },
  {
    los: 9.84,
    patients: 49,
  },
  {
    los: 10.67,
    patients: 24,
  },
  {
    los: 5.46,
    patients: 51,
  },
  {
    los: 17.2,
    patients: 4,
  },
  {
    los: 16.52,
    patients: 6,
  },
  {
    los: 10.34,
    patients: 10,
  },
  {
    los: 9.74,
    patients: 36,
  },
  {
    los: 11.35,
    patients: 6,
  },
  {
    los: 22.9,
    patients: 5,
  },
  {
    los: 12.27,
    patients: 5,
  },
  {
    los: 24.84,
    patients: 6,
  },
  {
    los: 27.05,
    patients: 5,
  },
  {
    los: 14.51,
    patients: 10,
  },
  {
    los: 39.92,
    patients: 2,
  },
  {
    los: 7.78,
    patients: 78,
  },
  {
    los: 28.66,
    patients: 3,
  },
  {
    los: 6.17,
    patients: 44,
  },
  {
    los: 16.31,
    patients: 3,
  },
  {
    los: 9.73,
    patients: 35,
  },
  {
    los: 12.74,
    patients: 25,
  },
  {
    los: 11.23,
    patients: 8,
  },
  {
    los: 20.83,
    patients: 8,
  },
  {
    los: 10.61,
    patients: 18,
  },
  {
    los: 9.83,
    patients: 42,
  },
  {
    los: 7.06,
    patients: 63,
  },
  {
    los: 7.13,
    patients: 44,
  },
  {
    los: 7.61,
    patients: 40,
  },
  {
    los: 21.04,
    patients: 5,
  },
  {
    los: 10.38,
    patients: 15,
  },
  {
    los: 6.44,
    patients: 48,
  },
  {
    los: 15.82,
    patients: 13,
  },
  {
    los: 19.91,
    patients: 9,
  },
  {
    los: 11.94,
    patients: 19,
  },
  {
    los: 15.6,
    patients: 11,
  },
  {
    los: 16.49,
    patients: 11,
  },
  {
    los: 27.62,
    patients: 2,
  },
  {
    los: 15.93,
    patients: 15,
  },
  {
    los: 13.24,
    patients: 9,
  },
  {
    los: 12.66,
    patients: 17,
  },
  {
    los: 18.61,
    patients: 2,
  },
  {
    los: 38.91,
    patients: 3,
  },
  {
    los: 25.87,
    patients: 2,
  },
  {
    los: 9.77,
    patients: 25,
  },
  {
    los: 13.69,
    patients: 23,
  },
  {
    los: 10.54,
    patients: 19,
  },
  {
    los: 31.61,
    patients: 3,
  },
  {
    los: 25.83,
    patients: 4,
  },
  {
    los: 23.55,
    patients: 5,
  },
  {
    los: 6.29,
    patients: 33,
  },
  {
    los: 12.32,
    patients: 3,
  },
  {
    los: 6.21,
    patients: 31,
  },
  {
    los: 8.57,
    patients: 45,
  },
  {
    los: 18.05,
    patients: 3,
  },
  {
    los: 26.75,
    patients: 7,
  },
  {
    los: 10.33,
    patients: 10,
  },
  {
    los: 2.31,
    patients: 74,
  },
  {
    los: 15.07,
    patients: 3,
  },
  {
    los: 16.85,
    patients: 6,
  },
  {
    los: 10.19,
    patients: 14,
  },
  {
    los: 8.8,
    patients: 52,
  },
  {
    los: 12.72,
    patients: 24,
  },
  {
    los: 11.77,
    patients: 16,
  },
  {
    los: 9.06,
    patients: 56,
  },
  {
    los: 14.52,
    patients: 8,
  },
  {
    los: 8.47,
    patients: 22,
  },
  {
    los: 4.14,
    patients: 90,
  },
  {
    los: 10.79,
    patients: 28,
  },
  {
    los: 21.85,
    patients: 7,
  },
  {
    los: 24.97,
    patients: 4,
  },
  {
    los: 14.84,
    patients: 12,
  },
  {
    los: 43.73,
    patients: 2,
  },
  {
    los: 14.71,
    patients: 14,
  },
  {
    los: 16.39,
    patients: 6,
  },
  {
    los: 11.8,
    patients: 22,
  },
  {
    los: 16.88,
    patients: 11,
  },
  {
    los: 23.53,
    patients: 4,
  },
  {
    los: 25.58,
    patients: 2,
  },
  {
    los: 15.32,
    patients: 7,
  },
  {
    los: 8.67,
    patients: 47,
  },
  {
    los: 12.94,
    patients: 18,
  },
  {
    los: 9.7,
    patients: 39,
  },
  {
    los: 11.31,
    patients: 12,
  },
  {
    los: 12.01,
    patients: 14,
  },
  {
    los: 10.11,
    patients: 20,
  },
  {
    los: 14.48,
    patients: 8,
  },
  {
    los: 25.1,
    patients: 1,
  },
  {
    los: 9.42,
    patients: 14,
  },
  {
    los: 27.64,
    patients: 1,
  },
  {
    los: 14.19,
    patients: 7,
  },
  {
    los: 7.29,
    patients: 12,
  },
  {
    los: 10.85,
    patients: 43,
  },
  {
    los: 14.06,
    patients: 8,
  },
  {
    los: 15.31,
    patients: 7,
  },
  {
    los: 6.63,
    patients: 80,
  },
  {
    los: 14.32,
    patients: 6,
  },
  {
    los: 23.03,
    patients: 2,
  },
  {
    los: 23.96,
    patients: 2,
  },
  {
    los: 14.27,
    patients: 3,
  },
  {
    los: 35.84,
    patients: 2,
  },
  {
    los: 9.99,
    patients: 40,
  },
  {
    los: 15.85,
    patients: 10,
  },
  {
    los: 26.47,
    patients: 2,
  },
  {
    los: 9.59,
    patients: 28,
  },
  {
    los: 15.52,
    patients: 5,
  },
  {
    los: 6.46,
    patients: 42,
  },
  {
    los: 5.43,
    patients: 50,
  },
  {
    los: 15.72,
    patients: 16,
  },
  {
    los: 10.99,
    patients: 17,
  },
  {
    los: 20.32,
    patients: 3,
  },
  {
    los: 33.01,
    patients: 2,
  },
  {
    los: 17.94,
    patients: 7,
  },
  {
    los: 33.77,
    patients: 4,
  },
  {
    los: 11.29,
    patients: 10,
  },
  {
    los: 19.96,
    patients: 9,
  },
  {
    los: 13.51,
    patients: 13,
  },
  {
    los: 9.2,
    patients: 18,
  },
  {
    los: 16.6,
    patients: 10,
  },
  {
    los: 25.77,
    patients: 3,
  },
  {
    los: 45.2,
    patients: 1,
  },
  {
    los: 22.75,
    patients: 8,
  },
  {
    los: 16.69,
    patients: 10,
  },
  {
    los: 6.16,
    patients: 40,
  },
  {
    los: 15.83,
    patients: 12,
  },
  {
    los: 7.25,
    patients: 25,
  },
  {
    los: 15.94,
    patients: 11,
  },
  {
    los: 10.93,
    patients: 28,
  },
  {
    los: 12.82,
    patients: 28,
  },
  {
    los: 31.91,
    patients: 1,
  },
  {
    los: 15.35,
    patients: 5,
  },
  {
    los: 20.49,
    patients: 3,
  },
  {
    los: 24.87,
    patients: 5,
  },
  {
    los: 24.21,
    patients: 4,
  },
  {
    los: 13.64,
    patients: 13,
  },
  {
    los: 8.23,
    patients: 17,
  },
  {
    los: 11.11,
    patients: 21,
  },
  {
    los: 9.5,
    patients: 26,
  },
  {
    los: 34.18,
    patients: 1,
  },
  {
    los: 17.71,
    patients: 7,
  },
  {
    los: 9.76,
    patients: 35,
  },
  {
    los: 23.22,
    patients: 1,
  },
  {
    los: 34.96,
    patients: 2,
  },
  {
    los: 8.27,
    patients: 19,
  },
  {
    los: 9.12,
    patients: 24,
  },
  {
    los: 22.26,
    patients: 5,
  },
  {
    los: 20.71,
    patients: 10,
  },
  {
    los: 6.59,
    patients: 53,
  },
  {
    los: 18.65,
    patients: 7,
  },
  {
    los: 12.67,
    patients: 20,
  },
  {
    los: 21.79,
    patients: 3,
  },
  {
    los: 9.04,
    patients: 38,
  },
  {
    los: 17.79,
    patients: 10,
  },
  {
    los: 9.97,
    patients: 30,
  },
  {
    los: 14.29,
    patients: 8,
  },
  {
    los: 16.72,
    patients: 11,
  },
  {
    los: 8.43,
    patients: 27,
  },
  {
    los: 15.15,
    patients: 3,
  },
  {
    los: 19.27,
    patients: 6,
  },
  {
    los: 14.37,
    patients: 6,
  },
  {
    los: 16.18,
    patients: 3,
  },
  {
    los: 9.87,
    patients: 36,
  },
  {
    los: 17.89,
    patients: 9,
  },
  {
    los: 7.4,
    patients: 25,
  },
  {
    los: 39.54,
    patients: 1,
  },
  {
    los: 11.47,
    patients: 14,
  },
  {
    los: 8.11,
    patients: 48,
  },
  {
    los: 4.28,
    patients: 55,
  },
  {
    los: 18.15,
    patients: 3,
  },
  {
    los: 19.77,
    patients: 6,
  },
  {
    los: 18.08,
    patients: 4,
  },
  {
    los: 31.29,
    patients: 1,
  },
  {
    los: 44.35,
    patients: 1,
  },
  {
    los: 11.63,
    patients: 21,
  },
  {
    los: 10.81,
    patients: 30,
  },
  {
    los: 17.84,
    patients: 14,
  },
  {
    los: 20.87,
    patients: 4,
  },
  {
    los: 12.02,
    patients: 12,
  },
  {
    los: 7.28,
    patients: 16,
  },
  {
    los: 9.4,
    patients: 7,
  },
  {
    los: 10.35,
    patients: 10,
  },
  {
    los: 10.27,
    patients: 4,
  },
  {
    los: 8.98,
    patients: 43,
  },
  {
    los: 21.44,
    patients: 5,
  },
  {
    los: 29.58,
    patients: 2,
  },
  {
    los: 8.3,
    patients: 15,
  },
  {
    los: 28.86,
    patients: 3,
  },
  {
    los: 13.96,
    patients: 19,
  },
  {
    los: 14.12,
    patients: 8,
  },
  {
    los: 11.05,
    patients: 20,
  },
  {
    los: 14.02,
    patients: 17,
  },
  {
    los: 23.69,
    patients: 1,
  },
  {
    los: 14.21,
    patients: 7,
  },
  {
    los: 23.57,
    patients: 4,
  },
  {
    los: 10.62,
    patients: 31,
  },
  {
    los: 19.53,
    patients: 7,
  },
  {
    los: 7.26,
    patients: 22,
  },
  {
    los: 49.86,
    patients: 1,
  },
  {
    los: 16.97,
    patients: 11,
  },
  {
    los: 12.52,
    patients: 13,
  },
  {
    los: 22.73,
    patients: 2,
  },
  {
    los: 9.96,
    patients: 41,
  },
  {
    los: 38.32,
    patients: 1,
  },
  {
    los: 15.97,
    patients: 17,
  },
  {
    los: 11.66,
    patients: 23,
  },
  {
    los: 39.66,
    patients: 1,
  },
  {
    los: 16.03,
    patients: 8,
  },
  {
    los: 29.03,
    patients: 2,
  },
  {
    los: 11.69,
    patients: 26,
  },
  {
    los: 7.59,
    patients: 41,
  },
  {
    los: 40.56,
    patients: 1,
  },
  {
    los: 14.36,
    patients: 6,
  },
  {
    los: 48.62,
    patients: 1,
  },
  {
    los: 21.8,
    patients: 7,
  },
  {
    los: 11.82,
    patients: 35,
  },
  {
    los: 17.74,
    patients: 8,
  },
  {
    los: 9.25,
    patients: 15,
  },
  {
    los: 25.56,
    patients: 1,
  },
  {
    los: 15.05,
    patients: 12,
  },
  {
    los: 11.72,
    patients: 22,
  },
  {
    los: 10.51,
    patients: 16,
  },
  {
    los: 11.9,
    patients: 32,
  },
  {
    los: 20.91,
    patients: 4,
  },
  {
    los: 14.99,
    patients: 5,
  },
  {
    los: 12.89,
    patients: 24,
  },
  {
    los: 8.15,
    patients: 33,
  },
  {
    los: 14.68,
    patients: 16,
  },
  {
    los: 5.19,
    patients: 48,
  },
  {
    los: 27.86,
    patients: 1,
  },
  {
    los: 8.88,
    patients: 59,
  },
  {
    los: 5.38,
    patients: 57,
  },
  {
    los: 8.69,
    patients: 54,
  },
  {
    los: 13.49,
    patients: 10,
  },
  {
    los: 26.92,
    patients: 4,
  },
  {
    los: 27.51,
    patients: 1,
  },
  {
    los: 13.82,
    patients: 19,
  },
  {
    los: 11.44,
    patients: 8,
  },
  {
    los: 20.9,
    patients: 6,
  },
  {
    los: 18.82,
    patients: 8,
  },
  {
    los: 12.71,
    patients: 19,
  },
  {
    los: 7.32,
    patients: 15,
  },
  {
    los: 14.69,
    patients: 11,
  },
  {
    los: 36.23,
    patients: 1,
  },
  {
    los: 11.27,
    patients: 12,
  },
  {
    los: 17.19,
    patients: 8,
  },
  {
    los: 7.2,
    patients: 31,
  },
  {
    los: 7.17,
    patients: 30,
  },
  {
    los: 19.71,
    patients: 6,
  },
  {
    los: 22.43,
    patients: 3,
  },
  {
    los: 37.07,
    patients: 3,
  },
  {
    los: 10.46,
    patients: 10,
  },
  {
    los: 7.46,
    patients: 32,
  },
  {
    los: 14.58,
    patients: 14,
  },
  {
    los: 12.97,
    patients: 27,
  },
  {
    los: 9.45,
    patients: 25,
  },
  {
    los: 9.53,
    patients: 29,
  },
  {
    los: 13.65,
    patients: 25,
  },
  {
    los: 21.57,
    patients: 5,
  },
  {
    los: 6.42,
    patients: 36,
  },
  {
    los: 11.49,
    patients: 16,
  },
  {
    los: 6.41,
    patients: 36,
  },
  {
    los: 14.6,
    patients: 11,
  },
  {
    los: 20.62,
    patients: 4,
  },
  {
    los: 10.58,
    patients: 17,
  },
  {
    los: 21.16,
    patients: 6,
  },
  {
    los: 29.02,
    patients: 3,
  },
  {
    los: 17.78,
    patients: 13,
  },
  {
    los: 27.66,
    patients: 5,
  },
  {
    los: 16.74,
    patients: 10,
  },
  {
    los: 11.22,
    patients: 12,
  },
  {
    los: 12.14,
    patients: 9,
  },
  {
    los: 17.54,
    patients: 3,
  },
  {
    los: 47.79,
    patients: 1,
  },
  {
    los: 41.08,
    patients: 1,
  },
  {
    los: 16.87,
    patients: 9,
  },
  {
    los: 39.96,
    patients: 1,
  },
  {
    los: 20.06,
    patients: 5,
  },
  {
    los: 35.59,
    patients: 3,
  },
  {
    los: 27.8,
    patients: 7,
  },
  {
    los: 10.36,
    patients: 6,
  },
  {
    los: 38.49,
    patients: 1,
  },
  {
    los: 31.55,
    patients: 2,
  },
  {
    los: 14.91,
    patients: 22,
  },
  {
    los: 10.07,
    patients: 27,
  },
  {
    los: 11.7,
    patients: 27,
  },
  {
    los: 23.86,
    patients: 3,
  },
  {
    los: 17.62,
    patients: 7,
  },
  {
    los: 7.54,
    patients: 38,
  },
  {
    los: 19.92,
    patients: 7,
  },
  {
    los: 16.21,
    patients: 6,
  },
  {
    los: 27.88,
    patients: 5,
  },
  {
    los: 38.05,
    patients: 1,
  },
  {
    los: 40.95,
    patients: 3,
  },
  {
    los: 18.32,
    patients: 3,
  },
  {
    los: 10.76,
    patients: 27,
  },
  {
    los: 18.88,
    patients: 9,
  },
  {
    los: 16.94,
    patients: 8,
  },
  {
    los: 13.85,
    patients: 22,
  },
  {
    los: 26.68,
    patients: 2,
  },
  {
    los: 14.04,
    patients: 14,
  },
  {
    los: 7.34,
    patients: 28,
  },
  {
    los: 12.21,
    patients: 7,
  },
  {
    los: 41.73,
    patients: 2,
  },
  {
    los: 8.31,
    patients: 23,
  },
  {
    los: 12.53,
    patients: 12,
  },
  {
    los: 45.03,
    patients: 1,
  },
  {
    los: 42.1,
    patients: 2,
  },
  {
    los: 13.18,
    patients: 16,
  },
  {
    los: 14.63,
    patients: 14,
  },
  {
    los: 19.64,
    patients: 8,
  },
  {
    los: 12.92,
    patients: 26,
  },
  {
    los: 10.9,
    patients: 27,
  },
  {
    los: 45.63,
    patients: 1,
  },
  {
    los: 24.3,
    patients: 1,
  },
  {
    los: 18.71,
    patients: 7,
  },
  {
    los: 14.66,
    patients: 12,
  },
  {
    los: 12.64,
    patients: 20,
  },
  {
    los: 12.51,
    patients: 11,
  },
  {
    los: 43.86,
    patients: 2,
  },
  {
    los: 11.59,
    patients: 13,
  },
  {
    los: 26.8,
    patients: 2,
  },
  {
    los: 39.62,
    patients: 1,
  },
  {
    los: 7.08,
    patients: 61,
  },
  {
    los: 8.46,
    patients: 28,
  },
  {
    los: 11.43,
    patients: 9,
  },
  {
    los: 12.75,
    patients: 24,
  },
  {
    los: 16.78,
    patients: 11,
  },
  {
    los: 21.31,
    patients: 1,
  },
  {
    los: 7.22,
    patients: 25,
  },
  {
    los: 11.62,
    patients: 15,
  },
  {
    los: 18.7,
    patients: 9,
  },
  {
    los: 11.13,
    patients: 10,
  },
  {
    los: 20.17,
    patients: 6,
  },
  {
    los: 12.23,
    patients: 8,
  },
  {
    los: 10.14,
    patients: 23,
  },
  {
    los: 11.26,
    patients: 10,
  },
  {
    los: 40.83,
    patients: 1,
  },
  {
    los: 14.98,
    patients: 15,
  },
  {
    los: 8.04,
    patients: 54,
  },
  {
    los: 12.99,
    patients: 19,
  },
  {
    los: 21.94,
    patients: 4,
  },
  {
    los: 12.24,
    patients: 7,
  },
  {
    los: 44.22,
    patients: 1,
  },
  {
    los: 27.73,
    patients: 4,
  },
  {
    los: 18.57,
    patients: 6,
  },
  {
    los: 16.67,
    patients: 7,
  },
  {
    los: 12.61,
    patients: 15,
  },
  {
    los: 33.29,
    patients: 1,
  },
  {
    los: 20.37,
    patients: 3,
  },
  {
    los: 17.44,
    patients: 5,
  },
  {
    los: 30.82,
    patients: 1,
  },
  {
    los: 17.65,
    patients: 8,
  },
  {
    los: 11.61,
    patients: 14,
  },
  {
    los: 37.65,
    patients: 1,
  },
  {
    los: 5.28,
    patients: 36,
  },
  {
    los: 15.78,
    patients: 13,
  },
  {
    los: 24.44,
    patients: 1,
  },
  {
    los: 14.2,
    patients: 5,
  },
  {
    los: 8.76,
    patients: 47,
  },
  {
    los: 18.42,
    patients: 7,
  },
  {
    los: 12.59,
    patients: 19,
  },
  {
    los: 19.13,
    patients: 6,
  },
  {
    los: 12.18,
    patients: 10,
  },
  {
    los: 37.28,
    patients: 2,
  },
  {
    los: 8.34,
    patients: 22,
  },
  {
    los: 14.96,
    patients: 11,
  },
  {
    los: 19.26,
    patients: 7,
  },
  {
    los: 22.14,
    patients: 2,
  },
  {
    los: 12.2,
    patients: 10,
  },
  {
    los: 9.09,
    patients: 27,
  },
  {
    los: 19.79,
    patients: 10,
  },
  {
    los: 46.2,
    patients: 1,
  },
  {
    los: 9.52,
    patients: 29,
  },
  {
    los: 8.4,
    patients: 13,
  },
  {
    los: 16.99,
    patients: 11,
  },
  {
    los: 26.11,
    patients: 1,
  },
  {
    los: 4.29,
    patients: 53,
  },
  {
    los: 15.88,
    patients: 8,
  },
  {
    los: 10.13,
    patients: 15,
  },
  {
    los: 9.75,
    patients: 39,
  },
  {
    los: 16.55,
    patients: 5,
  },
  {
    los: 8.54,
    patients: 35,
  },
  {
    los: 15.73,
    patients: 7,
  },
  {
    los: 33.96,
    patients: 1,
  },
  {
    los: 16.16,
    patients: 7,
  },
  {
    los: 11.08,
    patients: 20,
  },
  {
    los: 31.47,
    patients: 2,
  },
  {
    los: 18.73,
    patients: 12,
  },
  {
    los: 17.56,
    patients: 10,
  },
  {
    los: 15.92,
    patients: 17,
  },
  {
    los: 23.01,
    patients: 4,
  },
  {
    los: 9.34,
    patients: 5,
  },
  {
    los: 14.3,
    patients: 5,
  },
  {
    los: 9.13,
    patients: 32,
  },
  {
    los: 22.56,
    patients: 4,
  },
  {
    los: 9.67,
    patients: 42,
  },
  {
    los: 23.21,
    patients: 1,
  },
  {
    los: 6.56,
    patients: 46,
  },
  {
    los: 21.93,
    patients: 7,
  },
  {
    los: 23.3,
    patients: 1,
  },
  {
    los: 19.05,
    patients: 6,
  },
  {
    los: 26.77,
    patients: 7,
  },
  {
    los: 17.97,
    patients: 7,
  },
  {
    los: 18.72,
    patients: 6,
  },
  {
    los: 10.3,
    patients: 15,
  },
  {
    los: 25.66,
    patients: 4,
  },
  {
    los: 12.68,
    patients: 22,
  },
  {
    los: 25.74,
    patients: 1,
  },
  {
    los: 12.77,
    patients: 20,
  },
  {
    los: 30.81,
    patients: 2,
  },
  {
    los: 9.28,
    patients: 15,
  },
  {
    los: 26.37,
    patients: 2,
  },
  {
    los: 47.36,
    patients: 1,
  },
  {
    los: 15.08,
    patients: 11,
  },
  {
    los: 21.88,
    patients: 8,
  },
  {
    los: 16.15,
    patients: 5,
  },
  {
    los: 28.41,
    patients: 4,
  },
  {
    los: 13.23,
    patients: 10,
  },
  {
    los: 14.45,
    patients: 10,
  },
  {
    los: 41.5,
    patients: 2,
  },
  {
    los: 24.85,
    patients: 3,
  },
  {
    los: 16.01,
    patients: 7,
  },
  {
    los: 22.99,
    patients: 3,
  },
  {
    los: 10.94,
    patients: 29,
  },
  {
    los: 11.25,
    patients: 7,
  },
  {
    los: 16.38,
    patients: 4,
  },
  {
    los: 24.51,
    patients: 2,
  },
  {
    los: 26.64,
    patients: 4,
  },
  {
    los: 17.03,
    patients: 8,
  },
  {
    los: 21.65,
    patients: 8,
  },
  {
    los: 32.06,
    patients: 2,
  },
  {
    los: 13.8,
    patients: 18,
  },
  {
    los: 19.34,
    patients: 2,
  },
  {
    los: 37.94,
    patients: 1,
  },
  {
    los: 22.45,
    patients: 2,
  },
  {
    los: 42.91,
    patients: 1,
  },
  {
    los: 13.99,
    patients: 12,
  },
  {
    los: 10.53,
    patients: 19,
  },
  {
    los: 30.03,
    patients: 2,
  },
  {
    los: 33.66,
    patients: 1,
  },
  {
    los: 24.8,
    patients: 4,
  },
  {
    los: 9.69,
    patients: 34,
  },
  {
    los: 47.67,
    patients: 1,
  },
  {
    los: 20.61,
    patients: 8,
  },
  {
    los: 11.1,
    patients: 9,
  },
  {
    los: 30.17,
    patients: 1,
  },
  {
    los: 21.17,
    patients: 2,
  },
  {
    los: 19.6,
    patients: 7,
  },
  {
    los: 22.13,
    patients: 4,
  },
  {
    los: 9.22,
    patients: 14,
  },
  {
    los: 17.37,
    patients: 4,
  },
  {
    los: 46.54,
    patients: 1,
  },
  {
    los: 18.24,
    patients: 3,
  },
  {
    los: 49.71,
    patients: 1,
  },
  {
    los: 27.75,
    patients: 1,
  },
  {
    los: 22.5,
    patients: 2,
  },
  {
    los: 11.39,
    patients: 10,
  },
  {
    los: 15.62,
    patients: 7,
  },
  {
    los: 20.58,
    patients: 3,
  },
  {
    los: 9.44,
    patients: 12,
  },
  {
    los: 11.67,
    patients: 27,
  },
  {
    los: 14.44,
    patients: 4,
  },
  {
    los: 23.49,
    patients: 5,
  },
  {
    los: 16.11,
    patients: 3,
  },
  {
    los: 22.95,
    patients: 4,
  },
  {
    los: 20.88,
    patients: 7,
  },
  {
    los: 10.1,
    patients: 24,
  },
  {
    los: 6.3,
    patients: 34,
  },
  {
    los: 17.5,
    patients: 6,
  },
  {
    los: 23.75,
    patients: 4,
  },
  {
    los: 30.97,
    patients: 2,
  },
  {
    los: 43.91,
    patients: 5,
  },
  {
    los: 27.93,
    patients: 4,
  },
  {
    los: 20.75,
    patients: 6,
  },
  {
    los: 39.9,
    patients: 1,
  },
  {
    los: 15.12,
    patients: 10,
  },
  {
    los: 23.65,
    patients: 2,
  },
  {
    los: 23.45,
    patients: 1,
  },
  {
    los: 16.51,
    patients: 10,
  },
  {
    los: 12.3,
    patients: 8,
  },
  {
    los: 12.49,
    patients: 13,
  },
  {
    los: 14.16,
    patients: 11,
  },
  {
    los: 27.54,
    patients: 1,
  },
  {
    los: 9.72,
    patients: 47,
  },
  {
    los: 32.57,
    patients: 2,
  },
  {
    los: 13.57,
    patients: 11,
  },
  {
    los: 16.76,
    patients: 9,
  },
  {
    los: 14.76,
    patients: 17,
  },
  {
    los: 18.93,
    patients: 6,
  },
  {
    los: 27.07,
    patients: 1,
  },
  {
    los: 12.07,
    patients: 20,
  },
  {
    los: 24.99,
    patients: 3,
  },
  {
    los: 20.96,
    patients: 4,
  },
  {
    los: 8.52,
    patients: 33,
  },
  {
    los: 46.85,
    patients: 1,
  },
  {
    los: 14.86,
    patients: 11,
  },
  {
    los: 6.26,
    patients: 29,
  },
  {
    los: 9.37,
    patients: 20,
  },
  {
    los: 35.64,
    patients: 3,
  },
  {
    los: 19.54,
    patients: 4,
  },
  {
    los: 43.85,
    patients: 1,
  },
  {
    los: 22.77,
    patients: 4,
  },
  {
    los: 26.85,
    patients: 4,
  },
  {
    los: 23.6,
    patients: 3,
  },
  {
    los: 13.61,
    patients: 13,
  },
  {
    los: 22.02,
    patients: 4,
  },
  {
    los: 19.97,
    patients: 9,
  },
  {
    los: 14.34,
    patients: 11,
  },
  {
    los: 16.04,
    patients: 8,
  },
  {
    los: 33.54,
    patients: 4,
  },
  {
    los: 12.69,
    patients: 29,
  },
  {
    los: 17.1,
    patients: 5,
  },
  {
    los: 17.61,
    patients: 5,
  },
  {
    los: 30.42,
    patients: 1,
  },
  {
    los: 26.53,
    patients: 2,
  },
  {
    los: 15.89,
    patients: 7,
  },
  {
    los: 10.28,
    patients: 5,
  },
  {
    los: 13.41,
    patients: 7,
  },
  {
    los: 27.26,
    patients: 1,
  },
  {
    los: 38.04,
    patients: 1,
  },
  {
    los: 18.79,
    patients: 11,
  },
  {
    los: 18.25,
    patients: 2,
  },
  {
    los: 42.9,
    patients: 1,
  },
  {
    los: 14.85,
    patients: 14,
  },
  {
    los: 14.81,
    patients: 14,
  },
  {
    los: 20.03,
    patients: 9,
  },
  {
    los: 21.51,
    patients: 1,
  },
  {
    los: 13.78,
    patients: 20,
  },
  {
    los: 22.81,
    patients: 5,
  },
  {
    los: 43.75,
    patients: 1,
  },
  {
    los: 16.09,
    patients: 7,
  },
  {
    los: 13.16,
    patients: 9,
  },
  {
    los: 15.47,
    patients: 8,
  },
  {
    los: 15.25,
    patients: 5,
  },
  {
    los: 18.75,
    patients: 10,
  },
  {
    los: 11.32,
    patients: 8,
  },
  {
    los: 16.54,
    patients: 5,
  },
  {
    los: 21.78,
    patients: 6,
  },
  {
    los: 13.59,
    patients: 10,
  },
  {
    los: 27.09,
    patients: 3,
  },
  {
    los: 21.35,
    patients: 2,
  },
  {
    los: 32.7,
    patients: 2,
  },
  {
    los: 14.07,
    patients: 12,
  },
  {
    los: 17.63,
    patients: 10,
  },
  {
    los: 14.61,
    patients: 8,
  },
  {
    los: 3.25,
    patients: 88,
  },
  {
    los: 25.61,
    patients: 3,
  },
  {
    los: 20.52,
    patients: 3,
  },
  {
    los: 21.89,
    patients: 3,
  },
  {
    los: 13.58,
    patients: 9,
  },
  {
    los: 21.6,
    patients: 3,
  },
  {
    los: 22.42,
    patients: 3,
  },
  {
    los: 15.9,
    patients: 14,
  },
  {
    los: 23.9,
    patients: 6,
  },
  {
    los: 29.17,
    patients: 2,
  },
  {
    los: 31.71,
    patients: 3,
  },
  {
    los: 16.41,
    patients: 5,
  },
  {
    los: 9.03,
    patients: 42,
  },
  {
    los: 32.76,
    patients: 2,
  },
  {
    los: 21.53,
    patients: 2,
  },
  {
    los: 9.07,
    patients: 39,
  },
  {
    los: 35.8,
    patients: 2,
  },
  {
    los: 14.26,
    patients: 5,
  },
  {
    los: 17.05,
    patients: 10,
  },
  {
    los: 27.65,
    patients: 2,
  },
  {
    los: 16.28,
    patients: 3,
  },
  {
    los: 14.8,
    patients: 18,
  },
  {
    los: 16.36,
    patients: 3,
  },
  {
    los: 27.7,
    patients: 2,
  },
  {
    los: 45.74,
    patients: 2,
  },
  {
    los: 29.26,
    patients: 3,
  },
  {
    los: 22.17,
    patients: 1,
  },
  {
    los: 11.15,
    patients: 13,
  },
  {
    los: 10.63,
    patients: 31,
  },
  {
    los: 34.87,
    patients: 2,
  },
  {
    los: 24.22,
    patients: 3,
  },
  {
    los: 13.94,
    patients: 14,
  },
  {
    los: 18.34,
    patients: 1,
  },
  {
    los: 22.54,
    patients: 6,
  },
  {
    los: 10.89,
    patients: 27,
  },
  {
    los: 12.96,
    patients: 15,
  },
  {
    los: 26.9,
    patients: 3,
  },
  {
    los: 28.9,
    patients: 3,
  },
  {
    los: 17.81,
    patients: 9,
  },
  {
    los: 14.92,
    patients: 15,
  },
  {
    los: 16.1,
    patients: 4,
  },
  {
    los: 45.15,
    patients: 1,
  },
  {
    los: 11.53,
    patients: 25,
  },
  {
    los: 25.57,
    patients: 1,
  },
  {
    los: 12.31,
    patients: 7,
  },
  {
    los: 17.77,
    patients: 9,
  },
  {
    los: 19.88,
    patients: 8,
  },
  {
    los: 11.37,
    patients: 5,
  },
  {
    los: 20.05,
    patients: 5,
  },
  {
    los: 23.99,
    patients: 3,
  },
  {
    los: 11.57,
    patients: 11,
  },
  {
    los: 18.84,
    patients: 7,
  },
  {
    los: 12.98,
    patients: 14,
  },
  {
    los: 15.11,
    patients: 5,
  },
  {
    los: 36.77,
    patients: 3,
  },
  {
    los: 21.13,
    patients: 3,
  },
  {
    los: 13.93,
    patients: 23,
  },
  {
    los: 41.09,
    patients: 2,
  },
  {
    los: 11.64,
    patients: 15,
  },
  {
    los: 8.29,
    patients: 17,
  },
  {
    los: 21.74,
    patients: 6,
  },
  {
    los: 20.42,
    patients: 3,
  },
  {
    los: 12.93,
    patients: 26,
  },
  {
    los: 14.23,
    patients: 5,
  },
  {
    los: 12.08,
    patients: 22,
  },
  {
    los: 15.21,
    patients: 5,
  },
  {
    los: 19.73,
    patients: 5,
  },
  {
    los: 16.07,
    patients: 9,
  },
  {
    los: 42.88,
    patients: 2,
  },
  {
    los: 13.53,
    patients: 13,
  },
  {
    los: 15.75,
    patients: 10,
  },
  {
    los: 25.7,
    patients: 4,
  },
  {
    los: 25.39,
    patients: 5,
  },
  {
    los: 24.9,
    patients: 4,
  },
  {
    los: 32.66,
    patients: 1,
  },
  {
    los: 30.16,
    patients: 1,
  },
  {
    los: 14.14,
    patients: 6,
  },
  {
    los: 27.59,
    patients: 6,
  },
  {
    los: 20.53,
    patients: 3,
  },
  {
    los: 30.74,
    patients: 3,
  },
  {
    los: 20.97,
    patients: 6,
  },
  {
    los: 33.22,
    patients: 1,
  },
  {
    los: 10.96,
    patients: 24,
  },
  {
    los: 18.64,
    patients: 6,
  },
  {
    los: 36.78,
    patients: 2,
  },
  {
    los: 13.98,
    patients: 13,
  },
  {
    los: 8.6,
    patients: 45,
  },
  {
    los: 13.2,
    patients: 3,
  },
  {
    los: 13.68,
    patients: 15,
  },
  {
    los: 27.08,
    patients: 2,
  },
  {
    los: 35.48,
    patients: 1,
  },
  {
    los: 23.2,
    patients: 4,
  },
  {
    los: 14.78,
    patients: 11,
  },
  {
    los: 17.13,
    patients: 5,
  },
  {
    los: 17.83,
    patients: 6,
  },
  {
    los: 13.55,
    patients: 16,
  },
  {
    los: 16.7,
    patients: 10,
  },
  {
    los: 15.64,
    patients: 9,
  },
  {
    los: 16.27,
    patients: 4,
  },
  {
    los: 22.47,
    patients: 2,
  },
  {
    los: 39.21,
    patients: 1,
  },
  {
    los: 23.97,
    patients: 1,
  },
  {
    los: 26.94,
    patients: 2,
  },
  {
    los: 18.37,
    patients: 2,
  },
  {
    los: 48.9,
    patients: 1,
  },
  {
    los: 21.96,
    patients: 1,
  },
  {
    los: 22.94,
    patients: 2,
  },
  {
    los: 30.76,
    patients: 2,
  },
  {
    los: 20.69,
    patients: 4,
  },
  {
    los: 22.53,
    patients: 2,
  },
  {
    los: 16.84,
    patients: 7,
  },
  {
    los: 15.96,
    patients: 13,
  },
  {
    los: 16.57,
    patients: 6,
  },
  {
    los: 23.94,
    patients: 1,
  },
  {
    los: 13.73,
    patients: 15,
  },
  {
    los: 25.68,
    patients: 4,
  },
  {
    los: 19.83,
    patients: 5,
  },
  {
    los: 14.9,
    patients: 8,
  },
  {
    los: 40.57,
    patients: 1,
  },
  {
    los: 26.06,
    patients: 4,
  },
  {
    los: 9.54,
    patients: 19,
  },
  {
    los: 17.17,
    patients: 3,
  },
  {
    los: 33.04,
    patients: 1,
  },
  {
    los: 14.22,
    patients: 5,
  },
  {
    los: 24.57,
    patients: 3,
  },
  {
    los: 33.9,
    patients: 3,
  },
  {
    los: 17.53,
    patients: 6,
  },
  {
    los: 30.77,
    patients: 2,
  },
  {
    los: 13.39,
    patients: 7,
  },
  {
    los: 26.71,
    patients: 2,
  },
  {
    los: 49.12,
    patients: 1,
  },
  {
    los: 18.76,
    patients: 9,
  },
  {
    los: 25.73,
    patients: 1,
  },
  {
    los: 12.45,
    patients: 10,
  },
  {
    los: 29.87,
    patients: 2,
  },
  {
    los: 16.95,
    patients: 6,
  },
  {
    los: 27.63,
    patients: 4,
  },
  {
    los: 19.25,
    patients: 6,
  },
  {
    los: 19.52,
    patients: 4,
  },
  {
    los: 31.42,
    patients: 2,
  },
  {
    los: 28.11,
    patients: 2,
  },
  {
    los: 19.9,
    patients: 4,
  },
  {
    los: 24.01,
    patients: 5,
  },
  {
    los: 35.46,
    patients: 1,
  },
  {
    los: 13.38,
    patients: 6,
  },
  {
    los: 24.7,
    patients: 7,
  },
  {
    los: 10.56,
    patients: 20,
  },
  {
    los: 40.96,
    patients: 2,
  },
  {
    los: 33.97,
    patients: 3,
  },
  {
    los: 15.79,
    patients: 9,
  },
  {
    los: 14.97,
    patients: 11,
  },
  {
    los: 15.53,
    patients: 7,
  },
  {
    los: 12.44,
    patients: 7,
  },
  {
    los: 24.43,
    patients: 3,
  },
  {
    los: 17.18,
    patients: 4,
  },
  {
    los: 15.66,
    patients: 9,
  },
  {
    los: 12.13,
    patients: 12,
  },
  {
    los: 18.55,
    patients: 4,
  },
  {
    los: 18.74,
    patients: 6,
  },
  {
    los: 16.32,
    patients: 2,
  },
  {
    los: 21.98,
    patients: 5,
  },
  {
    los: 22.82,
    patients: 7,
  },
  {
    los: 15.67,
    patients: 9,
  },
  {
    los: 31.73,
    patients: 3,
  },
  {
    los: 27.49,
    patients: 1,
  },
  {
    los: 27.5,
    patients: 3,
  },
  {
    los: 14.38,
    patients: 3,
  },
  {
    los: 25.82,
    patients: 6,
  },
  {
    los: 17.38,
    patients: 5,
  },
  {
    los: 10.42,
    patients: 16,
  },
  {
    los: 21.86,
    patients: 3,
  },
  {
    los: 39.88,
    patients: 2,
  },
  {
    los: 25.6,
    patients: 3,
  },
  {
    los: 9.17,
    patients: 14,
  },
  {
    los: 29.22,
    patients: 2,
  },
  {
    los: 41.92,
    patients: 1,
  },
  {
    los: 18.66,
    patients: 4,
  },
  {
    los: 14.11,
    patients: 5,
  },
  {
    los: 31.05,
    patients: 1,
  },
  {
    los: 16.79,
    patients: 10,
  },
  {
    los: 15.57,
    patients: 7,
  },
  {
    los: 40.7,
    patients: 1,
  },
  {
    los: 28.1,
    patients: 1,
  },
  {
    los: 12.43,
    patients: 7,
  },
  {
    los: 21.83,
    patients: 3,
  },
  {
    los: 19.82,
    patients: 9,
  },
  {
    los: 15.65,
    patients: 11,
  },
  {
    los: 23.25,
    patients: 1,
  },
  {
    los: 41.15,
    patients: 1,
  },
  {
    los: 11.16,
    patients: 13,
  },
  {
    los: 25.52,
    patients: 4,
  },
  {
    los: 19.02,
    patients: 6,
  },
  {
    los: 28.48,
    patients: 1,
  },
  {
    los: 18.91,
    patients: 6,
  },
  {
    los: 17.76,
    patients: 7,
  },
  {
    los: 11.99,
    patients: 23,
  },
  {
    los: 23.81,
    patients: 4,
  },
  {
    los: 22.8,
    patients: 6,
  },
  {
    los: 39.94,
    patients: 1,
  },
  {
    los: 14.95,
    patients: 12,
  },
  {
    los: 30.63,
    patients: 2,
  },
  {
    los: 20.73,
    patients: 6,
  },
  {
    los: 18.02,
    patients: 5,
  },
  {
    los: 16.19,
    patients: 7,
  },
  {
    los: 41.78,
    patients: 2,
  },
  {
    los: 15.27,
    patients: 6,
  },
  {
    los: 18.8,
    patients: 4,
  },
  {
    los: 34.8,
    patients: 1,
  },
  {
    los: 16.91,
    patients: 11,
  },
  {
    los: 24.79,
    patients: 4,
  },
  {
    los: 15.45,
    patients: 4,
  },
  {
    los: 22.34,
    patients: 2,
  },
  {
    los: 13.21,
    patients: 5,
  },
  {
    los: 19.17,
    patients: 4,
  },
  {
    los: 34.88,
    patients: 2,
  },
  {
    los: 25.12,
    patients: 2,
  },
  {
    los: 18.86,
    patients: 7,
  },
  {
    los: 14.47,
    patients: 8,
  },
  {
    los: 21.2,
    patients: 4,
  },
  {
    los: 23.44,
    patients: 4,
  },
  {
    los: 42.36,
    patients: 2,
  },
  {
    los: 14.67,
    patients: 7,
  },
  {
    los: 19.76,
    patients: 5,
  },
  {
    los: 10.29,
    patients: 8,
  },
  {
    los: 12.15,
    patients: 9,
  },
  {
    los: 14.54,
    patients: 4,
  },
  {
    los: 49.78,
    patients: 1,
  },
  {
    los: 29.79,
    patients: 4,
  },
  {
    los: 33.08,
    patients: 3,
  },
  {
    los: 15.99,
    patients: 11,
  },
  {
    los: 15.39,
    patients: 4,
  },
  {
    los: 25.49,
    patients: 2,
  },
  {
    los: 14.94,
    patients: 15,
  },
  {
    los: 26.69,
    patients: 3,
  },
  {
    los: 22.97,
    patients: 5,
  },
  {
    los: 16.56,
    patients: 8,
  },
  {
    los: 29.88,
    patients: 1,
  },
  {
    los: 27.41,
    patients: 2,
  },
  {
    los: 21.46,
    patients: 3,
  },
  {
    los: 21.97,
    patients: 7,
  },
  {
    los: 16.06,
    patients: 5,
  },
  {
    los: 20.02,
    patients: 2,
  },
  {
    los: 15.06,
    patients: 5,
  },
  {
    los: 14.15,
    patients: 7,
  },
  {
    los: 20.55,
    patients: 4,
  },
  {
    los: 21.77,
    patients: 6,
  },
  {
    los: 19.04,
    patients: 5,
  },
  {
    los: 16.59,
    patients: 9,
  },
  {
    los: 29.29,
    patients: 1,
  },
  {
    los: 16.05,
    patients: 8,
  },
  {
    los: 16.3,
    patients: 1,
  },
  {
    los: 22.62,
    patients: 3,
  },
  {
    los: 14.55,
    patients: 10,
  },
  {
    los: 44.28,
    patients: 1,
  },
  {
    los: 37.86,
    patients: 1,
  },
  {
    los: 18.06,
    patients: 8,
  },
  {
    los: 11.78,
    patients: 28,
  },
  {
    los: 21.68,
    patients: 6,
  },
  {
    los: 11.56,
    patients: 16,
  },
  {
    los: 27.19,
    patients: 1,
  },
  {
    los: 18.68,
    patients: 7,
  },
  {
    los: 33.91,
    patients: 1,
  },
  {
    los: 17.9,
    patients: 11,
  },
  {
    los: 13.31,
    patients: 5,
  },
  {
    los: 20.38,
    patients: 1,
  },
  {
    los: 16.66,
    patients: 6,
  },
  {
    los: 22.92,
    patients: 2,
  },
  {
    los: 38.2,
    patients: 1,
  },
  {
    los: 33.43,
    patients: 2,
  },
  {
    los: 20.72,
    patients: 5,
  },
  {
    los: 32.73,
    patients: 1,
  },
  {
    los: 32.68,
    patients: 2,
  },
  {
    los: 14.57,
    patients: 14,
  },
  {
    los: 26.07,
    patients: 1,
  },
  {
    los: 41.89,
    patients: 2,
  },
  {
    los: 13.6,
    patients: 15,
  },
  {
    los: 17.21,
    patients: 4,
  },
  {
    los: 16.25,
    patients: 2,
  },
  {
    los: 44.05,
    patients: 1,
  },
  {
    los: 19.51,
    patients: 3,
  },
  {
    los: 32.85,
    patients: 3,
  },
  {
    los: 39.93,
    patients: 1,
  },
  {
    los: 21.11,
    patients: 5,
  },
  {
    los: 14.64,
    patients: 12,
  },
  {
    los: 17.43,
    patients: 3,
  },
  {
    los: 10.24,
    patients: 16,
  },
  {
    los: 32.01,
    patients: 1,
  },
  {
    los: 31.93,
    patients: 1,
  },
  {
    los: 24.14,
    patients: 2,
  },
  {
    los: 22.39,
    patients: 5,
  },
  {
    los: 42.69,
    patients: 1,
  },
  {
    los: 17.29,
    patients: 7,
  },
  {
    los: 19.93,
    patients: 9,
  },
  {
    los: 21.73,
    patients: 8,
  },
  {
    los: 22.74,
    patients: 5,
  },
  {
    los: 15.55,
    patients: 8,
  },
  {
    los: 16.75,
    patients: 15,
  },
  {
    los: 32.71,
    patients: 2,
  },
  {
    los: 24.53,
    patients: 2,
  },
  {
    los: 10.15,
    patients: 16,
  },
  {
    los: 19.7,
    patients: 8,
  },
  {
    los: 47.54,
    patients: 1,
  },
  {
    los: 17.64,
    patients: 7,
  },
  {
    los: 17.16,
    patients: 4,
  },
  {
    los: 16.17,
    patients: 3,
  },
  {
    los: 26.83,
    patients: 4,
  },
  {
    los: 18.01,
    patients: 6,
  },
  {
    los: 16.12,
    patients: 4,
  },
  {
    los: 31.65,
    patients: 1,
  },
  {
    los: 23.61,
    patients: 6,
  },
  {
    los: 24.59,
    patients: 4,
  },
  {
    los: 25.79,
    patients: 2,
  },
  {
    los: 31.04,
    patients: 3,
  },
  {
    los: 32.44,
    patients: 1,
  },
  {
    los: 21.92,
    patients: 3,
  },
  {
    los: 15.68,
    patients: 14,
  },
  {
    los: 18.48,
    patients: 1,
  },
  {
    los: 21.56,
    patients: 1,
  },
  {
    los: 9.16,
    patients: 14,
  },
  {
    los: 23.92,
    patients: 3,
  },
  {
    los: 27.17,
    patients: 2,
  },
  {
    los: 15.43,
    patients: 7,
  },
  {
    los: 9.3,
    patients: 8,
  },
  {
    los: 13.02,
    patients: 11,
  },
  {
    los: 23.18,
    patients: 2,
  },
  {
    los: 23.76,
    patients: 2,
  },
  {
    los: 26.89,
    patients: 1,
  },
  {
    los: 19.63,
    patients: 5,
  },
  {
    los: 21.1,
    patients: 1,
  },
  {
    los: 31.7,
    patients: 1,
  },
  {
    los: 25.76,
    patients: 3,
  },
  {
    los: 34.03,
    patients: 2,
  },
  {
    los: 12.7,
    patients: 21,
  },
  {
    los: 26.93,
    patients: 3,
  },
  {
    los: 13.43,
    patients: 4,
  },
  {
    los: 12.35,
    patients: 2,
  },
  {
    los: 43.21,
    patients: 2,
  },
  {
    los: 29.16,
    patients: 2,
  },
  {
    los: 36.72,
    patients: 1,
  },
  {
    los: 13.14,
    patients: 6,
  },
  {
    los: 17.51,
    patients: 3,
  },
  {
    los: 22.78,
    patients: 5,
  },
  {
    los: 33.82,
    patients: 2,
  },
  {
    los: 43.52,
    patients: 1,
  },
  {
    los: 15.14,
    patients: 7,
  },
  {
    los: 21.52,
    patients: 2,
  },
  {
    los: 9.23,
    patients: 11,
  },
  {
    los: 41.47,
    patients: 1,
  },
  {
    los: 35.81,
    patients: 1,
  },
  {
    los: 28.83,
    patients: 2,
  },
  {
    los: 42.55,
    patients: 2,
  },
  {
    los: 30.62,
    patients: 3,
  },
  {
    los: 15.03,
    patients: 13,
  },
  {
    los: 15.13,
    patients: 8,
  },
  {
    los: 36.35,
    patients: 1,
  },
  {
    los: 23.78,
    patients: 3,
  },
  {
    los: 28.12,
    patients: 4,
  },
  {
    los: 15.59,
    patients: 5,
  },
  {
    los: 16.37,
    patients: 4,
  },
  {
    los: 10.5,
    patients: 16,
  },
  {
    los: 17.58,
    patients: 5,
  },
  {
    los: 35.43,
    patients: 1,
  },
  {
    los: 10.25,
    patients: 9,
  },
  {
    los: 12.38,
    patients: 11,
  },
  {
    los: 13.13,
    patients: 3,
  },
  {
    los: 31.79,
    patients: 1,
  },
  {
    los: 32.99,
    patients: 1,
  },
  {
    los: 15.87,
    patients: 9,
  },
  {
    los: 28.63,
    patients: 3,
  },
  {
    los: 18.63,
    patients: 5,
  },
  {
    los: 18.26,
    patients: 6,
  },
  {
    los: 13.42,
    patients: 6,
  },
  {
    los: 22.88,
    patients: 6,
  },
  {
    los: 23.74,
    patients: 2,
  },
  {
    los: 11.5,
    patients: 11,
  },
  {
    los: 28.53,
    patients: 1,
  },
  {
    los: 12.6,
    patients: 20,
  },
  {
    los: 17.04,
    patients: 6,
  },
  {
    los: 16.08,
    patients: 3,
  },
  {
    los: 18.81,
    patients: 7,
  },
  {
    los: 16.68,
    patients: 7,
  },
  {
    los: 19.1,
    patients: 6,
  },
  {
    los: 10.18,
    patients: 13,
  },
  {
    los: 16.47,
    patients: 4,
  },
  {
    los: 44.04,
    patients: 1,
  },
  {
    los: 35.6,
    patients: 2,
  },
  {
    los: 11.28,
    patients: 5,
  },
  {
    los: 31.51,
    patients: 2,
  },
  {
    los: 17.28,
    patients: 2,
  },
  {
    los: 47.88,
    patients: 1,
  },
  {
    los: 31.45,
    patients: 2,
  },
  {
    los: 17.95,
    patients: 8,
  },
  {
    los: 23.85,
    patients: 3,
  },
  {
    los: 43.59,
    patients: 2,
  },
  {
    los: 30.13,
    patients: 1,
  },
  {
    los: 14.77,
    patients: 18,
  },
  {
    los: 40.1,
    patients: 1,
  },
  {
    los: 13.04,
    patients: 16,
  },
  {
    los: 38.12,
    patients: 1,
  },
  {
    los: 32.54,
    patients: 1,
  },
  {
    los: 13.35,
    patients: 6,
  },
  {
    los: 15.84,
    patients: 12,
  },
  {
    los: 17.55,
    patients: 6,
  },
  {
    los: 17.7,
    patients: 5,
  },
  {
    los: 34.85,
    patients: 2,
  },
  {
    los: 23.02,
    patients: 7,
  },
  {
    los: 23.72,
    patients: 10,
  },
  {
    los: 38.28,
    patients: 1,
  },
  {
    los: 15.61,
    patients: 5,
  },
  {
    los: 31.15,
    patients: 1,
  },
  {
    los: 25.99,
    patients: 3,
  },
  {
    los: 24.82,
    patients: 2,
  },
  {
    los: 17.87,
    patients: 9,
  },
  {
    los: 24.27,
    patients: 5,
  },
  {
    los: 33.86,
    patients: 3,
  },
  {
    los: 33.27,
    patients: 1,
  },
  {
    los: 37.39,
    patients: 2,
  },
  {
    los: 24.34,
    patients: 1,
  },
  {
    los: 26.98,
    patients: 2,
  },
  {
    los: 16.93,
    patients: 12,
  },
  {
    los: 13.87,
    patients: 15,
  },
  {
    los: 40.87,
    patients: 1,
  },
  {
    los: 30.95,
    patients: 4,
  },
  {
    los: 15.09,
    patients: 9,
  },
  {
    los: 23.42,
    patients: 2,
  },
  {
    los: 34.05,
    patients: 2,
  },
  {
    los: 20.48,
    patients: 3,
  },
  {
    los: 39.68,
    patients: 1,
  },
  {
    los: 49.9,
    patients: 1,
  },
  {
    los: 43.87,
    patients: 2,
  },
  {
    los: 13.46,
    patients: 4,
  },
  {
    los: 33.68,
    patients: 1,
  },
  {
    los: 32.64,
    patients: 2,
  },
  {
    los: 24.61,
    patients: 2,
  },
  {
    los: 18.45,
    patients: 3,
  },
  {
    los: 20.81,
    patients: 3,
  },
  {
    los: 27.22,
    patients: 3,
  },
  {
    los: 25.78,
    patients: 3,
  },
  {
    los: 20.68,
    patients: 6,
  },
  {
    los: 20.08,
    patients: 3,
  },
  {
    los: 21.02,
    patients: 2,
  },
  {
    los: 33.76,
    patients: 2,
  },
  {
    los: 14.59,
    patients: 7,
  },
  {
    los: 30.65,
    patients: 2,
  },
  {
    los: 21.95,
    patients: 4,
  },
  {
    los: 20.79,
    patients: 5,
  },
  {
    los: 34.06,
    patients: 3,
  },
  {
    los: 15.58,
    patients: 7,
  },
  {
    los: 19.65,
    patients: 5,
  },
  {
    los: 11.33,
    patients: 7,
  },
  {
    los: 13.19,
    patients: 6,
  },
  {
    los: 23.87,
    patients: 3,
  },
  {
    los: 31.16,
    patients: 4,
  },
  {
    los: 15.48,
    patients: 5,
  },
  {
    los: 27.52,
    patients: 3,
  },
  {
    los: 30.87,
    patients: 1,
  },
  {
    los: 13.09,
    patients: 11,
  },
  {
    los: 27.46,
    patients: 4,
  },
  {
    los: 9.35,
    patients: 6,
  },
  {
    los: 19.74,
    patients: 6,
  },
  {
    los: 17.33,
    patients: 2,
  },
  {
    los: 19.38,
    patients: 2,
  },
  {
    los: 18.95,
    patients: 6,
  },
  {
    los: 35.88,
    patients: 1,
  },
  {
    los: 19.81,
    patients: 6,
  },
  {
    los: 13.25,
    patients: 3,
  },
  {
    los: 15.4,
    patients: 6,
  },
  {
    los: 36.26,
    patients: 1,
  },
  {
    los: 30.6,
    patients: 2,
  },
  {
    los: 22.66,
    patients: 6,
  },
  {
    los: 19.86,
    patients: 7,
  },
  {
    los: 18.3,
    patients: 2,
  },
  {
    los: 25.46,
    patients: 1,
  },
  {
    los: 43.99,
    patients: 1,
  },
  {
    los: 20.78,
    patients: 6,
  },
  {
    los: 11.19,
    patients: 9,
  },
  {
    los: 22.51,
    patients: 2,
  },
  {
    los: 13.54,
    patients: 14,
  },
  {
    los: 42.18,
    patients: 1,
  },
  {
    los: 24.96,
    patients: 1,
  },
  {
    los: 35.9,
    patients: 1,
  },
  {
    los: 40.85,
    patients: 1,
  },
  {
    los: 25.93,
    patients: 4,
  },
  {
    los: 27.27,
    patients: 1,
  },
  {
    los: 21.38,
    patients: 3,
  },
  {
    los: 22.89,
    patients: 4,
  },
  {
    los: 44.78,
    patients: 1,
  },
  {
    los: 38.71,
    patients: 1,
  },
  {
    los: 21.7,
    patients: 8,
  },
  {
    los: 24.54,
    patients: 2,
  },
  {
    los: 29.53,
    patients: 1,
  },
  {
    los: 28.89,
    patients: 1,
  },
  {
    los: 27.99,
    patients: 3,
  },
  {
    los: 16.63,
    patients: 6,
  },
  {
    los: 19.99,
    patients: 6,
  },
  {
    los: 9.48,
    patients: 19,
  },
  {
    los: 36.81,
    patients: 4,
  },
  {
    los: 17.98,
    patients: 6,
  },
  {
    los: 17.59,
    patients: 6,
  },
  {
    los: 14.56,
    patients: 14,
  },
  {
    los: 23.89,
    patients: 3,
  },
  {
    los: 15.77,
    patients: 9,
  },
  {
    los: 10.39,
    patients: 13,
  },
  {
    los: 15.71,
    patients: 7,
  },
  {
    los: 27.47,
    patients: 2,
  },
  {
    los: 39.1,
    patients: 1,
  },
  {
    los: 14.5,
    patients: 8,
  },
  {
    los: 23.73,
    patients: 6,
  },
  {
    los: 24.67,
    patients: 1,
  },
  {
    los: 43.7,
    patients: 2,
  },
  {
    los: 27.57,
    patients: 1,
  },
  {
    los: 29.67,
    patients: 2,
  },
  {
    los: 17.91,
    patients: 7,
  },
  {
    los: 14.24,
    patients: 2,
  },
  {
    los: 42.46,
    patients: 1,
  },
  {
    los: 13.33,
    patients: 4,
  },
  {
    los: 19.85,
    patients: 9,
  },
  {
    los: 13.11,
    patients: 13,
  },
  {
    los: 37.59,
    patients: 3,
  },
  {
    los: 42.32,
    patients: 1,
  },
  {
    los: 38.73,
    patients: 3,
  },
  {
    los: 49.6,
    patients: 1,
  },
  {
    los: 19.21,
    patients: 3,
  },
  {
    los: 25.89,
    patients: 3,
  },
  {
    los: 13.15,
    patients: 9,
  },
  {
    los: 25.4,
    patients: 1,
  },
  {
    los: 27.74,
    patients: 4,
  },
  {
    los: 30.71,
    patients: 2,
  },
  {
    los: 24.94,
    patients: 4,
  },
  {
    los: 20.84,
    patients: 8,
  },
  {
    los: 29.92,
    patients: 1,
  },
  {
    los: 26.49,
    patients: 1,
  },
  {
    los: 45.6,
    patients: 2,
  },
  {
    los: 13.47,
    patients: 6,
  },
  {
    los: 18.56,
    patients: 9,
  },
  {
    los: 30.67,
    patients: 2,
  },
  {
    los: 17.66,
    patients: 9,
  },
  {
    los: 11.21,
    patients: 7,
  },
  {
    los: 45.69,
    patients: 1,
  },
  {
    los: 20.98,
    patients: 4,
  },
  {
    los: 15.29,
    patients: 7,
  },
  {
    los: 21.49,
    patients: 2,
  },
  {
    los: 29.23,
    patients: 2,
  },
  {
    los: 19.66,
    patients: 8,
  },
  {
    los: 23.52,
    patients: 5,
  },
  {
    los: 45.58,
    patients: 1,
  },
  {
    los: 26.74,
    patients: 3,
  },
  {
    los: 15.33,
    patients: 5,
  },
  {
    los: 32.86,
    patients: 3,
  },
  {
    los: 33.44,
    patients: 1,
  },
  {
    los: 23.56,
    patients: 3,
  },
  {
    los: 29.44,
    patients: 1,
  },
  {
    los: 16.33,
    patients: 4,
  },
  {
    los: 45.89,
    patients: 1,
  },
  {
    los: 41.74,
    patients: 2,
  },
  {
    los: 29.9,
    patients: 2,
  },
  {
    los: 40.69,
    patients: 1,
  },
  {
    los: 13.81,
    patients: 15,
  },
  {
    los: 24.91,
    patients: 6,
  },
  {
    los: 13.76,
    patients: 12,
  },
  {
    los: 20.4,
    patients: 2,
  },
  {
    los: 22.44,
    patients: 5,
  },
  {
    los: 10.26,
    patients: 5,
  },
  {
    los: 19.03,
    patients: 5,
  },
  {
    los: 37.03,
    patients: 2,
  },
  {
    los: 14.43,
    patients: 9,
  },
  {
    los: 36.14,
    patients: 1,
  },
  {
    los: 22.05,
    patients: 6,
  },
  {
    los: 17.11,
    patients: 5,
  },
  {
    los: 18.44,
    patients: 2,
  },
  {
    los: 24.03,
    patients: 5,
  },
  {
    los: 47.14,
    patients: 2,
  },
  {
    los: 16.53,
    patients: 5,
  },
  {
    los: 24.58,
    patients: 2,
  },
  {
    los: 11.74,
    patients: 20,
  },
  {
    los: 17.24,
    patients: 2,
  },
  {
    los: 45.73,
    patients: 1,
  },
  {
    los: 49.66,
    patients: 2,
  },
  {
    los: 40.79,
    patients: 1,
  },
  {
    los: 29.64,
    patients: 3,
  },
  {
    los: 21.59,
    patients: 2,
  },
  {
    los: 45.76,
    patients: 2,
  },
  {
    los: 25.65,
    patients: 1,
  },
  {
    los: 29.06,
    patients: 2,
  },
  {
    los: 13.44,
    patients: 4,
  },
  {
    los: 28.88,
    patients: 5,
  },
  {
    los: 40.92,
    patients: 2,
  },
  {
    los: 30.75,
    patients: 2,
  },
  {
    los: 26.67,
    patients: 3,
  },
  {
    los: 47.65,
    patients: 1,
  },
  {
    los: 28.74,
    patients: 3,
  },
  {
    los: 43.9,
    patients: 2,
  },
  {
    los: 28.4,
    patients: 1,
  },
  {
    los: 24.92,
    patients: 3,
  },
  {
    los: 27.12,
    patients: 1,
  },
  {
    los: 20.82,
    patients: 7,
  },
  {
    los: 14.33,
    patients: 3,
  },
  {
    los: 21.69,
    patients: 10,
  },
  {
    los: 29.25,
    patients: 1,
  },
  {
    los: 21.91,
    patients: 5,
  },
  {
    los: 15.02,
    patients: 7,
  },
  {
    los: 20.07,
    patients: 3,
  },
  {
    los: 16.81,
    patients: 7,
  },
  {
    los: 40.93,
    patients: 1,
  },
  {
    los: 37.81,
    patients: 3,
  },
  {
    los: 15.98,
    patients: 8,
  },
  {
    los: 16.43,
    patients: 3,
  },
  {
    los: 45.7,
    patients: 1,
  },
  {
    los: 33.2,
    patients: 1,
  },
  {
    los: 36.48,
    patients: 1,
  },
  {
    los: 22.07,
    patients: 2,
  },
  {
    los: 30.06,
    patients: 1,
  },
  {
    los: 29.86,
    patients: 5,
  },
  {
    los: 26.01,
    patients: 1,
  },
  {
    los: 22.04,
    patients: 3,
  },
  {
    los: 11.14,
    patients: 9,
  },
  {
    los: 28.76,
    patients: 4,
  },
  {
    los: 23.95,
    patients: 3,
  },
  {
    los: 25.06,
    patients: 3,
  },
  {
    los: 34.65,
    patients: 2,
  },
  {
    los: 21.66,
    patients: 3,
  },
  {
    los: 37.73,
    patients: 1,
  },
  {
    los: 23.38,
    patients: 2,
  },
  {
    los: 18.16,
    patients: 3,
  },
  {
    los: 26.82,
    patients: 4,
  },
  {
    los: 21.63,
    patients: 6,
  },
  {
    los: 32.45,
    patients: 1,
  },
  {
    los: 20.77,
    patients: 7,
  },
  {
    los: 17.4,
    patients: 4,
  },
  {
    los: 9.33,
    patients: 7,
  },
  {
    los: 16.89,
    patients: 10,
  },
  {
    los: 20.04,
    patients: 6,
  },
  {
    los: 31.83,
    patients: 3,
  },
  {
    los: 17.32,
    patients: 2,
  },
  {
    los: 20.94,
    patients: 7,
  },
  {
    los: 26.18,
    patients: 1,
  },
  {
    los: 32.46,
    patients: 2,
  },
  {
    los: 48.71,
    patients: 1,
  },
  {
    los: 17.08,
    patients: 4,
  },
  {
    los: 26.15,
    patients: 3,
  },
  {
    los: 21.62,
    patients: 4,
  },
  {
    los: 40.73,
    patients: 2,
  },
  {
    los: 23.06,
    patients: 2,
  },
  {
    los: 21.23,
    patients: 3,
  },
  {
    los: 36.67,
    patients: 2,
  },
  {
    los: 33.89,
    patients: 2,
  },
  {
    los: 16.14,
    patients: 5,
  },
  {
    los: 16.23,
    patients: 2,
  },
  {
    los: 20.43,
    patients: 5,
  },
  {
    los: 40.8,
    patients: 2,
  },
  {
    los: 37.57,
    patients: 1,
  },
  {
    los: 15.34,
    patients: 3,
  },
  {
    los: 23.11,
    patients: 3,
  },
  {
    los: 26.88,
    patients: 1,
  },
  {
    los: 19.75,
    patients: 4,
  },
  {
    los: 20.93,
    patients: 4,
  },
  {
    los: 22.65,
    patients: 4,
  },
  {
    los: 18.03,
    patients: 3,
  },
  {
    los: 32.75,
    patients: 3,
  },
  {
    los: 36.58,
    patients: 1,
  },
  {
    los: 15.37,
    patients: 3,
  },
  {
    los: 28.49,
    patients: 2,
  },
  {
    los: 18.5,
    patients: 7,
  },
  {
    los: 24.83,
    patients: 3,
  },
  {
    los: 25.88,
    patients: 5,
  },
  {
    los: 11.38,
    patients: 5,
  },
  {
    los: 26.45,
    patients: 1,
  },
  {
    los: 22.61,
    patients: 2,
  },
  {
    los: 10.32,
    patients: 6,
  },
  {
    los: 25.03,
    patients: 3,
  },
  {
    los: 23.41,
    patients: 1,
  },
  {
    los: 22.85,
    patients: 6,
  },
  {
    los: 26.61,
    patients: 4,
  },
  {
    los: 33.15,
    patients: 1,
  },
  {
    los: 31.63,
    patients: 1,
  },
  {
    los: 44.85,
    patients: 1,
  },
  {
    los: 25.98,
    patients: 1,
  },
  {
    los: 19.16,
    patients: 1,
  },
  {
    los: 18.38,
    patients: 1,
  },
  {
    los: 23.26,
    patients: 3,
  },
  {
    los: 42.03,
    patients: 1,
  },
  {
    los: 15.1,
    patients: 6,
  },
  {
    los: 32.74,
    patients: 2,
  },
  {
    los: 32.58,
    patients: 2,
  },
  {
    los: 22.03,
    patients: 2,
  },
  {
    los: 24.68,
    patients: 1,
  },
  {
    los: 29.93,
    patients: 2,
  },
  {
    los: 19.06,
    patients: 2,
  },
  {
    los: 49.43,
    patients: 1,
  },
  {
    los: 30.11,
    patients: 1,
  },
  {
    los: 24.42,
    patients: 1,
  },
  {
    los: 42.66,
    patients: 1,
  },
  {
    los: 12.04,
    patients: 17,
  },
  {
    los: 45.78,
    patients: 1,
  },
  {
    los: 20.64,
    patients: 5,
  },
  {
    los: 21.08,
    patients: 2,
  },
  {
    los: 23.66,
    patients: 3,
  },
  {
    los: 23.98,
    patients: 3,
  },
  {
    los: 20.14,
    patients: 7,
  },
  {
    los: 24.16,
    patients: 1,
  },
  {
    los: 18.11,
    patients: 3,
  },
  {
    los: 29.32,
    patients: 1,
  },
  {
    los: 24.77,
    patients: 1,
  },
  {
    los: 25.3,
    patients: 1,
  },
  {
    los: 42.8,
    patients: 1,
  },
  {
    los: 39.04,
    patients: 1,
  },
  {
    los: 22.67,
    patients: 6,
  },
  {
    los: 33.67,
    patients: 1,
  },
  {
    los: 9.29,
    patients: 5,
  },
  {
    los: 14.17,
    patients: 6,
  },
  {
    los: 27.83,
    patients: 2,
  },
  {
    los: 38.85,
    patients: 2,
  },
  {
    los: 16.48,
    patients: 6,
  },
  {
    los: 39.22,
    patients: 1,
  },
  {
    los: 20.67,
    patients: 2,
  },
  {
    los: 35.38,
    patients: 1,
  },
  {
    los: 44.59,
    patients: 2,
  },
  {
    los: 21.58,
    patients: 4,
  },
  {
    los: 23.59,
    patients: 1,
  },
  {
    los: 33.03,
    patients: 1,
  },
  {
    los: 26.63,
    patients: 2,
  },
  {
    los: 18.18,
    patients: 3,
  },
  {
    los: 30.58,
    patients: 3,
  },
  {
    los: 27.9,
    patients: 2,
  },
  {
    los: 27.77,
    patients: 1,
  },
  {
    los: 30.98,
    patients: 2,
  },
  {
    los: 13.32,
    patients: 1,
  },
  {
    los: 25.85,
    patients: 3,
  },
  {
    los: 24.37,
    patients: 2,
  },
  {
    los: 26.76,
    patients: 2,
  },
  {
    los: 48.63,
    patients: 1,
  },
  {
    los: 16.35,
    patients: 1,
  },
  {
    los: 22.32,
    patients: 3,
  },
  {
    los: 40.77,
    patients: 2,
  },
  {
    los: 23.43,
    patients: 2,
  },
  {
    los: 41.23,
    patients: 1,
  },
  {
    los: 31.95,
    patients: 1,
  },
  {
    los: 33.59,
    patients: 1,
  },
  {
    los: 30.5,
    patients: 2,
  },
  {
    los: 38.8,
    patients: 2,
  },
  {
    los: 15.74,
    patients: 7,
  },
  {
    los: 30.44,
    patients: 1,
  },
  {
    los: 34.32,
    patients: 3,
  },
  {
    los: 28.54,
    patients: 1,
  },
  {
    los: 38.81,
    patients: 1,
  },
  {
    los: 46.99,
    patients: 2,
  },
  {
    los: 34.02,
    patients: 2,
  },
  {
    los: 27.4,
    patients: 3,
  },
  {
    los: 17.52,
    patients: 5,
  },
  {
    los: 26.5,
    patients: 2,
  },
  {
    los: 40.88,
    patients: 2,
  },
  {
    los: 26.6,
    patients: 1,
  },
  {
    los: 28.51,
    patients: 1,
  },
  {
    los: 16.4,
    patients: 2,
  },
  {
    los: 17.45,
    patients: 3,
  },
  {
    los: 41.06,
    patients: 1,
  },
  {
    los: 24.05,
    patients: 2,
  },
  {
    los: 27.71,
    patients: 1,
  },
  {
    los: 14.72,
    patients: 11,
  },
  {
    los: 32.89,
    patients: 2,
  },
  {
    los: 13.27,
    patients: 5,
  },
  {
    los: 21.22,
    patients: 1,
  },
  {
    los: 29.97,
    patients: 1,
  },
  {
    los: 28.72,
    patients: 3,
  },
  {
    los: 28.18,
    patients: 1,
  },
  {
    los: 48.22,
    patients: 1,
  },
  {
    los: 22.1,
    patients: 3,
  },
  {
    los: 46.94,
    patients: 2,
  },
  {
    los: 28.06,
    patients: 4,
  },
  {
    los: 26.05,
    patients: 2,
  },
  {
    los: 21.33,
    patients: 1,
  },
  {
    los: 38.51,
    patients: 2,
  },
  {
    los: 25.94,
    patients: 3,
  },
  {
    los: 39.06,
    patients: 1,
  },
  {
    los: 32.56,
    patients: 3,
  },
  {
    los: 39.89,
    patients: 2,
  },
  {
    los: 18.29,
    patients: 4,
  },
  {
    los: 22.91,
    patients: 5,
  },
  {
    los: 12.63,
    patients: 15,
  },
  {
    los: 46.23,
    patients: 1,
  },
  {
    los: 17.12,
    patients: 3,
  },
  {
    los: 19.68,
    patients: 3,
  },
  {
    los: 48.37,
    patients: 1,
  },
  {
    los: 17.93,
    patients: 4,
  },
  {
    los: 18.13,
    patients: 7,
  },
  {
    los: 35.74,
    patients: 1,
  },
  {
    los: 31.18,
    patients: 1,
  },
  {
    los: 18.43,
    patients: 6,
  },
  {
    los: 44.92,
    patients: 1,
  },
  {
    los: 24.74,
    patients: 4,
  },
  {
    los: 43.25,
    patients: 1,
  },
  {
    los: 19.14,
    patients: 2,
  },
  {
    los: 24.12,
    patients: 4,
  },
  {
    los: 36.45,
    patients: 1,
  },
  {
    los: 13.17,
    patients: 7,
  },
  {
    los: 40.19,
    patients: 1,
  },
  {
    los: 39.87,
    patients: 2,
  },
  {
    los: 31.23,
    patients: 1,
  },
  {
    los: 20.56,
    patients: 5,
  },
  {
    los: 36.96,
    patients: 1,
  },
  {
    los: 21.42,
    patients: 2,
  },
  {
    los: 43.14,
    patients: 2,
  },
  {
    los: 32.69,
    patients: 3,
  },
  {
    los: 30.53,
    patients: 2,
  },
  {
    los: 19.78,
    patients: 4,
  },
  {
    los: 27.28,
    patients: 2,
  },
  {
    los: 25.95,
    patients: 4,
  },
  {
    los: 20.28,
    patients: 3,
  },
  {
    los: 21.28,
    patients: 2,
  },
  {
    los: 11.41,
    patients: 10,
  },
  {
    los: 22.18,
    patients: 1,
  },
  {
    los: 12.41,
    patients: 6,
  },
  {
    los: 30.2,
    patients: 1,
  },
  {
    los: 16.92,
    patients: 9,
  },
  {
    los: 17.85,
    patients: 6,
  },
  {
    los: 28.94,
    patients: 2,
  },
  {
    los: 31.14,
    patients: 1,
  },
  {
    los: 34.09,
    patients: 2,
  },
  {
    los: 7.36,
    patients: 17,
  },
  {
    los: 20.54,
    patients: 2,
  },
  {
    los: 23.31,
    patients: 3,
  },
  {
    los: 47.07,
    patients: 3,
  },
  {
    los: 34.91,
    patients: 2,
  },
  {
    los: 21.48,
    patients: 3,
  },
  {
    los: 39.64,
    patients: 1,
  },
  {
    los: 38.83,
    patients: 1,
  },
  {
    los: 17.07,
    patients: 6,
  },
  {
    los: 45.39,
    patients: 1,
  },
  {
    los: 39.45,
    patients: 1,
  },
  {
    los: 28.5,
    patients: 2,
  },
  {
    los: 33.34,
    patients: 1,
  },
  {
    los: 16.58,
    patients: 9,
  },
  {
    los: 21.27,
    patients: 1,
  },
  {
    los: 28.75,
    patients: 1,
  },
  {
    los: 48.32,
    patients: 1,
  },
  {
    los: 19.5,
    patients: 4,
  },
  {
    los: 19.55,
    patients: 4,
  },
  {
    los: 31.68,
    patients: 1,
  },
  {
    los: 16.9,
    patients: 10,
  },
  {
    los: 18.07,
    patients: 3,
  },
  {
    los: 21.3,
    patients: 1,
  },
  {
    los: 18.22,
    patients: 2,
  },
  {
    los: 19.4,
    patients: 3,
  },
  {
    los: 28.35,
    patients: 2,
  },
  {
    los: 31.84,
    patients: 2,
  },
  {
    los: 47.69,
    patients: 2,
  },
  {
    los: 28.79,
    patients: 4,
  },
  {
    los: 26.96,
    patients: 1,
  },
  {
    los: 17.39,
    patients: 6,
  },
  {
    los: 29.04,
    patients: 5,
  },
  {
    los: 19.18,
    patients: 1,
  },
  {
    los: 44.23,
    patients: 1,
  },
  {
    los: 32.52,
    patients: 2,
  },
  {
    los: 49.96,
    patients: 1,
  },
  {
    los: 27.82,
    patients: 1,
  },
  {
    los: 28.61,
    patients: 4,
  },
  {
    los: 27.95,
    patients: 3,
  },
  {
    los: 15.42,
    patients: 1,
  },
  {
    los: 21.4,
    patients: 2,
  },
  {
    los: 34.68,
    patients: 3,
  },
  {
    los: 18.6,
    patients: 4,
  },
  {
    los: 20.15,
    patients: 4,
  },
  {
    los: 18.27,
    patients: 1,
  },
  {
    los: 28.68,
    patients: 3,
  },
  {
    los: 18.62,
    patients: 6,
  },
  {
    los: 14.46,
    patients: 4,
  },
  {
    los: 30.88,
    patients: 1,
  },
  {
    los: 35.95,
    patients: 1,
  },
  {
    los: 19.12,
    patients: 3,
  },
  {
    los: 49.08,
    patients: 1,
  },
  {
    los: 32.6,
    patients: 1,
  },
  {
    los: 21.25,
    patients: 2,
  },
  {
    los: 46.83,
    patients: 2,
  },
  {
    los: 18.33,
    patients: 1,
  },
  {
    los: 34.69,
    patients: 1,
  },
  {
    los: 25.9,
    patients: 4,
  },
  {
    los: 23.77,
    patients: 4,
  },
  {
    los: 19.87,
    patients: 8,
  },
  {
    los: 30.9,
    patients: 3,
  },
  {
    los: 19.15,
    patients: 1,
  },
  {
    los: 15.26,
    patients: 5,
  },
  {
    los: 29.91,
    patients: 3,
  },
  {
    los: 19.69,
    patients: 6,
  },
  {
    los: 25.47,
    patients: 1,
  },
  {
    los: 28.21,
    patients: 2,
  },
  {
    los: 14.31,
    patients: 5,
  },
  {
    los: 19.3,
    patients: 2,
  },
  {
    los: 19.28,
    patients: 1,
  },
  {
    los: 31.8,
    patients: 1,
  },
  {
    los: 47.63,
    patients: 1,
  },
  {
    los: 13.28,
    patients: 3,
  },
  {
    los: 40.55,
    patients: 2,
  },
  {
    los: 31.48,
    patients: 1,
  },
  {
    los: 30.79,
    patients: 2,
  },
  {
    los: 31.85,
    patients: 2,
  },
  {
    los: 20.23,
    patients: 2,
  },
  {
    los: 17.49,
    patients: 1,
  },
  {
    los: 26.09,
    patients: 4,
  },
  {
    los: 20.25,
    patients: 1,
  },
  {
    los: 25.29,
    patients: 1,
  },
  {
    los: 19.56,
    patients: 3,
  },
  {
    los: 37.8,
    patients: 1,
  },
  {
    los: 41.64,
    patients: 2,
  },
  {
    los: 25.41,
    patients: 2,
  },
  {
    los: 39.97,
    patients: 1,
  },
  {
    los: 26.22,
    patients: 1,
  },
  {
    los: 20.27,
    patients: 3,
  },
  {
    los: 19.47,
    patients: 1,
  },
  {
    los: 23.54,
    patients: 1,
  },
  {
    los: 15.22,
    patients: 6,
  },
  {
    los: 18.41,
    patients: 3,
  },
  {
    los: 20.2,
    patients: 3,
  },
  {
    los: 19.35,
    patients: 3,
  },
  {
    los: 31.52,
    patients: 4,
  },
  {
    los: 26.66,
    patients: 3,
  },
  {
    los: 25.02,
    patients: 2,
  },
  {
    los: 17.15,
    patients: 3,
  },
  {
    los: 15.3,
    patients: 1,
  },
  {
    los: 20.65,
    patients: 3,
  },
  {
    los: 24.81,
    patients: 4,
  },
  {
    los: 41.62,
    patients: 1,
  },
  {
    los: 25.01,
    patients: 1,
  },
  {
    los: 32.63,
    patients: 1,
  },
  {
    los: 15.5,
    patients: 5,
  },
  {
    los: 24.33,
    patients: 2,
  },
  {
    los: 18.59,
    patients: 5,
  },
  {
    los: 26.13,
    patients: 1,
  },
  {
    los: 34.75,
    patients: 1,
  },
  {
    los: 11.07,
    patients: 13,
  },
  {
    los: 13.29,
    patients: 5,
  },
  {
    los: 33.12,
    patients: 1,
  },
  {
    los: 24.73,
    patients: 1,
  },
  {
    los: 46.95,
    patients: 1,
  },
  {
    los: 22.64,
    patients: 3,
  },
  {
    los: 22.21,
    patients: 3,
  },
  {
    los: 19.46,
    patients: 1,
  },
  {
    los: 42.29,
    patients: 1,
  },
  {
    los: 16.24,
    patients: 1,
  },
  {
    los: 14.25,
    patients: 3,
  },
  {
    los: 24.89,
    patients: 3,
  },
  {
    los: 17.34,
    patients: 6,
  },
  {
    los: 16.71,
    patients: 6,
  },
  {
    los: 9.36,
    patients: 7,
  },
  {
    los: 35.7,
    patients: 2,
  },
  {
    los: 12.28,
    patients: 7,
  },
  {
    los: 20.09,
    patients: 2,
  },
  {
    los: 32.92,
    patients: 1,
  },
  {
    los: 16.46,
    patients: 2,
  },
  {
    los: 37.49,
    patients: 1,
  },
  {
    los: 29.12,
    patients: 2,
  },
  {
    los: 35.3,
    patients: 1,
  },
  {
    los: 30.08,
    patients: 3,
  },
  {
    los: 34.71,
    patients: 2,
  },
  {
    los: 28.56,
    patients: 2,
  },
  {
    los: 24.56,
    patients: 1,
  },
  {
    los: 25.05,
    patients: 2,
  },
  {
    los: 33.16,
    patients: 1,
  },
  {
    los: 44.72,
    patients: 1,
  },
  {
    los: 19.62,
    patients: 4,
  },
  {
    los: 36.8,
    patients: 1,
  },
  {
    los: 25.11,
    patients: 2,
  },
  {
    los: 21.18,
    patients: 5,
  },
  {
    los: 32.77,
    patients: 3,
  },
  {
    los: 20.86,
    patients: 4,
  },
  {
    los: 17.42,
    patients: 3,
  },
  {
    los: 41.45,
    patients: 1,
  },
  {
    los: 34.43,
    patients: 1,
  },
  {
    los: 26.91,
    patients: 1,
  },
  {
    los: 45.68,
    patients: 1,
  },
  {
    los: 23.28,
    patients: 3,
  },
  {
    los: 25.84,
    patients: 3,
  },
  {
    los: 22.38,
    patients: 3,
  },
  {
    los: 29.57,
    patients: 1,
  },
  {
    los: 18.39,
    patients: 3,
  },
  {
    los: 35.76,
    patients: 2,
  },
  {
    los: 23.29,
    patients: 2,
  },
  {
    los: 32.82,
    patients: 1,
  },
  {
    los: 13.3,
    patients: 5,
  },
  {
    los: 15.2,
    patients: 5,
  },
  {
    los: 39.58,
    patients: 1,
  },
  {
    los: 42.16,
    patients: 1,
  },
  {
    los: 29.54,
    patients: 3,
  },
  {
    los: 17.22,
    patients: 3,
  },
  {
    los: 25.08,
    patients: 1,
  },
  {
    los: 32.91,
    patients: 1,
  },
  {
    los: 24.93,
    patients: 5,
  },
  {
    los: 27.39,
    patients: 2,
  },
  {
    los: 12.42,
    patients: 3,
  },
  {
    los: 21.06,
    patients: 2,
  },
  {
    los: 14.41,
    patients: 8,
  },
  {
    los: 16.61,
    patients: 4,
  },
  {
    los: 44.38,
    patients: 1,
  },
  {
    los: 21.29,
    patients: 2,
  },
  {
    los: 33.24,
    patients: 1,
  },
  {
    los: 36.13,
    patients: 1,
  },
  {
    los: 24.15,
    patients: 3,
  },
  {
    los: 42.21,
    patients: 1,
  },
  {
    los: 12.33,
    patients: 3,
  },
  {
    los: 31.08,
    patients: 2,
  },
  {
    los: 43.74,
    patients: 2,
  },
  {
    los: 33.21,
    patients: 1,
  },
  {
    los: 28.47,
    patients: 1,
  },
  {
    los: 22.08,
    patients: 2,
  },
  {
    los: 27.78,
    patients: 2,
  },
  {
    los: 44.06,
    patients: 2,
  },
  {
    los: 33.06,
    patients: 1,
  },
  {
    los: 26.48,
    patients: 1,
  },
  {
    los: 29.55,
    patients: 1,
  },
  {
    los: 47.6,
    patients: 1,
  },
  {
    los: 23.79,
    patients: 5,
  },
  {
    los: 25.64,
    patients: 1,
  },
  {
    los: 22.83,
    patients: 2,
  },
  {
    los: 22.09,
    patients: 4,
  },
  {
    los: 45.46,
    patients: 1,
  },
  {
    los: 26.84,
    patients: 2,
  },
  {
    los: 24.02,
    patients: 2,
  },
  {
    los: 42.98,
    patients: 1,
  },
  {
    los: 47.22,
    patients: 2,
  },
  {
    los: 26.86,
    patients: 2,
  },
  {
    los: 20.92,
    patients: 9,
  },
  {
    los: 32.32,
    patients: 3,
  },
  {
    los: 24.71,
    patients: 3,
  },
  {
    los: 25.23,
    patients: 1,
  },
  {
    los: 18.51,
    patients: 2,
  },
  {
    los: 20.59,
    patients: 2,
  },
  {
    los: 35.14,
    patients: 1,
  },
  {
    los: 29.07,
    patients: 2,
  },
  {
    los: 21.67,
    patients: 2,
  },
  {
    los: 38.52,
    patients: 1,
  },
  {
    los: 35.62,
    patients: 2,
  },
  {
    los: 49.68,
    patients: 1,
  },
  {
    los: 38.88,
    patients: 1,
  },
  {
    los: 24.76,
    patients: 4,
  },
  {
    los: 30.39,
    patients: 2,
  },
  {
    los: 43.23,
    patients: 1,
  },
  {
    los: 24.5,
    patients: 4,
  },
  {
    los: 27.03,
    patients: 1,
  },
  {
    los: 27.79,
    patients: 1,
  },
  {
    los: 33.71,
    patients: 1,
  },
  {
    los: 45.97,
    patients: 2,
  },
  {
    los: 19.94,
    patients: 5,
  },
  {
    los: 33.07,
    patients: 2,
  },
  {
    los: 44.29,
    patients: 2,
  },
  {
    los: 40.74,
    patients: 1,
  },
  {
    los: 29.2,
    patients: 1,
  },
  {
    los: 38.63,
    patients: 1,
  },
  {
    los: 26.73,
    patients: 1,
  },
  {
    los: 40.23,
    patients: 1,
  },
  {
    los: 32.62,
    patients: 1,
  },
  {
    los: 21.5,
    patients: 2,
  },
  {
    los: 31.07,
    patients: 2,
  },
  {
    los: 17.35,
    patients: 6,
  },
  {
    los: 20.16,
    patients: 2,
  },
  {
    los: 38.94,
    patients: 1,
  },
  {
    los: 23.68,
    patients: 2,
  },
  {
    los: 38.02,
    patients: 1,
  },
  {
    los: 39.5,
    patients: 1,
  },
  {
    los: 25.67,
    patients: 4,
  },
  {
    los: 30.48,
    patients: 1,
  },
  {
    los: 20.3,
    patients: 1,
  },
  {
    los: 31.58,
    patients: 1,
  },
  {
    los: 19.57,
    patients: 5,
  },
  {
    los: 44.41,
    patients: 2,
  },
  {
    los: 24.66,
    patients: 4,
  },
  {
    los: 49.73,
    patients: 1,
  },
  {
    los: 23.08,
    patients: 2,
  },
  {
    los: 20.29,
    patients: 1,
  },
  {
    los: 39.86,
    patients: 2,
  },
  {
    los: 22.93,
    patients: 4,
  },
  {
    los: 43.8,
    patients: 3,
  },
  {
    los: 38.77,
    patients: 2,
  },
  {
    los: 34.77,
    patients: 1,
  },
  {
    los: 23.16,
    patients: 1,
  },
  {
    los: 27.55,
    patients: 2,
  },
  {
    los: 23.83,
    patients: 2,
  },
  {
    los: 20.35,
    patients: 1,
  },
  {
    los: 22.11,
    patients: 1,
  },
  {
    los: 23.58,
    patients: 1,
  },
  {
    los: 25.34,
    patients: 2,
  },
  {
    los: 22.72,
    patients: 3,
  },
  {
    los: 38.64,
    patients: 1,
  },
  {
    los: 44.77,
    patients: 1,
  },
  {
    los: 34.12,
    patients: 1,
  },
  {
    los: 32.94,
    patients: 1,
  },
  {
    los: 31.11,
    patients: 2,
  },
  {
    los: 23.23,
    patients: 2,
  },
  {
    los: 24.23,
    patients: 2,
  },
  {
    los: 33.81,
    patients: 2,
  },
  {
    los: 36.92,
    patients: 1,
  },
  {
    los: 18.19,
    patients: 1,
  },
  {
    los: 17.14,
    patients: 1,
  },
  {
    los: 30.55,
    patients: 2,
  },
  {
    los: 39.73,
    patients: 2,
  },
  {
    los: 46.77,
    patients: 1,
  },
  {
    los: 38.67,
    patients: 1,
  },
  {
    los: 34.66,
    patients: 2,
  },
  {
    los: 43.2,
    patients: 1,
  },
  {
    los: 31.5,
    patients: 3,
  },
  {
    los: 12.16,
    patients: 9,
  },
  {
    los: 48.85,
    patients: 1,
  },
  {
    los: 21.03,
    patients: 4,
  },
  {
    los: 15.28,
    patients: 3,
  },
  {
    los: 21.37,
    patients: 1,
  },
  {
    los: 22.29,
    patients: 1,
  },
  {
    los: 38.92,
    patients: 1,
  },
  {
    los: 37.98,
    patients: 1,
  },
  {
    los: 36.04,
    patients: 2,
  },
  {
    los: 39.03,
    patients: 1,
  },
  {
    los: 38.01,
    patients: 3,
  },
  {
    los: 30.36,
    patients: 1,
  },
  {
    los: 36.63,
    patients: 1,
  },
  {
    los: 39.02,
    patients: 1,
  },
  {
    los: 27.37,
    patients: 1,
  },
  {
    los: 31.56,
    patients: 2,
  },
  {
    los: 27.97,
    patients: 3,
  },
  {
    los: 17.23,
    patients: 2,
  },
  {
    los: 25.07,
    patients: 2,
  },
  {
    los: 35.15,
    patients: 1,
  },
  {
    los: 21.84,
    patients: 5,
  },
  {
    los: 38.11,
    patients: 1,
  },
  {
    los: 34.26,
    patients: 1,
  },
  {
    los: 17.57,
    patients: 4,
  },
  {
    los: 49.53,
    patients: 2,
  },
  {
    los: 26.65,
    patients: 2,
  },
  {
    los: 48.01,
    patients: 1,
  },
  {
    los: 29.01,
    patients: 2,
  },
  {
    los: 15.51,
    patients: 7,
  },
  {
    los: 25.13,
    patients: 1,
  },
  {
    los: 31.25,
    patients: 1,
  },
  {
    los: 20.47,
    patients: 1,
  },
  {
    los: 17.26,
    patients: 1,
  },
  {
    los: 18.96,
    patients: 4,
  },
  {
    los: 24.49,
    patients: 1,
  },
  {
    los: 42.95,
    patients: 1,
  },
  {
    los: 45.85,
    patients: 1,
  },
  {
    los: 21.05,
    patients: 2,
  },
  {
    los: 22.33,
    patients: 2,
  },
  {
    los: 38.89,
    patients: 1,
  },
  {
    los: 12.39,
    patients: 6,
  },
  {
    los: 27.85,
    patients: 3,
  },
  {
    los: 29.1,
    patients: 1,
  },
  {
    los: 33.17,
    patients: 1,
  },
  {
    los: 13.36,
    patients: 4,
  },
  {
    los: 21.45,
    patients: 1,
  },
  {
    los: 16.26,
    patients: 2,
  },
  {
    los: 37.87,
    patients: 1,
  },
  {
    los: 17.31,
    patients: 3,
  },
  {
    los: 33.09,
    patients: 1,
  },
  {
    los: 27.43,
    patients: 1,
  },
  {
    los: 20.76,
    patients: 1,
  },
  {
    los: 18.1,
    patients: 5,
  },
  {
    los: 28.46,
    patients: 2,
  },
  {
    los: 21.36,
    patients: 1,
  },
  {
    los: 22.7,
    patients: 1,
  },
  {
    los: 34.79,
    patients: 1,
  },
  {
    los: 31.13,
    patients: 2,
  },
  {
    los: 28.44,
    patients: 2,
  },
  {
    los: 38.19,
    patients: 1,
  },
  {
    los: 22.24,
    patients: 4,
  },
  {
    los: 42.59,
    patients: 1,
  },
  {
    los: 32.02,
    patients: 2,
  },
  {
    los: 44.51,
    patients: 1,
  },
  {
    los: 39.41,
    patients: 1,
  },
  {
    los: 41.8,
    patients: 1,
  },
  {
    los: 44.94,
    patients: 2,
  },
  {
    los: 25.48,
    patients: 2,
  },
  {
    los: 29.39,
    patients: 1,
  },
  {
    los: 39.07,
    patients: 1,
  },
  {
    los: 45.75,
    patients: 1,
  },
  {
    los: 46.76,
    patients: 2,
  },
  {
    los: 27.72,
    patients: 5,
  },
  {
    los: 24.64,
    patients: 2,
  },
  {
    los: 32.9,
    patients: 1,
  },
  {
    los: 17.48,
    patients: 4,
  },
  {
    los: 42.85,
    patients: 2,
  },
  {
    los: 46.62,
    patients: 1,
  },
  {
    los: 23.35,
    patients: 2,
  },
  {
    los: 21.87,
    patients: 1,
  },
  {
    los: 42.6,
    patients: 1,
  },
  {
    los: 14.39,
    patients: 5,
  },
  {
    los: 37.66,
    patients: 1,
  },
  {
    los: 28.95,
    patients: 2,
  },
  {
    los: 22.06,
    patients: 5,
  },
  {
    los: 18.98,
    patients: 4,
  },
  {
    los: 29.69,
    patients: 1,
  },
  {
    los: 45.32,
    patients: 1,
  },
  {
    los: 23.88,
    patients: 3,
  },
  {
    los: 33.52,
    patients: 1,
  },
  {
    los: 23.48,
    patients: 1,
  },
  {
    los: 44.14,
    patients: 1,
  },
  {
    los: 16.13,
    patients: 7,
  },
  {
    los: 24.09,
    patients: 2,
  },
  {
    los: 27.01,
    patients: 2,
  },
  {
    los: 23.62,
    patients: 2,
  },
  {
    los: 19.98,
    patients: 1,
  },
  {
    los: 42.61,
    patients: 1,
  },
  {
    los: 30.59,
    patients: 1,
  },
  {
    los: 48.03,
    patients: 1,
  },
  {
    los: 18.49,
    patients: 3,
  },
  {
    los: 39.71,
    patients: 1,
  },
  {
    los: 23.05,
    patients: 1,
  },
  {
    los: 26.29,
    patients: 1,
  },
  {
    los: 36.79,
    patients: 1,
  },
  {
    los: 31.69,
    patients: 1,
  },
  {
    los: 27.68,
    patients: 1,
  },
  {
    los: 28.05,
    patients: 3,
  },
  {
    los: 19.22,
    patients: 1,
  },
  {
    los: 26.14,
    patients: 1,
  },
  {
    los: 42.71,
    patients: 2,
  },
  {
    los: 45.37,
    patients: 1,
  },
  {
    los: 29.77,
    patients: 4,
  },
  {
    los: 22.79,
    patients: 2,
  },
  {
    los: 45.41,
    patients: 1,
  },
  {
    los: 18.4,
    patients: 4,
  },
  {
    los: 23.82,
    patients: 2,
  },
  {
    los: 26.23,
    patients: 3,
  },
  {
    los: 41.98,
    patients: 1,
  },
  {
    los: 27.76,
    patients: 3,
  },
  {
    los: 46.58,
    patients: 1,
  },
  {
    los: 27.13,
    patients: 3,
  },
  {
    los: 28.15,
    patients: 1,
  },
  {
    los: 28.36,
    patients: 1,
  },
  {
    los: 22.19,
    patients: 1,
  },
  {
    los: 48.81,
    patients: 1,
  },
  {
    los: 15.17,
    patients: 6,
  },
  {
    los: 26.35,
    patients: 1,
  },
  {
    los: 30.47,
    patients: 1,
  },
  {
    los: 48.5,
    patients: 2,
  },
  {
    los: 40.29,
    patients: 1,
  },
  {
    los: 41.7,
    patients: 1,
  },
  {
    los: 26.03,
    patients: 1,
  },
  {
    los: 45.94,
    patients: 1,
  },
  {
    los: 46.78,
    patients: 1,
  },
  {
    los: 48.48,
    patients: 1,
  },
  {
    los: 28.65,
    patients: 3,
  },
  {
    los: 22.86,
    patients: 1,
  },
  {
    los: 28.45,
    patients: 1,
  },
  {
    los: 42.81,
    patients: 3,
  },
  {
    los: 21.9,
    patients: 1,
  },
  {
    los: 19.95,
    patients: 3,
  },
  {
    los: 28.82,
    patients: 1,
  },
  {
    los: 20.6,
    patients: 4,
  },
  {
    los: 33.83,
    patients: 3,
  },
  {
    los: 22.37,
    patients: 2,
  },
  {
    los: 25.8,
    patients: 2,
  },
  {
    los: 48.77,
    patients: 1,
  },
  {
    los: 28.77,
    patients: 2,
  },
  {
    los: 21.21,
    patients: 1,
  },
  {
    los: 22.59,
    patients: 1,
  },
  {
    los: 31.75,
    patients: 1,
  },
  {
    los: 18.35,
    patients: 1,
  },
  {
    los: 24.4,
    patients: 2,
  },
  {
    los: 26.1,
    patients: 2,
  },
  {
    los: 44.71,
    patients: 1,
  },
  {
    los: 32.03,
    patients: 3,
  },
  {
    los: 24.86,
    patients: 1,
  },
  {
    los: 29.09,
    patients: 2,
  },
  {
    los: 34.76,
    patients: 2,
  },
  {
    los: 31.76,
    patients: 2,
  },
  {
    los: 37.24,
    patients: 1,
  },
  {
    los: 44.84,
    patients: 1,
  },
  {
    los: 33.65,
    patients: 1,
  },
  {
    los: 30.86,
    patients: 1,
  },
  {
    los: 31.99,
    patients: 1,
  },
  {
    los: 20.36,
    patients: 2,
  },
  {
    los: 46.69,
    patients: 1,
  },
  {
    los: 37.38,
    patients: 1,
  },
  {
    los: 12.47,
    patients: 3,
  },
  {
    los: 40.2,
    patients: 1,
  },
  {
    los: 34.99,
    patients: 3,
  },
  {
    los: 30.22,
    patients: 1,
  },
  {
    los: 32.96,
    patients: 2,
  },
  {
    los: 28.13,
    patients: 2,
  },
  {
    los: 38.76,
    patients: 1,
  },
  {
    los: 24.1,
    patients: 2,
  },
  {
    los: 36.1,
    patients: 1,
  },
  {
    los: 21.41,
    patients: 1,
  },
  {
    los: 49.7,
    patients: 1,
  },
  {
    los: 35.66,
    patients: 1,
  },
  {
    los: 45.3,
    patients: 1,
  },
  {
    los: 33.85,
    patients: 2,
  },
  {
    los: 22.01,
    patients: 1,
  },
  {
    los: 30.04,
    patients: 1,
  },
  {
    los: 38.84,
    patients: 1,
  },
  {
    los: 37.09,
    patients: 1,
  },
  {
    los: 23.93,
    patients: 1,
  },
  {
    los: 28.33,
    patients: 2,
  },
  {
    los: 39.2,
    patients: 1,
  },
  {
    los: 16.2,
    patients: 4,
  },
  {
    los: 39.19,
    patients: 1,
  },
  {
    los: 40.07,
    patients: 1,
  },
  {
    los: 34.98,
    patients: 1,
  },
  {
    los: 31.92,
    patients: 1,
  },
  {
    los: 24.08,
    patients: 1,
  },
  {
    los: 25.19,
    patients: 2,
  },
  {
    los: 37.31,
    patients: 1,
  },
  {
    los: 22.6,
    patients: 2,
  },
  {
    los: 34.1,
    patients: 1,
  },
  {
    los: 27.87,
    patients: 1,
  },
  {
    los: 15.23,
    patients: 2,
  },
  {
    los: 36.09,
    patients: 1,
  },
  {
    los: 40.49,
    patients: 1,
  },
  {
    los: 31.41,
    patients: 1,
  },
  {
    los: 19.33,
    patients: 2,
  },
  {
    los: 31.77,
    patients: 1,
  },
  {
    los: 29.75,
    patients: 1,
  },
  {
    los: 30.66,
    patients: 2,
  },
  {
    los: 35.03,
    patients: 1,
  },
  {
    los: 23.67,
    patients: 2,
  },
  {
    los: 29.85,
    patients: 1,
  },
  {
    los: 26.04,
    patients: 1,
  },
  {
    los: 39.01,
    patients: 1,
  },
  {
    los: 30.07,
    patients: 1,
  },
  {
    los: 19.67,
    patients: 5,
  },
  {
    los: 15.41,
    patients: 3,
  },
  {
    los: 32.59,
    patients: 2,
  },
  {
    los: 43.02,
    patients: 1,
  },
  {
    los: 24.6,
    patients: 5,
  },
  {
    los: 48.75,
    patients: 1,
  },
  {
    los: 34.55,
    patients: 2,
  },
  {
    los: 26.62,
    patients: 1,
  },
  {
    los: 27.61,
    patients: 2,
  },
  {
    los: 49.74,
    patients: 1,
  },
  {
    los: 40.66,
    patients: 2,
  },
  {
    los: 43.13,
    patients: 1,
  },
  {
    los: 38.35,
    patients: 1,
  },
  {
    los: 42.17,
    patients: 1,
  },
  {
    los: 28.2,
    patients: 2,
  },
  {
    los: 22.36,
    patients: 1,
  },
  {
    los: 19.32,
    patients: 1,
  },
  {
    los: 30.01,
    patients: 2,
  },
  {
    los: 23.33,
    patients: 2,
  },
  {
    los: 15.36,
    patients: 5,
  },
  {
    los: 25.04,
    patients: 3,
  },
  {
    los: 24.95,
    patients: 4,
  },
  {
    los: 32.38,
    patients: 1,
  },
  {
    los: 25.42,
    patients: 1,
  },
  {
    los: 24.78,
    patients: 2,
  },
  {
    los: 19.23,
    patients: 2,
  },
  {
    los: 35.89,
    patients: 1,
  },
  {
    los: 30.68,
    patients: 1,
  },
  {
    los: 31.38,
    patients: 1,
  },
  {
    los: 36.99,
    patients: 1,
  },
  {
    los: 48.52,
    patients: 1,
  },
  {
    los: 26.78,
    patients: 1,
  },
  {
    los: 19.39,
    patients: 1,
  },
  {
    los: 26.31,
    patients: 1,
  },
  {
    los: 23.15,
    patients: 1,
  },
  {
    los: 38.21,
    patients: 1,
  },
  {
    los: 29.71,
    patients: 2,
  },
  {
    los: 44.52,
    patients: 1,
  },
  {
    los: 26.7,
    patients: 1,
  },
  {
    los: 21.75,
    patients: 1,
  },
  {
    los: 22.52,
    patients: 2,
  },
  {
    los: 34.42,
    patients: 1,
  },
  {
    los: 24.47,
    patients: 1,
  },
  {
    los: 30.61,
    patients: 2,
  },
  {
    los: 29.89,
    patients: 1,
  },
  {
    los: 29.81,
    patients: 2,
  },
  {
    los: 34.59,
    patients: 3,
  },
  {
    los: 31.66,
    patients: 1,
  },
  {
    los: 25.69,
    patients: 3,
  },
  {
    los: 27.89,
    patients: 1,
  },
  {
    los: 27.92,
    patients: 2,
  },
  {
    los: 25.2,
    patients: 1,
  },
  {
    los: 31.67,
    patients: 2,
  },
  {
    los: 28.03,
    patients: 2,
  },
  {
    los: 20.24,
    patients: 1,
  },
  {
    los: 37.71,
    patients: 1,
  },
  {
    los: 26.12,
    patients: 3,
  },
  {
    los: 28.71,
    patients: 1,
  },
  {
    los: 47.92,
    patients: 1,
  },
  {
    los: 43.78,
    patients: 1,
  },
  {
    los: 43.05,
    patients: 1,
  },
  {
    los: 33.19,
    patients: 2,
  },
  {
    los: 23.63,
    patients: 4,
  },
  {
    los: 37.06,
    patients: 1,
  },
  {
    los: 45.91,
    patients: 1,
  },
  {
    los: 19.48,
    patients: 1,
  },
  {
    los: 27.67,
    patients: 1,
  },
  {
    los: 41.01,
    patients: 1,
  },
  {
    los: 22.71,
    patients: 3,
  },
  {
    los: 34.25,
    patients: 1,
  },
  {
    los: 34.58,
    patients: 1,
  },
  {
    los: 46.59,
    patients: 1,
  },
  {
    los: 29.14,
    patients: 1,
  },
  {
    los: 18.53,
    patients: 2,
  },
  {
    los: 34.9,
    patients: 1,
  },
  {
    los: 48.53,
    patients: 1,
  },
  {
    los: 29.36,
    patients: 1,
  },
  {
    los: 24.06,
    patients: 1,
  },
  {
    los: 27.31,
    patients: 1,
  },
  {
    los: 22.98,
    patients: 2,
  },
  {
    los: 48.89,
    patients: 1,
  },
  {
    los: 24.62,
    patients: 1,
  },
  {
    los: 34.74,
    patients: 1,
  },
  {
    los: 29.82,
    patients: 2,
  },
  {
    los: 22.31,
    patients: 1,
  },
  {
    los: 33.6,
    patients: 2,
  },
  {
    los: 39.42,
    patients: 1,
  },
  {
    los: 41.43,
    patients: 1,
  },
  {
    los: 22.27,
    patients: 2,
  },
  {
    los: 18.12,
    patients: 3,
  },
  {
    los: 43.98,
    patients: 1,
  },
  {
    los: 26.38,
    patients: 1,
  },
  {
    los: 37.56,
    patients: 1,
  },
  {
    los: 31.62,
    patients: 2,
  },
  {
    los: 35.97,
    patients: 1,
  },
  {
    los: 33.73,
    patients: 2,
  },
  {
    los: 28.81,
    patients: 3,
  },
  {
    los: 31.78,
    patients: 1,
  },
  {
    los: 30.84,
    patients: 2,
  },
  {
    los: 40.81,
    patients: 1,
  },
  {
    los: 19.43,
    patients: 3,
  },
  {
    los: 35.79,
    patients: 1,
  },
  {
    los: 37.11,
    patients: 1,
  },
  {
    los: 18.36,
    patients: 1,
  },
  {
    los: 12.4,
    patients: 8,
  },
  {
    los: 33.8,
    patients: 1,
  },
  {
    los: 34.57,
    patients: 1,
  },
  {
    los: 13.37,
    patients: 2,
  },
  {
    los: 35.51,
    patients: 1,
  },
  {
    los: 19.36,
    patients: 1,
  },
  {
    los: 44.66,
    patients: 1,
  },
  {
    los: 41.81,
    patients: 1,
  },
  {
    los: 29.34,
    patients: 1,
  },
  {
    los: 26.28,
    patients: 1,
  },
  {
    los: 47.57,
    patients: 1,
  },
  {
    los: 39.83,
    patients: 1,
  },
  {
    los: 31.35,
    patients: 1,
  },
  {
    los: 35.55,
    patients: 1,
  },
  {
    los: 17.25,
    patients: 2,
  },
  {
    los: 42.67,
    patients: 1,
  },
  {
    los: 27.69,
    patients: 2,
  },
  {
    los: 33.32,
    patients: 1,
  },
  {
    los: 41.84,
    patients: 1,
  },
  {
    los: 24.88,
    patients: 2,
  },
  {
    los: 41.28,
    patients: 1,
  },
  {
    los: 43.6,
    patients: 1,
  },
  {
    los: 37.79,
    patients: 1,
  },
  {
    los: 41.95,
    patients: 1,
  },
  {
    los: 19.2,
    patients: 3,
  },
  {
    los: 44.21,
    patients: 1,
  },
  {
    los: 35.52,
    patients: 1,
  },
  {
    los: 25.72,
    patients: 2,
  },
  {
    los: 39.14,
    patients: 1,
  },
  {
    los: 29.76,
    patients: 1,
  },
  {
    los: 39.59,
    patients: 1,
  },
  {
    los: 25.24,
    patients: 2,
  },
  {
    los: 38.74,
    patients: 1,
  },
  {
    los: 42.54,
    patients: 1,
  },
  {
    los: 27.33,
    patients: 1,
  },
  {
    los: 35.12,
    patients: 2,
  },
  {
    los: 41.93,
    patients: 1,
  },
  {
    los: 34.97,
    patients: 1,
  },
  {
    los: 32.93,
    patients: 1,
  },
  {
    los: 28.92,
    patients: 2,
  },
  {
    los: 32.84,
    patients: 1,
  },
  {
    los: 25.33,
    patients: 1,
  },
  {
    los: 29.96,
    patients: 1,
  },
  {
    los: 30.92,
    patients: 1,
  },
  {
    los: 31.02,
    patients: 1,
  },
  {
    los: 36.61,
    patients: 1,
  },
  {
    los: 41.96,
    patients: 2,
  },
  {
    los: 33.92,
    patients: 1,
  },
  {
    los: 47.91,
    patients: 1,
  },
  {
    los: 28.34,
    patients: 1,
  },
  {
    los: 41.85,
    patients: 2,
  },
  {
    los: 23.5,
    patients: 2,
  },
  {
    los: 45.54,
    patients: 1,
  },
  {
    los: 28.73,
    patients: 1,
  },
  {
    los: 44.25,
    patients: 1,
  },
  {
    los: 34.93,
    patients: 1,
  },
  {
    los: 28.93,
    patients: 1,
  },
  {
    los: 42.89,
    patients: 1,
  },
  {
    los: 43.97,
    patients: 2,
  },
  {
    los: 28.96,
    patients: 1,
  },
  {
    los: 20.57,
    patients: 2,
  },
  {
    los: 30.41,
    patients: 1,
  },
  {
    los: 21.07,
    patients: 3,
  },
  {
    los: 19.01,
    patients: 2,
  },
  {
    los: 16.22,
    patients: 1,
  },
  {
    los: 41.82,
    patients: 1,
  },
  {
    los: 32.47,
    patients: 1,
  },
  {
    los: 44.48,
    patients: 1,
  },
  {
    los: 48.61,
    patients: 1,
  },
  {
    los: 26.79,
    patients: 3,
  },
  {
    los: 29.98,
    patients: 1,
  },
  {
    los: 28.38,
    patients: 1,
  },
  {
    los: 29.11,
    patients: 2,
  },
  {
    los: 34.28,
    patients: 1,
  },
  {
    los: 30.93,
    patients: 2,
  },
  {
    los: 34.67,
    patients: 1,
  },
  {
    los: 26.16,
    patients: 1,
  },
  {
    los: 36.47,
    patients: 1,
  },
  {
    los: 27.04,
    patients: 1,
  },
  {
    los: 34.45,
    patients: 1,
  },
  {
    los: 30.91,
    patients: 1,
  },
  {
    los: 14.35,
    patients: 1,
  },
  {
    los: 29.08,
    patients: 1,
  },
  {
    los: 33.11,
    patients: 1,
  },
  {
    los: 34.92,
    patients: 1,
  },
  {
    los: 25.16,
    patients: 1,
  },
  {
    los: 21.26,
    patients: 2,
  },
  {
    los: 49.75,
    patients: 1,
  },
  {
    los: 16.45,
    patients: 1,
  },
  {
    los: 23.14,
    patients: 2,
  },
  {
    los: 37.25,
    patients: 1,
  },
  {
    los: 36.76,
    patients: 1,
  },
  {
    los: 32.18,
    patients: 1,
  },
  {
    los: 25.38,
    patients: 1,
  },
  {
    los: 28.69,
    patients: 1,
  },
  {
    los: 35.69,
    patients: 1,
  },
  {
    los: 23.47,
    patients: 1,
  },
  {
    los: 30.83,
    patients: 2,
  },
  {
    los: 45.11,
    patients: 1,
  },
  {
    los: 37.95,
    patients: 2,
  },
  {
    los: 34.3,
    patients: 1,
  },
  {
    los: 37.6,
    patients: 2,
  },
  {
    los: 29.7,
    patients: 3,
  },
  {
    los: 24.17,
    patients: 1,
  },
  {
    los: 25.35,
    patients: 1,
  },
  {
    los: 37.9,
    patients: 1,
  },
  {
    los: 33.88,
    patients: 1,
  },
  {
    los: 35.92,
    patients: 1,
  },
  {
    los: 42.08,
    patients: 2,
  },
  {
    los: 35.04,
    patients: 1,
  },
  {
    los: 25.27,
    patients: 1,
  },
  {
    los: 35.86,
    patients: 1,
  },
  {
    los: 20.41,
    patients: 2,
  },
  {
    los: 40.78,
    patients: 1,
  },
  {
    los: 36.68,
    patients: 1,
  },
  {
    los: 26.46,
    patients: 2,
  },
  {
    los: 29.63,
    patients: 1,
  },
  {
    los: 19.24,
    patients: 2,
  },
  {
    los: 33.79,
    patients: 1,
  },
  {
    los: 23.64,
    patients: 3,
  },
  {
    los: 26.99,
    patients: 1,
  },
  {
    los: 32.87,
    patients: 2,
  },
  {
    los: 37.91,
    patients: 1,
  },
  {
    los: 42.82,
    patients: 2,
  },
  {
    los: 44.6,
    patients: 1,
  },
  {
    los: 29.65,
    patients: 1,
  },
  {
    los: 38.09,
    patients: 1,
  },
  {
    los: 31.36,
    patients: 1,
  },
  {
    los: 18.28,
    patients: 1,
  },
  {
    los: 29.8,
    patients: 1,
  },
  {
    los: 48.08,
    patients: 1,
  },
  {
    los: 48.84,
    patients: 1,
  },
  {
    los: 27.14,
    patients: 1,
  },
  {
    los: 31.31,
    patients: 1,
  },
  {
    los: 34.62,
    patients: 1,
  },
  {
    los: 37.96,
    patients: 1,
  },
  {
    los: 37.47,
    patients: 1,
  },
  {
    los: 43.19,
    patients: 1,
  },
  {
    los: 32.55,
    patients: 1,
  },
  {
    los: 26.52,
    patients: 1,
  },
  {
    los: 30.37,
    patients: 1,
  },
  {
    los: 24.11,
    patients: 1,
  },
  {
    los: 42.38,
    patients: 1,
  },
  {
    los: 31.4,
    patients: 1,
  },
  {
    los: 32.97,
    patients: 1,
  },
  {
    los: 20.33,
    patients: 1,
  },
  {
    los: 43.47,
    patients: 1,
  },
  {
    los: 30.85,
    patients: 2,
  },
  {
    los: 41.25,
    patients: 1,
  },
  {
    los: 45.42,
    patients: 1,
  },
  {
    los: 41.61,
    patients: 2,
  },
  {
    los: 35.02,
    patients: 1,
  },
  {
    los: 49.64,
    patients: 1,
  },
  {
    los: 25.5,
    patients: 1,
  },
  {
    los: 43.16,
    patients: 2,
  },
  {
    los: 44.1,
    patients: 2,
  },
  {
    los: 20.22,
    patients: 1,
  },
  {
    los: 24.24,
    patients: 1,
  },
  {
    los: 26.81,
    patients: 2,
  },
  {
    los: 25.15,
    patients: 1,
  },
  {
    los: 34.84,
    patients: 1,
  },
  {
    los: 29.6,
    patients: 2,
  },
  {
    los: 29.59,
    patients: 2,
  },
  {
    los: 38.99,
    patients: 1,
  },
  {
    los: 26.51,
    patients: 1,
  },
  {
    los: 48.13,
    patients: 1,
  },
  {
    los: 46.56,
    patients: 1,
  },
  {
    los: 23.34,
    patients: 1,
  },
  {
    los: 33.56,
    patients: 2,
  },
  {
    los: 27.29,
    patients: 1,
  },
  {
    los: 29.19,
    patients: 1,
  },
  {
    los: 37.75,
    patients: 1,
  },
  {
    los: 47.3,
    patients: 1,
  },
  {
    los: 19.41,
    patients: 1,
  },
  {
    los: 32.4,
    patients: 1,
  },
  {
    los: 31.98,
    patients: 1,
  },
  {
    los: 48.57,
    patients: 1,
  },
  {
    los: 33.05,
    patients: 1,
  },
  {
    los: 33.99,
    patients: 1,
  },
  {
    los: 38.72,
    patients: 1,
  },
  {
    los: 41.4,
    patients: 1,
  },
  {
    los: 32.1,
    patients: 1,
  },
  {
    los: 29.61,
    patients: 2,
  },
  {
    los: 22.2,
    patients: 1,
  },
  {
    los: 36.4,
    patients: 1,
  },
  {
    los: 49.8,
    patients: 1,
  },
  {
    los: 28.17,
    patients: 1,
  },
  {
    los: 49.97,
    patients: 1,
  },
  {
    los: 43.68,
    patients: 1,
  },
  {
    los: 22.57,
    patients: 3,
  },
  {
    los: 36.74,
    patients: 1,
  },
  {
    los: 27.94,
    patients: 1,
  },
  {
    los: 34.73,
    patients: 1,
  },
  {
    los: 30.02,
    patients: 1,
  },
  {
    los: 31.26,
    patients: 1,
  },
  {
    los: 46.33,
    patients: 1,
  },
  {
    los: 20.26,
    patients: 1,
  },
  {
    los: 32.83,
    patients: 2,
  },
  {
    los: 28.6,
    patients: 2,
  },
  {
    los: 42.37,
    patients: 1,
  },
  {
    los: 28.57,
    patients: 1,
  },
  {
    los: 24.35,
    patients: 2,
  },
  {
    los: 33.33,
    patients: 1,
  },
  {
    los: 28.8,
    patients: 3,
  },
  {
    los: 31.94,
    patients: 1,
  },
  {
    los: 34.78,
    patients: 1,
  },
  {
    los: 39.61,
    patients: 1,
  },
  {
    los: 48.8,
    patients: 1,
  },
  {
    los: 44.34,
    patients: 1,
  },
  {
    los: 27.15,
    patients: 1,
  },
  {
    los: 31.72,
    patients: 1,
  },
  {
    los: 43.81,
    patients: 1,
  },
  {
    los: 19.44,
    patients: 1,
  },
  {
    los: 41.37,
    patients: 1,
  },
  {
    los: 33.87,
    patients: 1,
  },
  {
    los: 43.82,
    patients: 1,
  },
  {
    los: 28.87,
    patients: 1,
  },
  {
    los: 29.84,
    patients: 1,
  },
  {
    los: 43.93,
    patients: 1,
  },
  {
    los: 41.03,
    patients: 1,
  },
  {
    los: 46.41,
    patients: 1,
  },
  {
    los: 46.55,
    patients: 2,
  },
  {
    los: 30.73,
    patients: 1,
  },
  {
    los: 31.9,
    patients: 1,
  },
  {
    los: 27.58,
    patients: 2,
  },
  {
    los: 28.04,
    patients: 2,
  },
  {
    los: 47.74,
    patients: 1,
  },
  {
    los: 41.05,
    patients: 1,
  },
  {
    los: 41.94,
    patients: 1,
  },
  {
    los: 47.72,
    patients: 1,
  },
  {
    los: 47.59,
    patients: 1,
  },
  {
    los: 41.3,
    patients: 1,
  },
  {
    los: 47.99,
    patients: 1,
  },
  {
    los: 23.84,
    patients: 1,
  },
  {
    los: 33.72,
    patients: 1,
  },
  {
    los: 49.15,
    patients: 1,
  },
  {
    los: 33.1,
    patients: 1,
  },
  {
    los: 35.67,
    patients: 1,
  },
  {
    los: 28.31,
    patients: 1,
  },
  {
    los: 35.44,
    patients: 1,
  },
  {
    los: 44.44,
    patients: 1,
  },
  {
    los: 37.55,
    patients: 1,
  },
  {
    los: 43.71,
    patients: 1,
  },
  {
    los: 33.58,
    patients: 1,
  },
  {
    los: 36.11,
    patients: 1,
  },
  {
    los: 32.67,
    patients: 1,
  },
  {
    los: 22.84,
    patients: 1,
  },
  {
    los: 22.4,
    patients: 1,
  },
  {
    los: 26.95,
    patients: 1,
  },
  {
    los: 38.15,
    patients: 1,
  },
  {
    los: 45.86,
    patients: 1,
  },
  {
    los: 37.89,
    patients: 1,
  },
  {
    los: 32.41,
    patients: 1,
  },
  {
    los: 25.25,
    patients: 1,
  },
  {
    los: 31.6,
    patients: 1,
  },
  {
    los: 25.92,
    patients: 1,
  },
  {
    los: 39.91,
    patients: 1,
  },
  {
    los: 47.55,
    patients: 1,
  },
  {
    los: 31.46,
    patients: 1,
  },
  {
    los: 18.47,
    patients: 1,
  },
  {
    los: 36.7,
    patients: 1,
  },
  {
    los: 27.98,
    patients: 1,
  },
  {
    los: 23.04,
    patients: 1,
  },
  {
    los: 46.02,
    patients: 1,
  },
  {
    los: 33.78,
    patients: 1,
  },
  {
    los: 46.66,
    patients: 1,
  },
  {
    los: 31.17,
    patients: 1,
  },
  {
    los: 38.7,
    patients: 1,
  },
  {
    los: 18.31,
    patients: 1,
  },
  {
    los: 41.88,
    patients: 1,
  },
  {
    los: 18.46,
    patients: 1,
  },
  {
    los: 37.64,
    patients: 1,
  },
  {
    los: 17.41,
    patients: 1,
  },
  {
    los: 23.17,
    patients: 1,
  },
  {
    los: 32.81,
    patients: 1,
  },
  {
    los: 41.9,
    patients: 1,
  },
  {
    los: 35.98,
    patients: 1,
  },
  {
    los: 46.93,
    patients: 1,
  },
  {
    los: 32.17,
    patients: 1,
  },
  {
    los: 23.91,
    patients: 1,
  },
  {
    los: 49.79,
    patients: 1,
  },
  {
    los: 41.53,
    patients: 1,
  },
  {
    los: 37.3,
    patients: 1,
  },
  {
    los: 34.01,
    patients: 1,
  },
  {
    los: 29.62,
    patients: 1,
  },
  {
    los: 22.41,
    patients: 1,
  },
  {
    los: 34.13,
    patients: 1,
  },
  {
    los: 30.51,
    patients: 1,
  },
  {
    los: 26.08,
    patients: 1,
  },
  {
    los: 21.34,
    patients: 1,
  },
  {
    los: 40.89,
    patients: 1,
  },
  {
    los: 36.98,
    patients: 1,
  },
  {
    los: 20.34,
    patients: 1,
  },
  {
    los: 47.95,
    patients: 1,
  },
  {
    los: 24.41,
    patients: 1,
  },
  {
    los: 19.29,
    patients: 1,
  },
  {
    los: 42.97,
    patients: 1,
  },
  {
    los: 36.93,
    patients: 1,
  },
  {
    los: 43.17,
    patients: 1,
  },
  {
    los: 31.87,
    patients: 1,
  },
  {
    los: 25.91,
    patients: 1,
  },
  {
    los: 36.73,
    patients: 1,
  },
  {
    los: 26.56,
    patients: 1,
  },
  {
    los: 29.15,
    patients: 1,
  },
  {
    los: 32.15,
    patients: 1,
  },
  {
    los: 45.01,
    patients: 1,
  },
  {
    los: 42.11,
    patients: 1,
  },
  {
    los: 46.48,
    patients: 1,
  },
]

export default data
