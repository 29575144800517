import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {useAppSelector} from './hooks'

const initialState = 0

export const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    setCurrent: (_state: number, action: PayloadAction<number>) => action.payload,
  },
})

export const {setCurrent} = currentSlice.actions

export const useCurrentPatient = () => {
  return useAppSelector(state => state.patients[state.current])
}

export const useCurrentResult = () => {
  return useAppSelector(state => state.results[state.current])
}

export default currentSlice.reducer
