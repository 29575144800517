const admissionLocLut: string[] = [
  'EMERGENCY ROOM',
  'PHYSICIAN REFERRAL',
  'TRANSFER FROM HOSPITAL',
  'WALK-IN/SELF REFERRAL',
  'TRANSFER FROM SKILLED NURSING FACILITY',
  'CLINIC REFERRAL',
  'INFORMATION NOT AVAILABLE',
  'PACU',
  'AMBULATORY SURGERY TRANSFER',
  'PROCEDURE SITE',
  'INTERNAL TRANSFER TO OR FROM PSYCH',
]

export default admissionLocLut
