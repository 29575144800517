import {Cake, TextSnippet, Transgender} from '@mui/icons-material'
import {Box, CardActionArea, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import type {FC} from 'react'
import {setCurrent} from '../../redux/currentSlice'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'

interface NamePlateProps {
  name: string
  index: number
  gender: string
  age: number
  diagnosis: string
}

const NamePlate: FC<NamePlateProps> = (props: NamePlateProps) => {
  const dispatch = useAppDispatch()
  const currentIndex = useAppSelector(state => state.current)

  return (
    <Box
      sx={currentIndex === props.index ? {backgroundColor: 'lightgrey'} : {}}
      onClick={() => dispatch(setCurrent(props.index))}
    >
      <CardActionArea sx={{p: 2}}>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6" component="h5" sx={{textAlign: 'center'}}>{props.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <List sx={{pb: 0, pl: 2, pt: 0}}>
              <ListItem sx={{p: 0}}>
                <ListItemIcon sx={{minWidth: 'var(--listItemIcon)'}}><Transgender /></ListItemIcon>
                <ListItemText><b>Gender:</b> {props.gender === 'F' ? 'Female' : 'Male'}</ListItemText>
              </ListItem>
              <ListItem sx={{p: 0}}>
                <ListItemIcon sx={{minWidth: 'var(--listItemIcon)'}}><Cake /></ListItemIcon>
                <ListItemText><b>Age:</b> {props.age}</ListItemText>
              </ListItem>
              <ListItem sx={{p: 0}}>
                <ListItemIcon sx={{minWidth: 'var(--listItemIcon)'}}><TextSnippet /></ListItemIcon>
                <ListItemText><b>Diagnosis:</b> <wbr /><span style={{display: 'inline-block'}}>{props.diagnosis}</span></ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardActionArea>
    </Box>
  )
}

export default NamePlate
export type {NamePlateProps}
