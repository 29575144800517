import type {Patient} from '../redux/patientsSlice'
import admissionLocLut from './AdmissionLoc'
import insuranceLut from './Insurance'
import ethnicityLut from './Ethnicity'
import ICDLut from './icd'

const randomBetween = (a: number, b: number): number => {
  const min = Math.min(a, b)
  const max = Math.max(a, b)
  const rand = Math.random() * (max - min) + min
  return parseFloat(rand.toFixed(1))
}

const randomIntBetween = (a: number, b: number): number => {
  return Math.round(randomBetween(a, b))
}

const randomFromArray = (arr: string[]): string => {
  return arr[randomIntBetween(0, arr.length - 1)]
}

const prevStays = randomIntBetween(0, 5)

const RandomPatient = (): Patient => {
  return {
    admission_location: randomFromArray(admissionLocLut),
    insurance: randomFromArray(insuranceLut),
    ethnicity: randomFromArray(ethnicityLut),
    gender: Math.random() < 0.5 ? 'F' : 'M',
    anchor_age: randomIntBetween(18, 91),
    icd_code: randomFromArray(Object.keys(ICDLut)) as keyof ICDLut,
    temperature: randomBetween(93.2, 107.6),
    resprate: randomIntBetween(12, 20),
    o2sat: randomBetween(95, 100),
    sbp: randomIntBetween(80, 180),
    dbp: randomIntBetween(40, 120),
    pain: randomIntBetween(1, 10),
    acuity: randomIntBetween(1, 5),
    diag_count: randomIntBetween(1, 5),
    med_count: randomIntBetween(0, 30),
    to_icu: Math.random() < 0.5 ? 0 : 1,
    ed_los: randomBetween(0, 0.5),
    previous_stays: prevStays,
    prev_stay_avg_dur: prevStays ? randomBetween(1, 10) : 0,
  }
}

export default RandomPatient
