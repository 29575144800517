const ICDLut = {
  R55: 'Syncope and collapse',
  E8809: 'Fall on stair / step',
  A419: 'Sepsis',
  30500: 'Alcohol abuse',
  N179: 'Acute kidney failure',
  S92: 'Fracture of lower leg',
  J18: 'Pneumonia',
  4280: 'Congestive heart failure',
  78904: 'Abdominal pain',
  K3580: 'Unspecified acute appendicitis',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface ICDLut {
  'R55': string
  'E8809': string
  'A419': string
  '30500': string
  'N179': string
  'S92': string
  'J18': string
  '4280': string
  '78904': string
  'K3580': string
}

export type {ICDLut}
export default ICDLut
