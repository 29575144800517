import type {FC} from 'react'
import {Grid, Paper} from '@mui/material'
import PatientDetail from './PatientDetail'
import Control from './control/Control'
import ButtonPanel from './ButtonPanel'

const Main: FC = () => {
  return (
    <Paper sx={{p: 2, height: '100%'}}>
      <Grid container>
        <Grid
          item xs={6} lg={8}
          sx={{pr: 2}}
        >
          <PatientDetail />
        </Grid>
        <Grid item xs={6} lg={4}>
          <ButtonPanel />
        </Grid>
        <Grid item xs={12}>
          <Control />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Main
