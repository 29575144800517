import type {FC} from 'react'
import {useCurrentPatient} from '../../../redux/currentSlice'
import {useUpdateCurrent} from './Control'
import SliderField from './SliderField'

const toFahrenheit = (celsius: number): number => {
  const fahrenheit = celsius * 9 / 5 + 32
  return parseFloat(fahrenheit.toFixed(1))
}

const toCelsius = (fahrenheit: number): number => {
  const celsius = (fahrenheit - 32) * 5 / 9
  return parseFloat(celsius.toFixed(1))
}

const Triage: FC = () => {
  const update = useUpdateCurrent()
  const props = useCurrentPatient().properties
  return (
    <>
      <SliderField
        onChangeCommitted={(value: number) => update('resprate', value)}
        min={12}
        max={20}
        step={1}
        value={props.resprate}
        label="Respiratory Rate"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('temperature', toFahrenheit(value))}
        min={34}
        max={42}
        step={0.1}
        value={toCelsius(props.temperature)}
        label="Temperature"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('o2sat', value)}
        min={95}
        max={100}
        step={0.1}
        value={props.o2sat}
        label="Oxygen Saturation"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('sbp', value)}
        min={80}
        max={180}
        step={1}
        value={props.sbp}
        label="Systolic blood pressure"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('dbp', value)}
        min={40}
        max={120}
        step={1}
        value={props.dbp}
        label="Diastolic blood pressure"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('pain', value)}
        min={1}
        max={10}
        step={1}
        value={props.pain}
        label="Pain"
      />
      <SliderField
        onChangeCommitted={(value: number) => update('acuity', value)}
        min={1}
        max={5}
        step={1}
        value={props.acuity}
        label="Acuity"
      />
    </>
  )
}

export default Triage
