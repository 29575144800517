import {configureStore} from '@reduxjs/toolkit'
import currentReducer from './currentSlice'
import patientReducer from './patientsSlice'
import resultsReducer from './resultsSlice'

export const store = configureStore({
  reducer: {
    patients: patientReducer,
    current: currentReducer,
    results: resultsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
