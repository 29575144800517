import type {FC} from 'react'
import {useState} from 'react'
import {Box, Grid, Tab, Tabs} from '@mui/material'
import {useAppDispatch} from '../../../redux/hooks'
import type {Patient, PatientInfo} from '../../../redux/patientsSlice'
import {updatePatient} from '../../../redux/patientsSlice'
import {useCurrentPatient} from '../../../redux/currentSlice'
import Triage from './Triage'
import Medical from './Medical'
import Demographics from './Demographics'

export const useUpdateCurrent = () => {
  const dispatch = useAppDispatch()
  let patient = useCurrentPatient()
  return (key: keyof Patient, value: string | number, patOverride?: PatientInfo): PatientInfo => {
    if (patOverride)
      patient = patOverride

    const newPatient = {
      ...patient,
      properties: {
        ...patient.properties,
        [key]: value,
      },
      changed: true,
    }
    dispatch(updatePatient(newPatient))
    return newPatient
  }
}

const Control: FC = () => {
  const [area, setArea] = useState<number>(0)

  return (
    <Box>
      <Tabs value={area} onChange={(_, newValue: number) => setArea(newValue)} centered>
        <Tab label="Triage" />
        <Tab label="Medical" />
        <Tab label="Demographics" />
      </Tabs>
      <Grid container spacing={2} sx={{mt: 2, overfloxY: 'auto'}}>
        {area === 0 && <Triage />}
        {area === 1 && <Medical />}
        {area === 2 && <Demographics />}
      </Grid>
    </Box>
  )
}

export default Control
