import type {SelectChangeEvent} from '@mui/material'
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material'
import type {FC} from 'react'
import InsuranceLut from '../../../lookup/Insurance'
import {useCurrentPatient} from '../../../redux/currentSlice'
import {useUpdateCurrent} from './Control'
import SliderField from './SliderField'

const Demographics: FC = () => {
  const update = useUpdateCurrent()
  const props = useCurrentPatient().properties
  return (
    <>
      <SliderField
        onChangeCommitted={(value: number) => update('anchor_age', value)}
        min={18}
        max={91}
        step={1}
        value={props.anchor_age}
        label="Age"
      />
      <Grid item xs={6}>
        <Box sx={{px: 4}}>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel
              id="control-demo-select-gender"
            >GENDER
            </InputLabel>
            <Select
              label="Gender"
              labelId="control-demo-select-gender"
              value={props.gender}
              onChange={(e: SelectChangeEvent) => update('gender', e.target.value)}
            >
              <MenuItem value="F">Female</MenuItem>
              <MenuItem value="M">Male</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{px: 4}}>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel
              id="control-demo-select-insurance"
            >INSURANCE
            </InputLabel>
            <Select
              label="Insurance"
              labelId="control-demo-select-insurance"
              value={props.insurance}
              onChange={(e: SelectChangeEvent) => update('insurance', e.target.value)}
            >
              {InsuranceLut.map((ins: string) => {
                return (
                  <MenuItem key={ins} value={ins}>{ins}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </>
  )
}

export default Demographics
