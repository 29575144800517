import {AddLocation, MedicalInformation} from '@mui/icons-material'
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import type {FC} from 'react'
import {useCurrentPatient} from '../../redux/currentSlice'

const PatientDetail: FC = () => {
  const patient = useCurrentPatient()

  return (
    <Box sx={{pl: 2}}>
      <Typography variant="h6" component="h5">{patient.name}</Typography>
      <List>
        <ListItem sx={{pl: 0}}>
          <ListItemIcon sx={{minWidth: 'var(--listItemIcon)'}}>
            <AddLocation />
          </ListItemIcon>
          <ListItemText>
            <b>Admitted to:</b> {patient.properties.admission_location}
          </ListItemText>
        </ListItem>
        <ListItem sx={{pl: 0}}>
          <ListItemIcon sx={{minWidth: 'var(--listItemIcon)'}}>
            <MedicalInformation />
          </ListItemIcon>
          <ListItemText>
            <b>Insurance:</b> {patient.properties.insurance}
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  )
}

export default PatientDetail
