import {Refresh, Send} from '@mui/icons-material'
import {Button, Grid, Hidden, SwipeableDrawer} from '@mui/material'
import type {FC} from 'react'
import {useState} from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import {randomPatientGenerated} from '../../redux/patientsSlice'
import PatientList from '../selector/PatientList'
import {refreshPrediction} from '../../redux/resultsSlice'

const ButtonPanel: FC = () => {
  const index = useAppSelector(state => state.current)
  const patient = useAppSelector(state => state.patients[index])
  const dispatch = useAppDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open)
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      spacing={2}
    >
      {/* <Grid item xs={12}> // this never happened *wink wink*
        <Button
          fullWidth
          variant="contained"
          startIcon={<ReadMore />}
        >
          Explanation
        </Button>
      </Grid> */}
      <Grid item xs={12}>
        <Button
          onClick={() => dispatch(refreshPrediction(patient))}
          fullWidth
          variant="contained"
          disabled={!patient.changed}
          startIcon={<Send />}
        >
          Refresh prediction
        </Button>
      </Grid>
      {index === 3
        && (
        <Grid item xs={12}>
          <Button
            onClick={() => dispatch(randomPatientGenerated())}
            fullWidth
            variant="contained"
            startIcon={<Refresh />}
          >
            New random patient
          </Button>
        </Grid>
        )
      }
      <Hidden lgUp>
        <Grid item xs={12}>
          <Button
            onClick={() => toggleDrawer(true)}
            fullWidth
            variant="contained"
            startIcon={<FormatListBulletedIcon />}
          >
            Select Patient
          </Button>
        </Grid>
        <SwipeableDrawer
          PaperProps={{sx: {width: '30%', backgroundColor: 'transparent', boxShadow: 'none', m: 2, pb: 4}}}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          open={drawerOpen}
          onMouseUp={() => toggleDrawer(false)}
          anchor="left"
        >
          <PatientList />
        </SwipeableDrawer>
      </Hidden>
    </Grid>
  )
}

export default ButtonPanel
