import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import RandomPatient from '../lookup/RandomPatient'
import type {ICDLut} from '../lookup/icd'

interface Patient {
  admission_location: string
  insurance: string
  ethnicity: string
  gender: 'F' | 'M'
  anchor_age: number
  icd_code: keyof ICDLut
  temperature: number
  resprate: number
  o2sat: number
  sbp: number
  dbp: number
  pain: number
  acuity: number
  diag_count: number
  med_count: number
  to_icu: 1 | 0
  ed_los: number
  previous_stays: number
  prev_stay_avg_dur: number
}

interface PatientInfo {
  index: number
  name: string
  changed: boolean
  properties: Patient
}

const initialState: PatientInfo[] = [
  {
    index: 0,
    name: 'Janet Lowery',
    changed: false,
    properties: {
      admission_location: 'EMERGENCY ROOM',
      insurance: 'Other',
      ethnicity: 'BLACK/AFRICAN AMERICAN',
      gender: 'F',
      anchor_age: 77,
      icd_code: '4280',
      temperature: 97.9,
      resprate: 20,
      o2sat: 98,
      sbp: 97,
      dbp: 65,
      pain: 0,
      acuity: 2,
      diag_count: 3,
      med_count: 18,
      to_icu: 1,
      ed_los: 0.07,
      previous_stays: 5,
      prev_stay_avg_dur: 9.25722,
    },
  },
  {
    index: 1,
    name: 'Oliver Rodney',
    changed: false,
    properties: {
      admission_location: 'EMERGENCY ROOM',
      insurance: 'Medicare',
      ethnicity: 'WHTIE',
      gender: 'M',
      anchor_age: 55,
      icd_code: '78904',
      temperature: 99.8,
      resprate: 16,
      o2sat: 100,
      sbp: 134,
      dbp: 90,
      pain: 10,
      acuity: 2,
      diag_count: 2,
      med_count: 29,
      to_icu: 0,
      ed_los: 0.21,
      previous_stays: 2,
      prev_stay_avg_dur: 1.4309,
    },
  },
  {
    index: 2,
    name: 'Joshua Green',
    changed: false,
    properties: {
      admission_location: 'WALK-IN/SELF REFERRAL',
      insurance: 'Other',
      ethnicity: 'WHTIE',
      gender: 'M',
      anchor_age: 33,
      icd_code: 'K3580',
      temperature: 97,
      resprate: 20,
      o2sat: 100,
      sbp: 158,
      dbp: 90,
      pain: 7,
      acuity: 3,
      diag_count: 2,
      med_count: 0,
      to_icu: 0,
      ed_los: 0.28,
      previous_stays: 0,
      prev_stay_avg_dur: 0,
    },
  },
  {
    index: 3,
    name: 'Random Patient',
    changed: true,
    properties: RandomPatient(),
  },
]

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    updatePatient: (state, action: PayloadAction<PatientInfo>) => {
      state[action.payload.index] = action.payload
    },
  },
})

export const {updatePatient} = patientsSlice.actions

export const randomPatientGenerated = (): PayloadAction<PatientInfo> => {
  return {
    type: 'patients/updatePatient',
    payload: {
      index: 3,
      name: 'Random Patient',
      changed: true,
      properties: RandomPatient(),
    },
  }
}

export type {PatientInfo, Patient}
export default patientsSlice.reducer
